import arrowRightPicto from '@caprikorn/dynamo-icons/arrowRightMd.svg';
import { IconButton } from 'src/ui/button/icon/IconButton';
import { Typo } from 'src/ui/typography/Typo';

import authText from 'src/texts/current/authPageText.json';
import { Column } from 'src/ui/Column/Column';
import { TextButton } from 'src/ui/button/textButton/TextButton';
import { Spacer } from 'src/ui/spacer/Spacer';
import * as Styled from './AuthFormTemplate.styles';

interface IAuthContentTemplateProps {
  children: React.ReactNode;
  text: string;
  buttonContent?: string;
  cancelContent: string;
  cancelIcon?: string;
  isSubmitDisabled?: boolean;
  onSubmit(): void;
  isLoading?: boolean;
  onCancel(): void;
}

export default function AuthFormTemplate({
  children,
  text,
  buttonContent = authText.form.login.connect,
  isSubmitDisabled,
  onSubmit,
  onCancel,
  cancelContent,
  isLoading,
  cancelIcon,
}: IAuthContentTemplateProps): React.ReactElement {
  return (
    <Column gap='lg' justifyContent='space-between'>
      <Styled.Form onSubmit={onSubmit} noValidate>
        <Column
          justifyContent='space-between'
          alignItems='center'
        >
          <Typo.Paragraph>
            {text}
          </Typo.Paragraph>
          <Styled.FormWrapper>
            {children}
          </Styled.FormWrapper>
          <Spacer height='lg' />
          <div>
            <IconButton
              type='submit'
              variant='primary'
              rightIcon={arrowRightPicto}
              disabled={isSubmitDisabled}
              isLoading={isLoading}
              dataTestId='AuthFormTemplate-login-submit'
            >
              {buttonContent}
            </IconButton>
          </div>
        </Column>
      </Styled.Form>
      <TextButton
        onClick={onCancel}
        rightIcon={cancelIcon}
        variant='primary'
        dataTestId='AuthFormTemplate-auth-login-cancel'
        squish
      >
        {cancelContent}
      </TextButton>
    </Column>
  );
}
