export const enum shieldQueriesEnum {
  SHIELD_CERTIFICATES = 'SHIELD_CERTIFICATES',
  SHIELD_CUSTOMER_IDS = 'SHIELD_CUSTOMER_IDS',

}

export const shieldKeysEnum = {
  [shieldQueriesEnum.SHIELD_CERTIFICATES]: (
    customerId: string,
  ) => [shieldQueriesEnum.SHIELD_CERTIFICATES, customerId],
  [shieldQueriesEnum.SHIELD_CUSTOMER_IDS]: (
    siren: string,
  ) => [shieldQueriesEnum.SHIELD_CUSTOMER_IDS, siren],

} as const;
