import { useSearchParams } from 'react-router-dom';

import { disabledTariffFormCompletion } from 'src/configuration/utils/feature_flag';
import { Column } from 'src/ui/Column/Column';
import { useState } from 'react';
import { ShieldStatusSwitcher } from './components/ShieldStatusSwitcher';
import { IFormattedShieldData } from './ShieldPage.types';
import { ShieldLegalIntro } from './components/ShieldLegalIntro';
import { ShieldForm } from './form/ShieldForm';

type IShieldPageContentProps = {
  data: IFormattedShieldData;
};

export const ShieldPageContent = ({ data }: IShieldPageContentProps) => {
  const [displayForm, setDisplayForm] = useState<boolean>(
    (!data.certificate && !data.isShieldRejected) || false,
  );

  const togglForm = () => setDisplayForm(!displayForm);
  const [searchParams] = useSearchParams();
  const siren = searchParams.get('siren') || '';
  const company = searchParams.get('company') || '';

  return (
    <Column width='100%' overflow='scroll'>
      <ShieldLegalIntro />
      <ShieldStatusSwitcher data={data} toggleForm={togglForm} />
      {(displayForm && !disabledTariffFormCompletion)
        && (
        <Column
          gap='xs'
          width='inherit'
          padding={['lg']}
        >
          <ShieldForm siren={siren} company={company} />
        </Column>
        )}
    </Column>

  );
};
