import React, { useState } from 'react';

import { IInputProps } from 'src/ui/input/basic/Input.types';
import closedEyePicto from '@caprikorn/dynamo-icons/eyeFalse.svg';
import eyePicto from '@caprikorn/dynamo-icons/eyeTrue.svg';

import * as Styled from './PasswordInput.styles';

export type PasswordInputProps = Omit<IInputProps, 'type'>;
/**
 * FIXME:
 * Until an upstream dependency issue in "eslint-plugin-react" is resolved,
 * disabling eslint rule "react/prop-types" is the recommended approach
 * to resolve false positives in eslint.
 *
 * see:
 * - https://github.com/yannickcr/eslint-plugin-react/issues/3140
 * - https://github.com/redwoodjs/redwood/pull/3762
 */
/* eslint-disable react/prop-types */

export const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(({
  placeholder,
  name,
  dataTestId,
  disabled,
  className,
  onChange,
  onBlur,
}: PasswordInputProps, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const suffixedProps = {
    content: showPassword ? closedEyePicto : eyePicto,
    dataTestId: 'test-input-toggle-password-visibility',
    onMouseLeave: () => setShowPassword(false),
    onMouseDown: () => setShowPassword(true),
    onMouseUp: () => setShowPassword(false),
  };
  return (
    <Styled.CustomPrefixedSuffixedInput
      className={className}
      type={showPassword ? 'text' : 'password'}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      disabled={disabled}
      ref={ref}
      data-testid={dataTestId}
      suffixProps={suffixedProps}
    />
  );
});

// Prevent eslint error: Component definition is missing display name react/display-name
PasswordInput.displayName = 'PasswordInput';
