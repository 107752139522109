import React, {
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import drawerStyles from './DrawerWrapper.module.css';
import { DRAWER_ANIMATION } from '../constants';

export interface IDrawerWrapperProps {
  show: boolean;
  children: React.ReactNode;
}

export const DrawerWrapper = ({ show, children }: IDrawerWrapperProps) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const displayedClassname = show ? '' : drawerStyles.hide;
  const ref: RefObject<HTMLDivElement> = useRef(null);

  const listenerCallback = (event: AnimationEvent) => {
    if (event?.animationName.includes(DRAWER_ANIMATION.SLIDEOUT)) {
      setDrawerVisible(false);
    }
  };

  useEffect(() => {
    ref?.current?.addEventListener('animationend', (event: AnimationEvent) => listenerCallback(event));

    if (show) {
      setDrawerVisible(true);
    }
  }, [ref, show]);

  useEffect(() => () => {
    ref?.current?.removeEventListener('animationend', listenerCallback);
  }, []);

  if (!drawerVisible) {
    return null;
  }

  return (
    <>
      <div className={`${drawerStyles.Wrapper} ${displayedClassname}`} ref={ref} />
      <div className={`${drawerStyles.Container} ${displayedClassname}`}>
        {children}
      </div>
    </>
  );
};
