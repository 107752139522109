import styled from 'src/theme/ThemeProvider';
import backgroundImage from 'src/assets/images/current/auth_illustration.svg';

export const Main = styled.main`
  background-image: url(${backgroundImage});
  background-position: bottom 0 right 0;
  background-repeat: no-repeat;
  background-size: 40%;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
