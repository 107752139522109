import config from 'src/configuration';
import { putRequest, axiosInstance } from 'src/libs/requests';
import { ILoginAction } from './AuthTypes';

const URL = {
  BASE: config.API_ROOT,
  LOGIN: '/public/login',
  RESET_PASSWORD: '/public/reset-password',
  UPDATE_PASSWORD_AFTER_RESET: '/public/update-password-after-reset',
  CHANGE_INITIAL_PASSWORD: '/public/change-initial-password',
  UPDATE_PASSWORD: '/update-password',
};

export const login = async (email: string, password: string) => {
  try {
    const { data } = await axiosInstance.post<ILoginAction>(URL.LOGIN, {
      login: email,
      password,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

// Change PWD when already connected
export const changePassword = async (
  previousPassword: string,
  newPassword: string,
) => {
  try {
    return await putRequest(URL.UPDATE_PASSWORD, {
      previousPassword,
      proposedPassword: newPassword,
    });
  } catch (error) {
    throw error;
  }
};

export const changeInitialPassword = async (
  email: string,
  previousPassword: string,
  newPassword: string,
) => {
  try {
    return await axiosInstance.patch(URL.CHANGE_INITIAL_PASSWORD, {
      email,
      initialPassword: previousPassword,
      newPassword,
    });
  } catch (error) {
    throw error;
  }
};

// Reset PWD using reset link

export const askPasswordResetLink = async (
  email: string,
) => {
  try {
    return await axiosInstance.post(URL.RESET_PASSWORD, {
      email,
    });
  } catch (error) {
    throw error;
  }
};

export const updatePasswordResetLink = async (
  email: string,
  confirmationCode: string,
  password: string,
) => {
  try {
    return await axiosInstance.put(URL.UPDATE_PASSWORD_AFTER_RESET, {
      email,
      confirmationCode,
      password,
    });
  } catch (error) {
    throw error;
  }
};
