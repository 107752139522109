import { useLocation } from 'react-router-dom';

import chevronPictoRightMd from '@caprikorn/dynamo-icons/chevronRightSm.svg';
import { useTheme } from 'src/theme/ThemeProvider';
import { ReactSvgWrapper } from '../pictos/wrappers/ReactSVGWrapper';
import * as Styled from './Breadcrumbs.styles';
import { Typo } from '../typography/Typo';
import { Spacer } from '../spacer/Spacer';

export interface BreadcrumbPath {
  pathName: string;
  path: string;
  pathIcon?: string;
}

export interface IBreadcrumbsProps {
  paths: Array<BreadcrumbPath>;
}

export const Breadcrumbs = ({ paths }: IBreadcrumbsProps) => {
  const location = useLocation();
  const theme = useTheme();
  return (
    <Styled.Container data-testid='breadcrumb'>
      {paths.map(({ pathName, path, pathIcon }, index) => {
        const isActiveClassname = location.pathname === path ? 'BreadcrumbsLink-active' : '';

        return (
          <li key={pathName + path}>
            <Styled.ItemContainer>
              <Styled.CustomNavLink
                to={path}
                className={isActiveClassname}
              >
                <Typo.Span>
                  {pathIcon && (
                  <ReactSvgWrapper
                    src={pathIcon}
                    size={theme.sizes.icons.sm}
                    color={theme.colors.text.primary}
                  />
                  )}
                </Typo.Span>
                <Spacer width='xxs' />
                <Styled.CustomSpan className={isActiveClassname}>{pathName}</Styled.CustomSpan>
              </Styled.CustomNavLink>
              <Spacer width='xs' />
              <Typo.Span>
                {(index !== paths.length - 1) && (
                <ReactSvgWrapper
                  src={chevronPictoRightMd}
                  size={theme.sizes.icons.sm}
                  color={theme.colors.text.primary}
                />
                )}
              </Typo.Span>
              <Spacer width='xs' />
            </Styled.ItemContainer>
          </li>
        );
      })}
    </Styled.Container>
  );
};
