import styled, { IconSizeType, useTheme } from 'src/theme/ThemeProvider';
import { ReactSvgWrapper } from 'src/ui/pictos/wrappers/ReactSVGWrapper';
import { IStyledButtonProps, StyledButton } from './PictoAsButton.styles';

export interface IPictoAsButtonProps extends Omit<IStyledButtonProps, 'size'> {
  src: string;
  size: IconSizeType;
  ariaLabel?: string;
  dataTestId?: string;
}

const GreyPicto = styled(ReactSvgWrapper)`
  filter: grayscale(100%);
`;

export const PictoAsButton: React.FC<IPictoAsButtonProps> = ({
  isLoading,
  onClick,
  src,
  disabled,
  size,
  color,
  ariaLabel = '',
  dataTestId = '',
  className = '',
  type = 'button',
  tabIndex = 0,
}: IPictoAsButtonProps) => {
  const Picto = (isLoading || disabled) ? GreyPicto : ReactSvgWrapper;
  const theme = useTheme();

  return (
    <StyledButton
      className={className}
      onClick={onClick}
      isLoading={isLoading}
      disabled={disabled}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      size={theme.sizes.icons[size]}
      type={type}
      tabIndex={tabIndex}
    >
      <Picto
        size={theme.sizes.icons[size]}
        src={src}
        color={color}
      />
    </StyledButton>
  );
};
