import { Spacer } from 'src/ui/spacer/Spacer';
import { Title } from 'src/ui/typography/title/Title';
import { Button } from 'src/ui/button/basic/Button';
import { IImageWrapperProps, ImgWrapper } from 'src/ui/pictos/wrappers/ImgWrapper';
import { ISharedButtonProps } from 'src/ui/button/basic/Button.types';
import { Loader } from 'src/ui/loader/Loader';
import { ErrorTemplate } from 'src/components/errors/templates/ErrorTemplate';
import errorsText from 'src/texts/current/errorsText.json';
import { CenterContentLayout } from 'src/components/layouts/position/CenterContentLayout';
import { Column } from 'src/ui/Column/Column';
import { useTheme } from 'src/theme/ThemeProvider';
import { Row } from 'src/ui/row/Row';
import { IModalProps, Modal } from '../modal/Modal';

export interface IModalWithAction extends IModalProps {
  title: string;
  cancel?: ISharedButtonProps;
  submit?: ISharedButtonProps;
  img: IImageWrapperProps;
  isLoading?: boolean;
  isError?: boolean;
  isClickOutsideEnabled?: boolean;
}

export const ModalWithActions = ({
  show,
  children,
  onHide,
  size = 'normal',
  className = '',
  title,
  cancel,
  submit,
  img,
  isLoading,
  isError,
  isClickOutsideEnabled,
}: IModalWithAction) => {
  const theme = useTheme();
  if (isError) {
    return (
      <Modal
        show={show}
        onHide={onHide}
        size={size}
        className={className}
        isClickOutsideEnabled={isClickOutsideEnabled}
      >
        <Column
          justifyContent='space-between'
          textAlign='start'
          height={`${theme.layouts.modals.sm.height}px`}
          width={`${theme.layouts.modals.sm.width}px`}
        >
          <CenterContentLayout>
            <ErrorTemplate title={errorsText.default.title.large} imageType='error' />
          </CenterContentLayout>
        </Column>
      </Modal>
    );
  }

  if (isLoading) {
    return (
      <Modal
        show={show}
        onHide={onHide}
        size={size}
        className={className}
        isClickOutsideEnabled={isClickOutsideEnabled}
      >
        <Column
          justifyContent='space-between'
          textAlign='start'
          height={`${theme.layouts.modals.sm.height}px`}
          width={`${theme.layouts.modals.sm.width}px`}
        >
          <CenterContentLayout>
            <Loader />
          </CenterContentLayout>
        </Column>
      </Modal>
    );
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size}
      className={className}
      isClickOutsideEnabled={isClickOutsideEnabled}
    >
      <Column
        justifyContent='space-between'
        textAlign='start'
        height={`${theme.layouts.modals.sm.height}px`}
        width={`${theme.layouts.modals.sm.width}px`}
      >
        <div>
          <ImgWrapper {...img} />
          <Spacer height='md' />
          <Title tag='h1' titleStyle='h4'>
            {title }
          </Title>
          <Spacer height='md' />
          {children}
          <Spacer height='md' />
        </div>
        <Row>
          {cancel && (
          <Button
            variant={cancel?.variant ?? 'primary'}
            onClick={cancel?.onClick ?? onHide}
            outline
            type='button'
            dataTestId={cancel?.dataTestId ?? 'ModalWithActions-cancel'}
          >
            {cancel?.children}
          </Button>
          )}
          {cancel && submit && <Spacer width='sm' />}
          {submit && (
          <Button
            variant={cancel?.variant ?? 'primary'}
            onClick={submit.onClick}
            type={submit.type ?? 'button'}
            dataTestId={submit?.dataTestId ?? 'ModalWithActions-submit'}
          >
            {submit.children}
          </Button>
          )}
        </Row>
      </Column>
    </Modal>
  );
};
