import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { emailSchema } from 'src/libs/yupHelpers';
import { IFormattedError } from 'src/domains/error/ErrorTypes';
import { FormInput } from 'src/ui/input/form/FormInput';
import { PasswordInput } from 'src/ui/input/password/PasswordInput';
import { Input } from 'src/ui/input/basic/Input';
import { Spacer } from 'src/ui/spacer/Spacer';
import { Notification } from 'src/ui/overlays/notification/Notification';
import authText from 'src/texts/current/authPageText.json';
import commonText from 'src/texts/current/commonText.json';

import AuthFormTemplate from '../../templates/form/AuthFormTemplate';
import { SubscriptionLink } from '../../templates/subscritpion/SubscriptionLink';

const inputsText = commonText.inputs;
const loginText = authText.form.login;

interface IAuthLoginFormProps {
  error: IFormattedError | undefined | null;
  onLogin(email: string, password: string): void;
  onCancel(): void;
  isLoading?: boolean;
  notifContent: string;
  notifType: 'warning';
}

type FormData = {
  email: string;
  password: string;
};

const schema = yup.object().shape({
  email: emailSchema.required(),
  password: yup.string().required(),
});

export const AuthLoginForm = ({
  error,
  notifType,
  notifContent,
  onLogin,
  onCancel,
  isLoading,
}: IAuthLoginFormProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: { email: '', password: '' },
    mode: 'onSubmit',
  });

  const onSubmit = (data: FormData) => {
    onLogin(data.email, data.password);
    reset();
  };

  return (
    <>
      <Notification type={notifType} showNotif={!!error}>
        {notifContent}
      </Notification>
      <AuthFormTemplate
        isSubmitDisabled={!dirtyFields.password || (!dirtyFields.email || !!errors.email)}
        text={loginText.paragraph}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={isLoading}
        onCancel={onCancel}
        cancelContent={loginText.forgotPwd}
      >
        <FormInput
          htmlFor='email'
          label={inputsText.email.label}
          hint={errors?.email && inputsText.email.hint}
          required
        >
          <Input
            type='email'
            placeholder={inputsText.email.placeholder}
            dataTestId='test-auth-login-email'
            {...register('email')}
          />
        </FormInput>
        <Spacer height='md' />
        <FormInput
          htmlFor='password'
          label={inputsText.password.label}
          hint={errors?.password && inputsText.password.hint}
          required
        >
          <PasswordInput
            placeholder={inputsText.password.placeholder}
            dataTestId='test-auth-login-password'
            {...register('password')}
          />
        </FormInput>
      </AuthFormTemplate>
      <SubscriptionLink />
    </>
  );
};
