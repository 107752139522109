import { Typo } from 'src/ui/typography/Typo';
import { dateConverter } from 'src/libs/miscellaneous';
import { IShieldFormData } from 'src/domains/shield/ShieldTypes';
import texts from 'src/texts/current/shieldPageText.json';
import commonTexts from 'src/texts/current/commonText.json';

import { InfoItem } from 'src/ui/infos/InfoItem';
import styled from 'src/theme/ThemeProvider';
import { replaceText } from 'src/libs/text.utils';
import { Card } from 'src/ui/card/Card';
import { Column } from 'src/ui/Column/Column';
import { InfoText } from '../form/ShieldForm.styles';

const CustomInfoItem = styled(InfoItem)`
  margin-top: 16px;
`;

const shieldInputs = texts.form.inputs;
const commonInputs = commonTexts.inputs;

const formTexts = texts.form.choices;

interface IShieldPageIntroBannerDisplayCertificateProps {
  certificate: IShieldFormData | null;
}

export const ShieldCertificateDisplay = ({
  certificate,
}: IShieldPageIntroBannerDisplayCertificateProps) => {
  const choiceValue = certificate && formTexts.values[certificate.statement - 1];

  return (
    <Card>
      <Column gap='md'>
        {certificate?.signatureDate
        && (
        <Typo.Paragraph weight='bold' size='lg'>
          { replaceText(
            texts.subtitle,
            { replaceValues: { date: dateConverter(certificate?.signatureDate) } },
          )}
        </Typo.Paragraph>
        ) }
        <Column gap='xs'>
          <CustomInfoItem name={shieldInputs.sirenNumber.label} value={certificate?.siren} />
          <CustomInfoItem name={shieldInputs.companyName.label} value={certificate?.company} />
          <CustomInfoItem
            name={shieldInputs.address}
            value={`${certificate?.address.streetNumber} ${certificate?.address.street} ${certificate?.address.postalCode} ${certificate?.address.city}`}
          />
          <CustomInfoItem name={commonInputs.email.label} value={certificate?.mail} />
          <CustomInfoItem name={commonInputs.phone.label} value={certificate?.phoneNumber} />
          <CustomInfoItem
            name={shieldInputs.signatoryDescription}
            value={`${certificate?.firstName} ${certificate?.name} - ${certificate?.signatoryDescription}`}
          />
          <CustomInfoItem
            name={shieldInputs.signedOn.label}
            value={certificate?.signatureLocation}
          />
        </Column>
        {(certificate && choiceValue)
          && (
          <Column margin={['md', 0]}>
            <Typo.Paragraph>
              {choiceValue.regularText}
            </Typo.Paragraph>
              {choiceValue.list && (
                <ul>
                  {choiceValue.list.map((li) => (
                    <li key={li}>{li}</li>
                  ))}
                </ul>
              )}
            <InfoText>{choiceValue.italicText}</InfoText>
          </Column>
          )}
      </Column>
    </Card>
  );
};
