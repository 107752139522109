import { replaceText } from 'src/libs/text.utils';
import searchText from 'src/texts/current/searchText.json';
import commonText from 'src/texts/current/commonText.json';
import { LevelTypes } from './SearchDrawer.types';

export const menuItems: Array<{content: string, level: LevelTypes}> = [
  {
    content: replaceText(searchText.menuItem.contracts, {
      isPlural: true, isFirstLetterCapital: true,
    }),
    level: 'billConsolidation',
  },
  {
    content: replaceText(searchText.menuItem.sites, {
      isPlural: true, isFirstLetterCapital: true,
    }),
    level: 'pointOfDelivery',
  },
];

export const pageSizeOptions = [
  { value: '10', label: commonText.sizePerPage.options[10] },
  { value: '20', label: commonText.sizePerPage.options[20] },
  { value: '30', label: commonText.sizePerPage.options[30] },
];
