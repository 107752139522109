import { dateComparator } from 'src/libs/miscellaneous';
import { IRow } from 'src/ui/table/Table';
import text from 'src/texts/current/documentsText.json';
import { CustomButton } from './DocumentsPage.styles';
import {
  DocumentNameValues,
  DocumentNameValuesType,
  DocAggregatedByCategoriesType,
  IDocumentDownloadInfo,
  IDocumentInformation,
  DocByFileExtensionType,
} from './Documents.types';

const giveProperName = (value: DocumentNameValuesType) => text.table.documentName[value];

const ACCEPTED_FORMATS = ['xls', 'ods', 'xlsx', 'csv'];

const sortDocByDate = (array: IDocumentInformation[]) => array.sort((
  a: IDocumentInformation,
  b: IDocumentInformation,
) => dateComparator(new Date(b.created), new Date(a.created)));

export const generateDocCategoriesObjectTemplate: () => DocAggregatedByCategoriesType = () => (
  Object.keys(DocumentNameValues).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        documentsPdf: [],
        documentsXls: [],
      },
    }),
    {},
  )) as DocAggregatedByCategoriesType;

const allocateDocumentToCategories: (
  documents: IDocumentInformation[]
) => DocAggregatedByCategoriesType = (documents) => {
  const copy = generateDocCategoriesObjectTemplate();

  documents.forEach((doc) => {
    if (doc.fileExtension === 'pdf') {
      copy[doc.documentType].documentsPdf.push(doc);
    } else if (ACCEPTED_FORMATS.includes(doc.fileExtension)) {
      copy[doc.documentType].documentsXls.push(doc);
    }
  });

  return copy;
};

const getLatestDocInfo: (docs: DocByFileExtensionType) => {
  latestPdfDoc: IDocumentInformation | undefined;
  latestXlsDoc: IDocumentInformation | undefined;
} = (docs) => {
  const sortedPdfDoc = sortDocByDate(docs.documentsPdf);
  const sortedXlsDoc = sortDocByDate(docs.documentsXls);

  return {
    latestPdfDoc: sortedPdfDoc?.[0],
    latestXlsDoc: sortedXlsDoc?.[0],
  };
};

/**
 * Will generate all the table rows from a list of document
 * those rows will be formatted to fit TableDocuments component expectanties
 * @param documents all the documents recieved from fetch
 * @param isLoading query status
 * @param processingId spécific document s3 ID when downloading is ongoing
 * @param mutateFunction function for download
 * @returns an array fitting for Table component, with data related to documents
 */

interface ISortAndFormatForTableDocuments {
  documents: Array<IDocumentInformation>;
  isLoading: boolean;
  processingId: string;
  mutateFunction: ({
    fileName,
    s3VersionID,
    fileExtension,
  }: IDocumentDownloadInfo) => Promise<unknown>;
}

export const sortAndFormatForTableDocuments = ({
  documents,
  isLoading,
  processingId,
  mutateFunction,
}: ISortAndFormatForTableDocuments): Array<IRow> => {
  // generate documents Agregation object layout
  const docAgregation = allocateDocumentToCategories(documents);

  // For each categories generate the correct table row data
  return Object.entries(docAgregation).map(([
    cat,
    catDocuments,
  ]) => {
    // Ensure types is right
    const categoryName = DocumentNameValues[cat as DocumentNameValuesType];

    // Sort all documents to display only the latest one
    const { latestPdfDoc, latestXlsDoc } = getLatestDocInfo(catDocuments);

    // determine if the given doc is under download.
    const currentProcessing = isLoading && (
      (processingId === latestPdfDoc?.s3VersionID) || (processingId === latestXlsDoc?.s3VersionID)
    );

    // Generate the row
    return {
      rowItems: [
        giveProperName(categoryName),
        <CustomButton
          key={categoryName}
          latestPdfDocument={latestPdfDoc}
          latestXlsDocument={latestXlsDoc}
          isLoading={isLoading}
          currentProcessing={currentProcessing}
          downloadFunc={mutateFunction}
        />,
      ],
      rowId: cat,
    };
  });
};
