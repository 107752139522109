import csvPicto from 'src/assets/pictos/csvPicto.svg';
import pdfPicto from 'src/assets/pictos/pdfPicto.svg';
import { IDocumentDownloadInfo, IDocumentInformation } from 'src/pages/documents/Documents.types';
import { Spacer } from 'src/ui/spacer/Spacer';
import { PictoAsButton } from 'src/ui/button/picto/PictoAsButton';
import { PictoAsButtonWrapper } from 'src/ui/button/picto/wrapper/PictoAsButtonWrapper';

interface IDocumentsButtonCellProps {
  latestPdfDocument?: IDocumentInformation;
  latestXlsDocument?: IDocumentInformation;
  isLoading: boolean;
  currentProcessing: boolean;
  className?: string;
  downloadFunc: ({
    fileName,
    s3VersionID,
    fileExtension,
  }: IDocumentDownloadInfo) => Promise<unknown>;
}

export const DocumentsButtonsCell = ({
  latestPdfDocument,
  latestXlsDocument,
  isLoading,
  currentProcessing,
  className = '',
  downloadFunc,
}: IDocumentsButtonCellProps) => {
  const onClick = (
    document: IDocumentInformation,
  ) => {
    downloadFunc({
      fileName: document.fileName,
      s3VersionID: document.s3VersionID,
      fileExtension: document.fileExtension,
    });
  };

  return (
    <PictoAsButtonWrapper className={className} currentProcessing={currentProcessing}>
      <PictoAsButton
        onClick={() => {
          if (latestXlsDocument) {
            onClick(latestXlsDocument);
          }
        }}
        size='lg'
        isLoading={isLoading}
        disabled={latestXlsDocument === undefined}
        aria-label={`Téléchargement document csv ${latestXlsDocument?.s3VersionID}`}
        dataTestId={`test-documentsButtons-csv-${latestXlsDocument?.s3VersionID ?? 'disabled'}`}
        src={csvPicto}
      />
      <Spacer width='md' />
      <PictoAsButton
        onClick={() => {
          if (latestPdfDocument) {
            onClick(latestPdfDocument);
          }
        }}
        size='lg'
        isLoading={isLoading}
        disabled={latestPdfDocument === undefined}
        aria-label={`Téléchargement document pdf ${latestPdfDocument?.s3VersionID}`}
        dataTestId={`test-documentsButtons-pdf-${latestPdfDocument?.s3VersionID ?? 'disabled'}`}
        src={pdfPicto}
      />
    </PictoAsButtonWrapper>
  );
};
