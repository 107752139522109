import {
  QueryObserverOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import queryClient from 'src/AppQueryClient';

import {
  fetchContractOverview,
  fetchCustomerOverview,
  fetchCustomersByGroup,
  fetchEnergyConsumption,
  getExtractPerimeterDownload,
  getExtractPerimeterStatus,
  postExtractPerimeterRequest,
} from 'src/domains/perimeter/PerimeterClient';

import { download } from 'src/libs/miscellaneous';
import { EnergyType } from '../shared/DomainSharedTypes';
import { useGenerateQueryKey, useGenerateQueryKeyNotStrict } from '../shared/useGenerateQueryKey';
import { perimeterQueriesEnum } from './PerimeterKeys';
import { DetailSitePerimeterType, IDetailSiteLastStatementsQuery, IExportPerimeterExtractStatus } from './PerimeterTypes';
import { parseSiteDetailsMaxPowerData, parsedSIteDetailsMawPowerDataType } from './libs/dataParsers';
import { parseConsoOverview, parsedConsoOverviewDataType } from './libs/perimeterOverviewParser';
import { IConsumptionData, IDetailSitePerimeterResponse } from './overview/OverviewTypes';
import { fetchMaximalPower, fetchMeterReadings, fetchSiteOverview } from './site/SiteClient';
import { IMaximalPowerAction, IMeterReadingsRequest } from './site/site.types';
import { postMeterReading } from './site/arc/ArcClient';

interface IUsePerimeterConsoQuery{
  energy: EnergyType;
  enabled: boolean;
  customerId?: string;
  contractId?: string;
  siteId?: string;
}

export const usePerimeterConsoQuery = ({
  energy,
  enabled,
  customerId,
  contractId,
  siteId,
}: IUsePerimeterConsoQuery) => useQuery<{
  data: IConsumptionData;
}, AxiosError, parsedConsoOverviewDataType>({
  queryKey: useGenerateQueryKey({
    path: perimeterQueriesEnum.CONSO,
    params: [energy, customerId, contractId, siteId],
  }),
  queryFn: ({ signal }) => fetchEnergyConsumption(customerId, contractId, siteId, energy, signal),
  enabled,
  select: ({ data }) => parseConsoOverview(data),
  staleTime: 1000 * 60 * 60 * 12,
  retry: false,
});

export const usePerimeterDetailSiteMaxPowerQuery = (
  {
    customerId,
    contractId,
    siteId,
  }: DetailSitePerimeterType,
) => useQuery<{ data: IMaximalPowerAction }, AxiosError, parsedSIteDetailsMawPowerDataType>({
  queryKey: useGenerateQueryKey({
    path: perimeterQueriesEnum.SITE_DETAILS_MAX_POWER,
    params: [customerId, contractId, siteId],
  }),
  queryFn: () => fetchMaximalPower(customerId, contractId, siteId),
  select: ({ data }) => parseSiteDetailsMaxPowerData(data),
});

export const useCustomersContactQuery = (customerId: string | undefined) => useQuery({
  queryKey: useGenerateQueryKey({
    path: perimeterQueriesEnum.CUSTOMERS_CONTACT,
    params: [customerId],
  }),
  queryFn: () => fetchCustomerOverview(customerId),
  enabled: !!customerId,
  select: ({ data }) => data,
});

export const useSitesContactQuery = (
  customerId: string | undefined,
  contractId: string | undefined,
) => useQuery({
  queryKey: useGenerateQueryKey({
    path: perimeterQueriesEnum.SITES_CONTACT,
    params: [customerId, contractId],
  }),
  queryFn: () => Promise.all([
    fetchContractOverview(customerId, contractId),
    fetchCustomerOverview(customerId),
  ]),
  enabled: !!(customerId && contractId),
});

export const usePerimeterDetailSiteOverview = (
  {
    customerId,
    contractId,
    siteId,
  }: DetailSitePerimeterType,
) => useQuery({
  queryKey: useGenerateQueryKey({
    path: perimeterQueriesEnum.SITE_DETAILS_OVERVIEW,
    params: [customerId, contractId, siteId],
  }),
  queryFn: () => Promise.all([
    fetchSiteOverview(customerId, contractId, siteId),
    // We need both those query for the references names in the context
    fetchCustomerOverview(customerId),
    fetchContractOverview(customerId, contractId),
  ]),
  select: ([{ data: rep1 }, { data: rep2 }, { data: rep3 }]): IDetailSitePerimeterResponse => ({
    ...rep1,
    companyName: rep2.companyName,
    contractName: rep3.contractInformation.contractName,
  }),
});

export const usePerimeterDetailSiteLastStatementsQuery = <SelectType = IMeterReadingsRequest>(
  {
    customerId,
    contractId,
    siteId,
    readingPage = 1,
  }: IDetailSiteLastStatementsQuery,
  options?: QueryObserverOptions<IMeterReadingsRequest, AxiosError, SelectType>,
) => useQuery<IMeterReadingsRequest, AxiosError, SelectType>({
  queryKey: useGenerateQueryKey({
    path: perimeterQueriesEnum.SITE_DETAILS_LAST_STATEMENTS,
    params: [customerId, contractId, siteId, readingPage],
  }),
  queryFn: () => fetchMeterReadings(customerId, contractId, siteId, readingPage),
  ...options,
});

export const usePerimeterDetailsSiteARCMutation = (
  customerId: string,
  contractId: string,
  siteId: string,
) => {
  const keyToInvalidate = useGenerateQueryKey({
    path: perimeterQueriesEnum.SITE_DETAILS_LAST_STATEMENTS_NO_PAGES,
    params: [customerId, contractId, siteId],
  });
  return useMutation({
    mutationFn: (
      indexes: Record<string, string>[],
    ) => postMeterReading(
      indexes,
      customerId,
      contractId,
      siteId,
    ),
    retry: false,
    mutationKey: useGenerateQueryKey({ path: perimeterQueriesEnum.SITE_DETAILS_ARC }),
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: keyToInvalidate,
      });
      return response;
    },
    onError: (error: AxiosError) => error,
  });
};

export const useCustomersByGroup = () => useQuery({
  queryKey: useGenerateQueryKey({ path: perimeterQueriesEnum.CUSTOMERS_BY_GROUP }),
  queryFn: () => fetchCustomersByGroup(),
  select: (resp) => resp.data.customers,
});

export const usePerimeterExtractStatusQuery = <SelectType = IExportPerimeterExtractStatus>({
  isAdmin,
  options,
}: {
  isAdmin: boolean;
  options?: QueryObserverOptions<IExportPerimeterExtractStatus, AxiosError, SelectType>;
 }) => useQuery<IExportPerimeterExtractStatus, AxiosError, SelectType>({
   queryKey: useGenerateQueryKey({ path: perimeterQueriesEnum.PERIMETER_EXTRACT_STATUS }),
   queryFn: () => getExtractPerimeterStatus(),
   retry: false,
   staleTime: 15000,
   enabled: isAdmin,
   ...options,
 });

export const usePerimeterExtractDownloadMutation = (
  groupName: string,
  onErrorActions?: () => void,
) => useMutation({
  mutationKey: useGenerateQueryKey(
    {
      path: perimeterQueriesEnum.PERIMETER_EXTRACT_DOWNLOAD,
      params: [groupName],
    },
  ),
  mutationFn: () => getExtractPerimeterDownload(),
  onSuccess: (response) => {
    download(`${groupName}_perimeter_extract.csv`, response.data);
  },
  onError: () => {
    if (onErrorActions) onErrorActions();
  },
  retry: false,
});

export const usePerimeterExtractMutation = (
  onErrorActions?: () => void,
) => {
  const keyToInvalidate = useGenerateQueryKeyNotStrict(
    [perimeterQueriesEnum.PERIMETER_EXTRACT_STATUS],
  );

  return useMutation({
    mutationKey: useGenerateQueryKey({ path: perimeterQueriesEnum.PERIMETER_EXTRACT_REQUEST }),
    mutationFn: () => postExtractPerimeterRequest(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keyToInvalidate });
    },
    onError: () => {
      if (onErrorActions) onErrorActions();
    },
    retry: false,
  });
};
