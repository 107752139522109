import { InlineLoader } from 'src/ui/loader/InlineLoader';
import { StyledDiv, StyledLoaderWrapper } from './PictoAsButtonWrapper.styles';

export interface IPictoAsButtonWrapperProps {
  children: React.ReactNode;
  currentProcessing: boolean;
  className?: string;
}

export const PictoAsButtonWrapper = ({
  children,
  className = '',
  currentProcessing,
}: IPictoAsButtonWrapperProps) => (
  <StyledDiv className={className}>
    {children}
    <StyledLoaderWrapper processing={currentProcessing}>
      <InlineLoader />
    </StyledLoaderWrapper>
  </StyledDiv>
);
