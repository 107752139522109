import styled from 'src/theme/ThemeProvider';

export const SkeletonContainer = styled.div`
  width: 100%;

  div:last-child:not(:only-child) {
    width: 80%;
  }
`;

export const SkeletonItem = styled.div`
  width: 100%;
  height: 16px;
  margin-bottom: ${({ theme }) => theme.sizes.space.xs}px;
  border-radius: ${({ theme }) => theme.sizes.radii.xs}px;

  opacity: .7;
  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: ${({ theme }) => `${theme.colors.disable.loading}`};
    }

    100% {
      background-color: ${({ theme }) => `${theme.colors.transparency[200]}`};
    }
  }
`;
