import { useNavigate } from 'react-router-dom';

import { Typo } from 'src/ui/typography/Typo';
import { Column } from 'src/ui/Column/Column';
import searchText from 'src/texts/current/searchText.json';
import { getOptionForSizePerPage } from 'src/libs/miscellaneous';
import { PageObject, Pagination } from 'src/ui/pagination/Pagination';
import { Dropdown } from 'src/ui/dropdown/Dropdown';
import { Row } from 'src/ui/row/Row';
import { replaceText } from 'src/libs/text.utils';
import { SplitBox } from './split/SplitBox';
import { LevelTypes, IParsedSearchResults } from '../SearchDrawer.types';
import { pageSizeOptions } from '../SearchDrawer.constants';
import { computeRoute } from '../SearchDrawer.utils';

interface ISearchDrawerProps {
  level: LevelTypes;
  data: IParsedSearchResults;
  toggleDrawer(): void;
  submitPageChange(page: number): void;
  submitSizeChange(size: number): void;
}

export const SearchDrawerResults = ({
  level,
  toggleDrawer,
  data,
  submitPageChange,
  submitSizeChange,
}: ISearchDrawerProps) => {
  const navigate = useNavigate();

  const onPageChange = ({ selected }: PageObject) => submitPageChange(selected + 1);
  const onSizeChange = (size: number) => submitSizeChange(size);

  return (
    <Column overflow='hidden' dataTestId='SearchResult-Container-Column'>
      <Row
        justifyContent='space-between'
        alignItems='center'
        gap='sm'
      >
        <Typo.Caption>
          {replaceText(
            searchText.results[data.itemList?.length > 1 ? 'plural' : 'singular'],
            { replaceValues: { number: data.pagination.recordsCount } },
          )}
        </Typo.Caption>

        <Dropdown
          placeholder={searchText.results.sizePerPagePlaceholder}
          options={pageSizeOptions}
          onChange={(val) => onSizeChange(Number(val?.value))}
          dataTestId='Search-PageSizeSelection'
          value={getOptionForSizePerPage(data.pagination.size.toString())}
          isSearchable={false}
          ariaLabel={searchText.results.sizePerPagePlaceholder}
          customStyle={{ container: { width: '160px' } }}
        />
      </Row>
      <Column
        gap='xs'
        overflow='auto'
        margin={['sm', 0, 0, 0]}
        dataTestId='SearchResult-Column'
      >
        {data.itemList.map((item) => (
          <SplitBox
            itemList={item.data}
            title={item.name}
            subtitle={item.references[level] || ''}
            key={`${item.name} - ${item.references[level]}`}
            onClick={() => {
              toggleDrawer();
              navigate(computeRoute({ refs: item.references, level }));
            }}
          />
        ))}
      </Column>
      {data.pagination.size > 0 && (
        <Pagination
          onPageChange={onPageChange}
          numberOfPages={Math.ceil(data.pagination.recordsCount / data.pagination.size)}
          forcePage={data.pagination.currentPage - 1}
        />
      )}
    </Column>
  );
};
