import { useRef } from 'react';

import { ITicketWrapperProps } from 'src/domains/ticket/TicketsTypes';
import { dateConverter } from 'src/libs/miscellaneous';
import { TicketCard } from 'src/ui/business/ticketCard/TicketCard';
import { setTicketStatus, setTicketVariant } from 'src/pages/ticket/helpers';

export const TicketCardWrapper = ({
  ticket,
  onClick,
  currentId,
}: ITicketWrapperProps) => {
  const ref = useRef<null | HTMLDivElement>(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
      inline: 'nearest',
    });
    onClick(ticket);
  };

  return (
    <TicketCard
      ref={ref}
      current={currentId === ticket.id}
      onClick={handleClick}
      key={ticket.id}
      title={ticket.subject}
      id={ticket.id.toString()}
      date={dateConverter(ticket.createdAt.toString())}
      status={setTicketStatus(ticket.status)}
      dataTestId={ticket.id.toString()}
      variant={setTicketVariant(ticket.status)}
      loading={false}
    />
  );
};
