import { Dropdown } from 'src/ui/dropdown/Dropdown';
import { Row } from 'src/ui/row/Row';
import ticketPageText from 'src/texts/current/ticketPageText.json';
import {
  pageSizeOptions,
  pageStatusOptions,
} from 'src/pages/ticket/TicketPage.constants';
import { Choices } from 'src/ui/choices/Choices';
import { useTheme } from 'src/theme/ThemeProvider';
import { getOptionForSizePerPage } from 'src/libs/miscellaneous';
import * as Styled from './TicketPageHeader.styles';

interface ITicketPageHeader {
  pageSize: number;
  filterStatus: string[];
  handlePageSizeChange: (newSize: number) => void;
  handleStatusFilterChange: (status: string[]) => void;
}

export const TicketPageHeader = ({
  pageSize,
  filterStatus,
  handlePageSizeChange,
  handleStatusFilterChange,
}: ITicketPageHeader) => {
  const theme = useTheme();
  return (
    <Styled.HeaderOptionsContainer>
      <Row flexWrap='wrap' justifyContent='space-between'>
        <Styled.CustomFormInput
          htmlFor='filter by status'
          label={ticketPageText.statusFilterOptions.label}
        >
          <Choices
            selectedValues={filterStatus}
            multiChoice
            options={pageStatusOptions(theme)}
            onChange={(val: string[]) => handleStatusFilterChange(val)}
            dataTestId='ticketPageHeader-status'
          />
        </Styled.CustomFormInput>
        <Styled.CustomFormInput
          htmlFor='nombre de ticket par page'
          label={ticketPageText.pageSizeOptions.placeholder}
        >
          <Dropdown
            placeholder={ticketPageText.pageSizeOptions.placeholder}
            options={pageSizeOptions}
            onChange={(val) => handlePageSizeChange(Number(val?.value))}
            dataTestId='TicketPage-PageSizeSelection'
            value={getOptionForSizePerPage(pageSize.toString())}
            isSearchable={false}
            ariaLabel={ticketPageText.pageSizeOptions.placeholder}
          />
        </Styled.CustomFormInput>
      </Row>
    </Styled.HeaderOptionsContainer>
  );
};
