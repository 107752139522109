import { ICON_SIZE } from '../basic/Button.styles';
import { TButtonSize } from '../basic/Button.types';
import {
  IconButtonProps,
  CustomButton,
  CustomSvgWrapper,
} from './IconButton.styles';

interface IRenderedIconProps {
  size: TButtonSize;
  icon?: string;
  alignLeft?: boolean;
}

const RenderedIcon = ({
  icon,
  alignLeft,
  size,
}: IRenderedIconProps) => {
  if (icon) {
    return (
      <CustomSvgWrapper
        iconLeft={alignLeft}
        src={icon}
        buttonSize={size}
        size={ICON_SIZE[size]}
      />
    );
  }

  return null;
};

export const IconButton = ({
  variant,
  disabled,
  className,
  onClick,
  children,
  leftIcon,
  rightIcon,
  outline,
  width,
  isLoading,
  dataTestId,
  size = 'md',
  type,
}: IconButtonProps) => (
  <CustomButton
    className={className}
    variant={variant}
    onClick={onClick}
    disabled={disabled}
    outline={outline}
    width={width}
    isLoading={isLoading}
    dataTestId={dataTestId}
    size={size}
    type={type}
  >
    <RenderedIcon
      alignLeft
      icon={leftIcon}
      size={size}
    />
    {children}
    <RenderedIcon icon={rightIcon} size={size} />
  </CustomButton>
);
