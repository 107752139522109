import * as yup from 'yup';

export const schemaPointOfDelivery = yup.object().shape({
  pointOfDeliveryNumber: yup.string().when('billPointOfDeliveryName', {
    is: '',
    then: (schema) => schema.required(),
  }),
  billPointOfDeliveryName: yup.string(),
});

export const schemaBillConsolidation = yup.object().shape({
  billConsolidationReference: yup.string().when('billConsolidationName', {
    is: '',
    then: (schema) => schema.required(),
  }),
  billConsolidationName: yup.string(),
});
