import { QueryKey } from '@tanstack/react-query';
import { useContext } from 'react';
import { GroupReferenceContext } from 'src/contexts/GroupReferences.context';
import {
  EnumKeysPossiblePath,
  GlobalEnumKeysPattern,
  QueryEnymParams,
  globalEnumKeys,
  globalEnumKeysKey,
} from './GlobalQueriesKeysTypes';

const generateQueryKey = <
  InferedKey extends EnumKeysPossiblePath = EnumKeysPossiblePath
> (
    currentGroup: string,
    { path, params }: QueryEnymParams<InferedKey>,
    isGroupDependant = true,
  ): QueryKey => {
  const key = params || [];
  const globalKeys = globalEnumKeys as unknown as GlobalEnumKeysPattern;

  const keys = globalKeys[path];

  return [
    (isGroupDependant ? currentGroup : ''),
    ...(typeof keys === 'function' ? keys(...key) : keys),
  ];
};

/**
 * will add to every query keys potential global elements
 * should be use in most cases
 * @param path the query path using restricted possibilities defined
 *             in @file globalQueriesKeysTypes.ts
 * @param params the optionnal queries params, must be given is the path require it.
 * @param isGroupDependant does the query is dependant to the current group
 * @returns the correctly formed query key
 */
export const useGenerateQueryKey = <
  InferedKey extends EnumKeysPossiblePath = EnumKeysPossiblePath
> (
    props: QueryEnymParams<InferedKey>,
    isGroupDependant = true,
  ): QueryKey => {
  const { currentGroup } = useContext(GroupReferenceContext);
  return generateQueryKey(
    currentGroup,
    props,
    isGroupDependant,
  );
};

/**
 * Same as above @function useGenerateQueryKey but used when
 * you don't know all the params of the query when the hook must be call
 * @param isGroupDependant does the query is dependant to the current group
 * @returns a function with @param path & @param params as parameters
 *          that return the correctly formed query key
 */
export const useGenerateQueryKeyWithUnknownParams = <
  InferedKey extends EnumKeysPossiblePath = EnumKeysPossiblePath
> (isGroupDependant = true) => {
  const { currentGroup } = useContext(GroupReferenceContext);

  return (props: QueryEnymParams<InferedKey>) => generateQueryKey(
    currentGroup,
    props,
    isGroupDependant,
  );
};

/* Must be used only to generate globale query keys that can affect multiple queries at once.
 * To reset multiple queries sharing a common element in their keys,
 * or check if at least one queries is fetching among multiple sharing a key element.
*/
export const useGenerateQueryKeyNotStrict = (
  elements: globalEnumKeysKey[],
  isGroupDependant = true,
) => {
  const { currentGroup } = useContext(GroupReferenceContext);
  return [
    (isGroupDependant ? currentGroup : ''),
    ...elements,
  ];
};
