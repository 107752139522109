import { Sidebar } from '@caprikorn/dynamo-ui';
import styled from 'src/theme/ThemeProvider';
import { ImgWrapper } from 'src/ui/pictos/wrappers/ImgWrapper';
import { Typo } from 'src/ui/typography/Typo';

interface IMenuTitleProps {
  children: React.ReactNode;
  icon: string;
  isActive?: boolean;
  expand?: boolean;
}

type StyledLiType = Pick<IMenuTitleProps, 'isActive'>;

const Container = styled.div`
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.sizes.radii.sm}px;
  transition: background-color 100ms ease-in-out;
  margin: ${({ theme }) => theme.sizes.space.xs}px ${({ theme }) => theme.sizes.space.md}px;
  max-width: 275px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.transparency[200]}
  }
`;

export const CameleonContainer = styled(Container)`
  cursor: default;
  &:hover {
    background-color: transparent;
  }
`;

export const IconContainer = styled(Typo.Span)`
   margin: ${({ theme }) => theme.sizes.space.xs}px;
`;

export const Text = styled(Typo.Span)<StyledLiType>`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.sizes.space.xxs}px;
  width: 100%;
  transition: opacity 400ms ease-in-out,width 400ms ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.text.reverse : theme.colors.text.primary)};
  white-space: pre;
`;

export const CameleonIcon = styled(ImgWrapper)`
  background-color: white;
  border-radius: ${({ theme }) => theme.sizes.radii.xl}px;
  border: 2px solid ${({ theme }) => theme.colors.semantic.error[50]};
  overflow: hidden;
  align-items: center;
  display: flex;
`;

export const MenuBar = styled(Sidebar)`
  background-color: ${({ theme }) => theme.colors.background.elevated};
`;

export const MenuBarHeader = styled(Sidebar.Header)`
  padding: ${({ theme }) => `${theme.sizes.space.xs}px ${theme.sizes.space.xxs}px`};
`;
