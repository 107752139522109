import {
  ICustomerOverview,
  IConsumptionData,
  IContractInfosDetailed,
  ISiteDataDetailed,
} from 'src/domains/perimeter/overview/OverviewTypes';
import { replaceText, thousandsSeperator } from 'src/libs/text.utils';
import { formatEnergyWording } from 'src/pages/perimeter/lib';
import { IInfoItemProps } from 'src/ui/infos/InfoItem.types';
import texts from 'src/texts/current/perimeterTableOverviewContentText.json';
import { dateConverter } from 'src/libs/miscellaneous';
import { formatContractAddress } from './dataParsers';
import { ISiteDistributor } from '../site/site.types';

const overviewText = texts.overview;

type ISiteOverviewParser = Omit<
  ISiteDataDetailed, 'consumptionContact'
  > & { distributorInformation: ISiteDistributor | undefined }
  & {registrationNumber: string | undefined };

//  Check if given string is null or empty, if not, return the string with thousands separator.
const setConsumptionValue = (value?: string | null) => (value ? `${thousandsSeperator(Number(value))} kWh` : 'Indisponible');

export const parseContractOverviewInfos = (data?: IContractInfosDetailed) => {
  const result = [];
  if (data?.contractId) {
    result.push({
      name: replaceText(overviewText.sites.ref),
      value: data.contractId,
    });
  }
  if (data?.costCenter) {
    result.push({
      name: overviewText.sites.costCenter,
      value: data.costCenter,
    });
  }
  if (data?.service) {
    result.push({
      name: overviewText.sites.service,
      value: data.service,
    });
  }

  return {
    title: result.length > 0
      ? replaceText(overviewText.sites.title, { isFirstLetterCapital: true })
      : replaceText(overviewText.client.title, { isFirstLetterCapital: true }),
    data: result,
  };
};

export const parseClientOverviewInfos = (data?: ICustomerOverview) => {
  const result = [];
  if (data?.customerId) {
    result.push({
      name: replaceText(overviewText.contract.ref),
      value: data.customerId,
    });
  }
  if (data?.siren) {
    result.push({
      name: overviewText.contract.siren,
      value: data.siren,
    });
  }
  if (data?.apeCode) {
    result.push({
      name: overviewText.contract.ape,
      value: data.apeCode,
    });
  }

  return {
    title: result.length > 0
      ? replaceText(overviewText.contract.title, { isFirstLetterCapital: true })
      : replaceText(overviewText.client.title, { isFirstLetterCapital: true }),
    data: result,
  };
};

export const parseSiteInfos = (data: ISiteOverviewParser) => {
  const {
    siteInformation,
    consumptionAddress,
    distributorInformation,
    registrationNumber,
  } = data;

  const formattedInfos = {
    title: `${replaceText(overviewText.site.title, { isFirstLetterCapital: true })} ${siteInformation?.energy && formatEnergyWording(siteInformation?.energy)}`,
    data: [
      { name: siteInformation?.energy === 'GAZ' ? overviewText.site.pce : overviewText.site.pdl, value: siteInformation?.siteId },
      { name: overviewText.site.address, value: consumptionAddress ? formatContractAddress(consumptionAddress) : '--' },
      { name: overviewText.site.subscriptionDate, value: siteInformation?.supplyStartDate ? dateConverter(siteInformation.supplyStartDate.toString()) : '--' },
    ],
  };

  if (siteInformation?.supplyEndDate) {
    formattedInfos.data.push(
      {
        name: overviewText.site.cancellationDate,
        value: dateConverter(siteInformation?.supplyEndDate.toString()),
      },
    );
  }

  // if distributor.name is defined you may want to show it
  // value can be null so we avoid rendering empty section in this case
  if (distributorInformation?.name && distributorInformation?.number) {
    formattedInfos.data.push(
      {
        name: `${overviewText.site.distributorInfos} ${distributorInformation.name} : `,
        value: distributorInformation.number,
      },
    );
  }

  // if registration number is defined, you may want to show it
  // registration number is unknown when is value is 999 or 000
  if (registrationNumber && registrationNumber !== '999' && registrationNumber !== '000') {
    formattedInfos.data.push(
      {
        name: overviewText.site.registrationNumber,
        value: registrationNumber,
      },
    );
  }

  return formattedInfos;
};

export type parsedConsoOverviewDataType = {
  name: string;
  value: string;
}[];

export const parseConsoOverview = (consoData?: IConsumptionData) => {
  const { lastMonth, lastYear } = overviewText.conso;

  const consumptionData = [
    { name: lastMonth, value: `${setConsumptionValue(consoData?.previousMonthInKwh)}` },
    { name: lastYear, value: `${setConsumptionValue(consoData?.currentYearInKwh)}` },
  ];

  return consumptionData;
};

const sitesContact = overviewText.sites.contactTitle;
const regroupementContact = overviewText.contract.contactTitle;
const siteContact = overviewText.site.contactTitle;

type ITitle = typeof sitesContact | typeof regroupementContact | typeof siteContact;

interface IContact {
  contactData?: {
    civility?: string;
    email?: string;
    firstName: string;
    lastName: string;
    mobilePhone?: string;
    phone?: string;};
  contactName?: string;
  contactEmail?: string;
  addressData?: {
    streetNumber: string;
    street: string;
    zipCode: string;
    city: string;
  };
  title: ITitle;
}

export const parseContact = ({
  contactEmail,
  contactName,
  contactData,
  addressData,
  title,
}: IContact) => {
  const contact = contactData?.lastName && `${contactData?.civility} ${contactData?.firstName ?? ''} ${contactData?.lastName}`;

  const result = [] as Array<IInfoItemProps>;
  if (contactName || contact) {
    result.push({
      name: overviewText.contact.name,
      value: contactName || contact,
    });
  }
  if (contactEmail || contactData?.email) {
    result.push({
      name: overviewText.contact.email,
      value: contactEmail || contactData?.email,
    });
  }
  if (addressData) {
    result.push({
      name: overviewText.contact.address,
      value: formatContractAddress(addressData),
    });
  }
  if (contactData?.phone) {
    result.push({
      name: overviewText.contact.phone,
      value: contactData.phone,
    });
  }
  if (contactData?.mobilePhone) {
    result.push({
      name: overviewText.contact.mobilePhone,
      value: contactData.mobilePhone,
    });
  }

  if (result.length > 0) {
    return {
      title: `${overviewText.contact.contactType} ${title}`,
      data: result,
    };
  }
  // It's undefined because if there is no contact, we don't want the block to appear
  return undefined;
};
