export const enum perimeterDataQueriesEnum {
  DATA = 'DATA',
  DATA_CUSTOMERS = 'DATA_CUSTOMERS',
  DATA_CONTRACTS = 'DATA_CONTRACTS',
  DATA_SITES = 'DATA_SITES',
}

export const perimeterDataKeysEnum = {
  [perimeterDataQueriesEnum.DATA_CUSTOMERS]: (
    pageNumber: number,
    pathname: string,
    search?: string,
  ) => [
    perimeterDataQueriesEnum.DATA,
    perimeterDataQueriesEnum.DATA_CUSTOMERS,
    pageNumber,
    pathname,
    search,
  ],
  // TODO : check the reason behind this nightmare of querykeys and fix it
  [perimeterDataQueriesEnum.DATA_CONTRACTS]: (
    pageNumber: number,
    pathname: string,
    search?: string,
  ) => [
    perimeterDataQueriesEnum.DATA,
    `${perimeterDataQueriesEnum.DATA_CONTRACTS}-${pageNumber as number - 1}-${search}-${pathname}`,
  ],
  [perimeterDataQueriesEnum.DATA_SITES]: (
    pageNumber: number,
    pathname: string,
    customerId?: string,
    contractId?: string,
    search?: string,
  ) => [
    perimeterDataQueriesEnum.DATA,
    perimeterDataQueriesEnum.DATA_SITES,
    customerId,
    contractId,
    pageNumber,
    search,
    pathname,
  ],
} as const;
