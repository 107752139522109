import { EnergyType } from '../shared/DomainSharedTypes';

export const enum dashboardQueriesEnum {
  FETCH_CONSUMPTION = 'FETCH_CONSUMPTION',
}

export const dashboardKeysEnum = {
  [dashboardQueriesEnum.FETCH_CONSUMPTION]: (
    energyType: EnergyType,
  ) => [dashboardQueriesEnum.FETCH_CONSUMPTION, energyType],
} as const;
