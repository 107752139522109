import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { FormInput } from 'src/ui/input/form/FormInput';
import searchText from 'src/texts/current/searchText.json';
import { removeEmptyValues } from 'src/libs/miscellaneous';
import { PrefixedSuffixedInput } from 'src/ui/input/specials/PrefixedSuffixedInput';
import closePicto from '@caprikorn/dynamo-icons/closeMd.svg';
import { Typo } from 'src/ui/typography/Typo';
import { useSearchPointOfDeliveryMutation } from 'src/domains/search/SearchQueries';
import { IPointOfDeliveryPayload } from 'src/domains/search/SearchTypes';
import { replaceText } from 'src/libs/text.utils';
import { schemaPointOfDelivery } from '../SearchDrawer.validation';
import { IPointOfDeliveryForm } from '../SearchDrawer.types';
import * as Styled from '../SearchDrawer.styles';
import { SearchForm } from './SearchForm';
import { parsePointsOfDeliveryResponse } from '../SearchDrawer.utils';

const { sites: sitesText } = searchText.forms;

export const PointOfDeliveryForm = ({ toggleDrawer }: { toggleDrawer(): void}) => {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
    getValues,
  } = useForm<IPointOfDeliveryForm>({
    resolver: yupResolver(schemaPointOfDelivery),
    reValidateMode: 'onBlur',
    defaultValues: {
      pointOfDeliveryNumber: '',
      billPointOfDeliveryName: '',
    },
  });

  const {
    data: pointOfDeliveryData,
    mutate,
    isError,
    isPending,
    reset,
  } = useSearchPointOfDeliveryMutation();
  const [currentSize, setCurrentSize] = useState<number>(10);

  const onSubmit = ({ page, size }: { page?: number, size?: number }) => {
    const data = getValues();
    const formatedData = removeEmptyValues(data);
    // TODO: check this madness with the boss when he's back
    // One option is partial of the incoming object but not sure
    mutate({
      inputsPayload: formatedData,
      paginationPayload: {
        page: page ?? 1,
        size: size ?? currentSize,
      },
    } as unknown as IPointOfDeliveryPayload);
  };

  const submitPageChange = (page: number) => onSubmit({ page });
  const submitSizeChange = (size: number) => {
    setCurrentSize(size);
    onSubmit({ size });
  };

  return (
    <Styled.CustomForm onSubmit={handleSubmit(onSubmit)}>
      <SearchForm
        toggleDrawer={toggleDrawer}
        level='pointOfDelivery'
        data={pointOfDeliveryData && parsePointsOfDeliveryResponse(pointOfDeliveryData.data)}
        isError={isError}
        isLoading={isPending}
        submitPageChange={submitPageChange}
        submitSizeChange={submitSizeChange}
      >
        <FormInput htmlFor='labelPointOfDelivery' label={replaceText(sitesText.labelInput.label)}>
          <PrefixedSuffixedInput
            disabled={isPending}
            suffixProps={{
              onClick: () => {
                reset();
                resetField('billPointOfDeliveryName');
              },
              content: closePicto,
            }}
            type='text'
            dataTestId='input-billPointOfDeliveryName'
            placeholder={sitesText.labelInput.placeholder}
            {...register('billPointOfDeliveryName')}
          />
        </FormInput>
        <FormInput htmlFor='referencePointOfDelivery' label={replaceText(sitesText.refInput.label)}>
          <PrefixedSuffixedInput
            disabled={isPending}
            suffixProps={{
              onClick: () => {
                reset();
                resetField('pointOfDeliveryNumber');
              },
              content: closePicto,
            }}
            type='text'
            dataTestId='input-pointOfDeliveryNumber'
            placeholder={sitesText.refInput.placeholder}
            {...register('pointOfDeliveryNumber')}
          />
        </FormInput>
        {(errors.billPointOfDeliveryName || errors.pointOfDeliveryNumber) && (
        <Typo.Caption type='error'>
          {searchText.forms.hint}
        </Typo.Caption>
        )}
      </SearchForm>
    </Styled.CustomForm>
  );
};
