export const localStorageKey = {
  email: 'email',
  cameleonEmail: 'cameleonEmail',
  cameleonFullName: 'cameleonFullName',
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  menuCollapsed: 'menuCollapsed',
  theme: 'theme',
  currentGroupRef: 'currentGroupRef',
} as const;

export type TlocalStorageKey = keyof typeof localStorageKey;

export const getLocalStorageData = (key: TlocalStorageKey) => localStorage.getItem(key) ?? '';

export const removeLocalStorageData = (key: TlocalStorageKey) => localStorage.removeItem(key);

export const setLocalStorageData = (
  key: TlocalStorageKey,
  value: string,
) => localStorage.setItem(key, value);
