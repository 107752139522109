import { localStorageKey, removeLocalStorageData, TlocalStorageKey } from 'src/libs/localStorage.utils';
import { routes } from 'src/router/RouteConstants';

export const isAuthenticated = () => {
  const token = localStorage.getItem(localStorageKey.accessToken);
  return !!token;
};

export const onLogout = () => {
  // Copy localStorageKey omiting some props.
  const propsLocalStorageToReset = (({
    menuCollapsed: _menuCollapsed,
    theme: _theme,
    ...object
  }) => object)(localStorageKey);

  const storageKeys = Object.keys(propsLocalStorageToReset) as Array<TlocalStorageKey>;
  storageKeys.forEach((key) => removeLocalStorageData(key));
  window.location.href = routes.AUTH_LOGIN;
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
};
