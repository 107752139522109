export const enum commonQueriesEnum {
  BILLS_DOWNLOAD = 'BILLS_DOWNLOAD',
  TECH_REQUEST = 'TECH_REQUEST',
  EXPORT_DATA_REQUEST = 'EXPORT_DATA_REQUEST',
  GROUPS = 'GROUPS',
  GROUP_DETAIL = 'GROUP_DETAIL',
}

export const commonKeysEnum = {
  [commonQueriesEnum.BILLS_DOWNLOAD]: [commonQueriesEnum.BILLS_DOWNLOAD],
  [commonQueriesEnum.TECH_REQUEST]: [commonQueriesEnum.TECH_REQUEST],
  [commonQueriesEnum.EXPORT_DATA_REQUEST]: [commonQueriesEnum.EXPORT_DATA_REQUEST],
  [commonQueriesEnum.GROUPS]: [commonQueriesEnum.GROUPS],
  [commonQueriesEnum.GROUP_DETAIL]: [commonQueriesEnum.GROUP_DETAIL],
} as const;
