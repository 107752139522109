import { Text, TextTypes } from 'src/ui/typography/text/Text';

export interface ISpanProps {
  children: string | React.ReactNode;
  size?: 'md' | 'lg';
  weight?: 'regular' | 'semi-bold' | 'bold';
  underline?: boolean;
  className?: string;
  type?: TextTypes;
  dataTestId?: string;
}

export const Span: React.FC<ISpanProps> = ({
  children,
  underline,
  size = 'md',
  weight = 'regular',
  className,
  type,
  dataTestId,
}: ISpanProps) => (
  <Text
    tag='span'
    className={className}
    size={size}
    underline={underline}
    weight={weight}
    type={type}
    dataTestId={dataTestId}
  >
    {children}
  </Text>
);
