import { ErrorCodes } from 'src/domains/error/ErrorConstants';
import { replaceText } from 'src/libs/text.utils';
import commonText from 'src/texts/current/commonText.json';
import documentsText from 'src/texts/current/documentsText.json';
import { DocumentErrorNamesType, DocumentErrorNames } from './Documents.types';

const getDocumentFailMessage = (status: number | undefined, supportEmail: string) => {
  switch (status) {
    case ErrorCodes.NO_CONTENT:
      return documentsText.notifications.notifEmpty;
    case ErrorCodes.REQUEST_ENTITY_TOO_LARGE:
      return replaceText(
        documentsText.notifications.notifTooHeavy,
        { replaceValues: { supportEmail } },
      );
    default:
      return commonText.errors.download;
  }
};

export const getFailMessage = (
  errorCase: DocumentErrorNamesType,
  status: number | undefined,
  supportEmail = '',
) => {
  switch (errorCase) {
    case DocumentErrorNames.DOCUMENT_DOWNLOAD:
    case DocumentErrorNames.DOCUMENT_EMPTY:
      return getDocumentFailMessage(status, supportEmail);
    case DocumentErrorNames.EXTRACT_DOWNLOAD:
      return replaceText(
        documentsText.notifications.notifExtractDownloadFail,
        { replaceValues: { supportEmail } },
      );
    case DocumentErrorNames.EXTRACT_PERIM_GENERATE:
      return replaceText(
        documentsText.notifications.notifExtractGenerateFail,
        { replaceValues: { supportEmail } },
      );
    default:
      return '';
  }
};
