import errorImage from 'src/assets/images/current/generic_error.svg';
import { replaceText } from 'src/libs/text.utils';
import searchText from 'src/texts/current/searchText.json';
import styled from 'src/theme/ThemeProvider';
import { Column } from 'src/ui/Column/Column';
import { Spacer } from 'src/ui/spacer/Spacer';
import { Typo } from 'src/ui/typography/Typo';
import { Title } from 'src/ui/typography/title/Title';
import { LevelTypes } from '../../SearchDrawer.types';

const MargedLi = styled.li`
  margin: ${({ theme }) => `${theme.sizes.border.xl}px`};
`;

export const EmptyState = ({ level }: { level: LevelTypes }) => {
  const texts = searchText.emptyState[level];

  return (
    <Column
      margin={['lg']}
      justifyContent='center'
      alignItems='flex-start'
      dataTestId='SearchResultEmpty-Column'
    >
      <img src={errorImage} alt='error' />
      <Title tag='h2' titleStyle='h4'>{searchText.emptyState.title}</Title>
      <Spacer height='lg' />
      <Typo.Paragraph weight='bold'>{replaceText(texts.subtitle)}</Typo.Paragraph>
      <Typo.Paragraph>{texts.paragraph}</Typo.Paragraph>
      <ul>
        {texts.lists.map((li) => (
          <MargedLi key={li.label}>
            <Typo.Paragraph>
              <b>{replaceText(li.label)}</b>
              {' '}
              {replaceText(li.parenthesis)}
            </Typo.Paragraph>
            {li?.extraInfo && <Typo.Paragraph>{li.extraInfo}</Typo.Paragraph>}
          </MargedLi>
        ))}
      </ul>
    </Column>
  );
};
