import styled from 'src/theme/ThemeProvider';
import { IconButtonProps } from '../icon/IconButton.styles';
import { IconButton } from '../icon/IconButton';

type OmitedIconProps = Omit<IconButtonProps, 'loading' | 'width' | 'children' | 'outline'>;

export interface ITextButtonProps extends OmitedIconProps {
  children?: React.ReactNode;
  squish?: boolean;
}

// Check height in button
const StyledTextButton = styled(IconButton)<ITextButtonProps>`
  border: none;
  ${({ squish }) => squish && 'height: auto'};
  ${({ squish }) => squish && 'padding: 0'};
  
  // If no children remove the margin for picto elements
  & > * {
    ${({ children }) => !children && 'margin: 0'};
  }
`;

export const TextButton = ({
  variant,
  disabled,
  className,
  onClick,
  children,
  leftIcon,
  rightIcon,
  dataTestId,
  size = 'md',
  squish,
}: ITextButtonProps) => (
  <StyledTextButton
    className={className}
    variant={variant}
    onClick={onClick}
    disabled={disabled}
    outline
    dataTestId={dataTestId}
    size={size}
    leftIcon={leftIcon}
    rightIcon={rightIcon}
    squish={squish}
  >
    {children}
  </StyledTextButton>
);
