import { useMutation } from '@tanstack/react-query';

import { fetchSearchBillConsolidationResults, fetchSearchPointOfDeliveryResults } from './SearchClient';
import { useGenerateQueryKey } from '../shared/useGenerateQueryKey';
import { searchQueriesEnum } from './SearchKeys';
import { IBillConsolidationPayload, IPointOfDeliveryPayload } from './SearchTypes';

export const useSearchBillConsolidationMutation = () => useMutation({
  mutationFn: (
    payload: IBillConsolidationPayload,
  ) => fetchSearchBillConsolidationResults(payload),
  retry: false,
  mutationKey: useGenerateQueryKey({ path: searchQueriesEnum.SEARCH_BILL_CONSOLIDATIONS }),
});

export const useSearchPointOfDeliveryMutation = () => useMutation({
  mutationFn: (payload: IPointOfDeliveryPayload) => fetchSearchPointOfDeliveryResults(payload),
  retry: false,
  mutationKey: useGenerateQueryKey({ path: searchQueriesEnum.SEARCH_POINTS_OF_DELIVERY }),
});
