import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ErrorNotFound } from 'src/components/errors/ErrorNotFound';
import { CenterContentLayout } from 'src/components/layouts/position/CenterContentLayout';
import { routes } from './RouteConstants';

const UserDetailsPageProvider = lazy(() => import('src/pages/access/details/UserDetailsPageProvider'));
const AccessPage = lazy(() => import('src/pages/access/AccessPage'));
const UserListPageProvider = lazy(() => import('src/pages/access/list/UserListPageProvider'));
const CreateUserPageProvider = lazy(() => import('src/pages/access/create/CreateUserPageProvider'));

export const AccessManagerRouter = () => (
  <Suspense fallback={null}>
    <Routes>
      <Route path='/' element={<AccessPage />}>
        <Route path='/' element={<UserListPageProvider />} />
        <Route path={routes.ACCESS_MANAGER_USER} element={<UserDetailsPageProvider />} />
        <Route path={routes.ACCESS_MANAGER_CREATE} element={<CreateUserPageProvider />} />

        <Route
          path='*'
          element={(
            <CenterContentLayout>
              <ErrorNotFound />
            </CenterContentLayout>
          )}
        />
      </Route>
    </Routes>
  </Suspense>
);
