import { replaceText } from 'src/libs/text.utils';
import { Link } from 'src/ui/typography/link/Link';
import styled from 'src/theme/ThemeProvider';
import authText from 'src/texts/current/authPageText.json';

const CustomLink = styled(Link)`
  text-align: center;
  margin-top:  ${({ theme }) => theme.sizes.space.xs}px;
`;

export const SubscriptionLink = () => (
  <CustomLink
    type='paragraph-md'
    href={authText.form.login.redirectUrl}
    dataTestId='link-redirect-loginForm'
    target='_blank'
  >
    {replaceText(authText.form.login.redirect) }
  </CustomLink>
);
