import { QueryClient } from '@tanstack/react-query';
import { TimeDuration, defaultQueryOptions } from './domains/shared/QueriesConstants';

const queriesOptions = defaultQueryOptions;

if (window.Cypress) {
  defaultQueryOptions.staleTime = 0 as TimeDuration;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...queriesOptions,
    },
  },
});

export default queryClient;
