import styled from 'src/theme/ThemeProvider';
import { DrawerContainer } from 'src/ui/overlays/drawer/container/DrawerContainer';
import { DrawerContent } from 'src/ui/overlays/drawer/content/DrawerContent';

export const CustomList = styled.ul`
  display: flex;
  padding-left: 0;
  margin: ${({ theme }) => `${theme.sizes.space.xs}px`};
  margin-top: 0;
  list-style: none;
`;

export const CustomDrawerContent = styled(DrawerContent)`
   div[class^='DrawerContent_headerWrapper'] {
    padding-bottom: var(--sizeSpaceXxs);
  }
`;
export const CustomDrawerContainer = styled(DrawerContainer)`
  margin: ${({ theme }) => ` ${theme.sizes.space.xxs}px `};
  margin-top: 0;
  padding: ${({ theme }) => theme.sizes.space.md}px;
  overflow: hidden;
`;

export const CustomForm = styled.form`
  overflow: hidden;
  height: inherit;
`;
