import { filterValues } from './TicketsTypes';

export const concatFilterValues = (filters: filterValues[]) => {
  const maxFilterQuantity = 3;
  const isStatusUsed = filters.length > 0 && filters.length < maxFilterQuantity;
  if (isStatusUsed) {
    const str = filters.map((value) => `&status[]=${value}`).join('');
    return str;
  }
  return '';
};
