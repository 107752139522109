import styled from 'src/theme/ThemeProvider';
import { FormInput } from 'src/ui/input/form/FormInput';
import { Typo } from 'src/ui/typography/Typo';
import { Title } from 'src/ui/typography/title/Title';

export const InfoText = styled(Typo.Span)`
  font-style: italic;  
`;

// TODO: TITLE SHOULD HAVE THIS PROPS ALWAYS (FOR LATER)
export const CustomTitle = styled(Title)`
  overflow-wrap: break-word;  
`;

export const ShieldFormInput = styled(FormInput)`
  flex: 1;
  min-width: 250px;
`;
