import { useQuery } from '@tanstack/react-query';
import { getGroups } from './CommonClient';
import { commonQueriesEnum } from './CommonKeys';

/**
 * Put this query on a specific file to avoid
 * import dependancy circle with GroupReferencesContext
 */

export const useGroupsQuery = () => useQuery({
  queryKey: [commonQueriesEnum.GROUPS],
  queryFn: () => getGroups(),
  select: (res) => res.groups,
});
