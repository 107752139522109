import {
  useMemo,
  createContext,
  useState,
  useEffect,
} from 'react';
import { axiosInstance } from 'src/libs/requests';
import commonText from 'src/texts/current/commonText.json';
import { setLocalStorageData, getLocalStorageData } from 'src/libs/localStorage.utils';
import { GROUP_ID_LENGTH } from 'src/domains/shared/constants';
import { useGroupsQuery } from 'src/domains/common/AllGroupQuery';

interface IGroupReferenceContext {
  changeCurrentGroup: (newRef: string) => void;
  currentGroup: string;
}

export const GroupReferenceContext = createContext<IGroupReferenceContext>({
  changeCurrentGroup: () => true,
  currentGroup: commonText.groups.dropdownNoMatch,
});

GroupReferenceContext.displayName = 'GroupReferenceContext';

export const GroupReferenceContextProvider = (
  { children }: { children: React.ReactNode },
) => {
  const [currentGroup, setCurrentGroup] = useState<string>(getLocalStorageData('currentGroupRef'));
  const { data } = useGroupsQuery();

  const changeCurrentGroup = (newRef: string): void => {
    setLocalStorageData('currentGroupRef', newRef);
    setCurrentGroup(newRef);
    axiosInstance.defaults.headers.common['x-group-reference'] = newRef;
  };

  const groupReferenceContextValues = useMemo(() => ({
    changeCurrentGroup,
    currentGroup,
  }), [currentGroup, changeCurrentGroup]);

  useEffect(() => {
    if (currentGroup.length !== GROUP_ID_LENGTH
      && !data?.map((d) => d.reference).includes(currentGroup)) {
      changeCurrentGroup(data?.[0].reference || '');
    }
  }, [data]);

  return (
    <GroupReferenceContext.Provider value={groupReferenceContextValues}>
      {children}
    </GroupReferenceContext.Provider>
  );
};
