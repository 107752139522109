import styled from 'src/theme/ThemeProvider';
import { Spacer } from 'src/ui/spacer/Spacer';
import { ITitleTags, Title } from 'src/ui/typography/title/Title';
import { Typo } from 'src/ui/typography/Typo';
import errorsText from 'src/texts/current/errorsText.json';
import { errorImageSelector, ErrorImageType } from './errorImageSelector';

export interface IErrorTemplateTitleProps {
  titleTag?: ITitleTags;
  titleStyle?: ITitleTags;
}

export interface IErrorTemplateProps extends IErrorTemplateTitleProps {
  children?: React.ReactNode;
  imageType: ErrorImageType;
  title?: string;
  text?: string | React.ReactNode;
  dataTestId?: string;
}

const StyledDiv = styled.div`
  text-align: center;
`;

export const ErrorTemplate = ({
  title = errorsText.default.title.small,
  titleTag = 'h3',
  titleStyle,
  text,
  children,
  dataTestId,
  imageType,
}: IErrorTemplateProps) => (
  <StyledDiv data-testid={dataTestId}>
    <img
      src={errorImageSelector(imageType)}
      alt='error'
      height='100'
    />

    <Spacer height='lg' />

    <StyledDiv>
      <Title tag={titleTag} titleStyle={titleStyle}>
        {title}
      </Title>
      <Spacer height='lg' />
      {text && <Typo.Paragraph>{text}</Typo.Paragraph>}
    </StyledDiv>
    {children}
  </StyledDiv>
);
