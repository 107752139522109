import { Typo } from 'src/ui/typography/Typo';
import styles from '../tab/TabItem.module.css';

export interface ITabSwitcherProps {
  content: string;
  dataTestId?: string;
  className?: string;
  isActive?: boolean;
  onClick(): void;
}

export const TabSwitcher: React.FC<ITabSwitcherProps> = ({
  content,
  isActive,
  dataTestId = 'TabItem',
  className = '',
  onClick,
}: ITabSwitcherProps) => (
  <li
    className={[
      styles.TabItem,
      styles.TabItemLink,
      isActive ? styles.TabItemLinkActive : '',
      className,
    ].join(' ')}
    data-testid={dataTestId}
    onClick={onClick}
    aria-hidden='true'
  >
    <Typo.Span
      className={[isActive ? styles.TabItemLinkActive : ''].join(' ')}
    >
      {content}
    </Typo.Span>
  </li>
);
