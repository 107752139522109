export const enum authQueriesEnum {
  LOGIN = 'LOGIN',
  GROUP = 'GROUP',
  RESET_PASSWORD = 'RESET_PASSWORD',
  ASK_RESET_PASSWORD_LINK = 'ASK_RESET_PASSWORD_LINK',
  RESET_PASSWORD_LINK = 'RESET_PASSWORD_LINK',
  RESET_INITIAL_PASSWORD = 'RESET_INITIAL_PASSWORD',
}

export const authKeysEnum = {
  [authQueriesEnum.LOGIN]: [authQueriesEnum.LOGIN],
  [authQueriesEnum.GROUP]: [authQueriesEnum.GROUP],
  [authQueriesEnum.RESET_PASSWORD]: [authQueriesEnum.RESET_PASSWORD],
  [authQueriesEnum.ASK_RESET_PASSWORD_LINK]: [authQueriesEnum.ASK_RESET_PASSWORD_LINK],
  [authQueriesEnum.RESET_PASSWORD_LINK]: [authQueriesEnum.RESET_PASSWORD_LINK],
  [authQueriesEnum.RESET_INITIAL_PASSWORD]: [authQueriesEnum.RESET_INITIAL_PASSWORD],
} as const;
