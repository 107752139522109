import { useContext } from 'react';
import { GroupReferenceContext } from 'src/contexts/GroupReferences.context';
import { useGroupsQuery } from 'src/domains/common/AllGroupQuery';
import { computeGroupOptions, labelForGroupDropdown } from 'src/domains/common/utils/common.utils';
import commonText from 'src/texts/current/commonText.json';
import { DropdownOption } from 'src/ui/dropdown/Dropdown';

export const useGroupsDropdown = (
  onGroupChange: Array<() => void> = [],
) => {
  const { data } = useGroupsQuery();
  const { currentGroup, changeCurrentGroup } = useContext(GroupReferenceContext);

  const formattedGroups = computeGroupOptions(data);

  return {
    groupsDropdownOptions: formattedGroups,
    groupsOnDropdownChange: (
      select: DropdownOption | null,
    ) => {
      onGroupChange?.forEach((fn) => fn());
      changeCurrentGroup(select?.value ?? commonText.groups.dropdownNoMatch);
    },
    groupsDropdownValue: {
      label: labelForGroupDropdown(formattedGroups, currentGroup),
      value: currentGroup,
    },
  };
};
