import { css } from '@emotion/react';

import styled, { Theme } from 'src/theme/ThemeProvider';
import { IInputProps } from './Input.types';

export const INPUT_HEIGHT = 56;

export const sharedInputStyles = (theme: Theme, isError?: boolean) => css`
  box-sizing: border-box;
  padding-left: ${theme.sizes.space.lg}px;
  border-radius: ${theme.sizes.radii.xl}px;
  border: ${theme.sizes.border.sm}px solid;

  font-size: ${theme.fontSizes.text.md}px;
  font-weight: ${theme.fontWeights.semiBold};
  line-height: ${theme.lineHeights.text.md}px;
  width: 100%;
  height: ${INPUT_HEIGHT}px;

  background: none;
  color: ${theme.colors.actions.inputs.primary};

  &:focus, &:hover {
    outline: none;
    border-color: ${theme.colors.actions.inputs.border}
  };

  &::placeholder {
    color: ${theme.colors.actions.inputs.placeholder};
    font-weight: ${theme.fontWeights.regular};
  };

  &:disabled {
    cursor: not-allowed;
    outline: none;
    color: ${theme.colors.actions.inputs.placeholder};
    border-color: ${theme.colors.actions.inputs.placeholder};
    background-color: ${theme.colors.actions.inputs.disabled};
    &:focus, &:hover {
     border-color: ${theme.colors.actions.inputs.placeholder};
    }
  };

  ${isError && {
    borderColor: theme.colors.semantic.error[50],
  }}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
`;

export const Input = styled.input<Pick<IInputProps, 'isError'>>`
  ${({ isError, theme }) => sharedInputStyles(theme, isError)};
`;
