import * as yup from 'yup';

import {
  cpSchema,
  emailSchema,
  phoneSchema,
  sirenSchema,
} from 'src/libs/yupHelpers';

const isEligibleRequiredSchema = yup.string().when('isEligible', {
  is: true,
  then: (schema) => schema.required(),
});

const isEligibleRequiredSchemaStatement = yup.string().when('isEligible', {
  is: true,
  then: (schema) => schema.oneOf(['checked']).required(),
});

export const shieldFormSchema = yup.object().shape({
  isEligible: yup.boolean().required(),

  siren: sirenSchema.required(),
  company: yup.string().required(),
  streetNumber: yup.string(),
  street: isEligibleRequiredSchema,
  postalCode: yup.string().when('isEligible', {
    is: true,
    then: () => cpSchema,
  }),
  city: isEligibleRequiredSchema,
  mail: yup.string().when('isEligible', {
    is: true,
    then: () => emailSchema,
  }),
  customerIds: yup.array().of(yup.string()).when('isEligible', {
    is: true,
    then: (schema) => schema.required(),
  }),
  phoneNumber: yup.string().when('isEligible', {
    is: true,
    then: () => phoneSchema,
  }),
  category: yup.number().nullable().when('isEligible', {
    is: true,
    then: (schema) => schema.required(),
  }),
  signatureDate: isEligibleRequiredSchema,
  signatureLocation: isEligibleRequiredSchema,
  name: isEligibleRequiredSchema,
  firstName: isEligibleRequiredSchema,
  signatoryDescription: isEligibleRequiredSchema,
  statement1: isEligibleRequiredSchemaStatement,
  statement2: isEligibleRequiredSchemaStatement,
  statement3: isEligibleRequiredSchemaStatement,

});
