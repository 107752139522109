import { useState } from 'react';

import { CenterContentLayout } from 'src/components/layouts/position/CenterContentLayout';

import { ITicketsPage, filterValues } from 'src/domains/ticket/TicketsTypes';
import commonText from 'src/texts/current//commonText.json';
import { ErrorTemplate } from 'src/components/errors/templates/ErrorTemplate';
import { PageObject, Pagination } from 'src/ui/pagination/Pagination';
import { TicketsListContainer } from './list/TicketsListContainer';
import * as Styled from './TicketsContent.styles';
import { TicketPageHeader } from './header/TicketPageHeader';
import { TicketDrawer } from './drawer/TicketDrawer';

const TicketsContent = ({
  isLoading,
  data,
  setPageSize,
  setCurrentPage,
  pageSize,
  filterStatus,
  setFilterStatus,
}: ITicketsPage) => {
  const [activeTicketId, setActiveTicketId] = useState<number | undefined>(undefined);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const toggleDrawer = () => setShowDrawer(!showDrawer);

  const onPageChange = ({ selected }: PageObject) => {
    setCurrentPage(selected + 1);
    setActiveTicketId(undefined);
  };

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1);
    setActiveTicketId(undefined);
  };

  const onChangeFilterStatus = (filter: filterValues[]) => {
    setFilterStatus(filter);
    setActiveTicketId(undefined);
  };

  if (data || isLoading) {
    return (
      <>
        <TicketDrawer
          ticketId={activeTicketId}
          show={showDrawer}
          onHide={toggleDrawer}
        />
        <TicketPageHeader
          pageSize={pageSize}
          filterStatus={filterStatus}
          handlePageSizeChange={onPageSizeChange}
          handleStatusFilterChange={onChangeFilterStatus as ((filter: string[]) => void)}
        />
        <Styled.Wrapper>
          <TicketsListContainer
            tickets={data?.tickets ?? []}
            currentTicketDisplayed={activeTicketId}
            onClickOnTicket={(ticketId: number) => {
              setActiveTicketId(ticketId);
              toggleDrawer();
            }}
            isLoading={isLoading}
          />
        </Styled.Wrapper>
        { data?.pagination
        && (
        <Styled.PaginationWrapper>
          <Pagination
            onPageChange={onPageChange}
            numberOfPages={Math.ceil(data.pagination.recordsCount / pageSize)}
            forcePage={data.pagination.currentPage - 1}
          />
        </Styled.PaginationWrapper>
        )}
      </>
    );
  }

  return (
    <CenterContentLayout>
      <ErrorTemplate
        title={commonText.errors.data}
        text=''
        imageType='error'
      />
    </CenterContentLayout>
  );
};

export default TicketsContent;
