export const lightBaseColors = {
  neutral: {
    100: '#0F1A1C',
    200: '#2A4B50',
    600: '#CDE1E4',
    700: '#DEEBED',
    800: '#EEF5F6',
    900: '#F8FBFB',
  },
  primary: {
    300: '#0A665C',
    500: '#084F47',
    600: '#053832',
  },
  secondary: {
    300: '#934801',
  },
  alternative: {
    1: '#99B4FF',
    2: '#FF6669',
    3: '#9BCFC9',
    4: '#2FB3C2',
  },
  black: {
    10: '#404040',
    15: '#808080',
    25: '#BFBFBF',
    50: '#D9D9D9',
    75: '#E6E6E6',
  },
  info: {
    50: '#1D57AF',
  },
  success: {
    50: '#2F6A2F',
  },
  warning: {
    50: '#934801',
  },
  error: {
    50: '#AD2000',
  },
} as const;

export const darkBaseColors = {
  neutral: {
    900: '#0F1A1C',
    800: '#132527',
    700: '#1D3538',
    600: '#1E4247',
    200: '#BCD7DC',
    100: '#F8FBFB',
  },
  primary: {
    600: '#12B5A3',
    500: '#17E8D1',
    300: '#74F1E3',
  },
  secondary: {
    300: '#FEC867',
  },
  alternative: {
    1: '#99B4FF',
    2: '#FF6669',
    3: '#9BCFC9',
    4: '#2FB3C2',
  },
  black: {
    10: '#E6E6E6',
    15: '#D9D9D9',
    25: '#BFBFBF',
    50: '#808080',
    75: '#404040',
  },
  info: {
    50: '#91B5ED',
  },
  success: {
    50: '#80C684',
  },
  warning: {
    50: '#FDB935',
  },
  error: {
    50: '#EBA594',
  },
} as const;
