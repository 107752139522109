import { postRequest } from 'src/libs/requests';

const URL = {
  METER_READING: '/meter-reading',
};

export const postMeterReading = async (
  indexes: Record<string, string>[],
  customerId: string,
  contractId: string,
  siteId: string,
) => {
  try {
    return await postRequest(URL.METER_READING, {
      indexes,
      customerId,
      contractId,
      siteId,
    });
  } catch (error) {
    throw error;
  }
};
