import { Theme } from 'src/theme/ThemeProvider';

const outlineLoaderColor = (theme: Theme) => ({
  primary: theme.colors.actions.buttons.primary,
  danger: theme.colors.actions.buttons.danger,
});

const loaderColor = (theme: Theme) => ({
  primary: theme.colors.text.reverse,
  danger: theme.colors.text.reverse,
});

export const setLoaderColor = (
  theme: Theme,
  variant: 'primary' | 'danger',
  outline?: boolean,
  disabled?: boolean,
) => {
  if (disabled) {
    return theme.colors.disable.text;
  }

  return outline ? outlineLoaderColor(theme)[variant] : loaderColor(theme)[variant];
};
