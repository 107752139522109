import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from 'src/router/RouteConstants';
import { useLoginRedirect } from 'src/hooks/useLoginRedirect';
import { ErrorCategories } from 'src/domains/error/ErrorConstants';
import { useLoginMutation } from 'src/domains/auth/AuthQueries';
import { formatCaughtError } from 'src/domains/error/ErrorHelpers';
import { AuthLoginForm } from './AuthLoginForm';

export function AuthLoginContentProvider(): React.ReactElement {
  const [notifContent, setNotifContent] = useState('');
  const navigate = useNavigate();

  const {
    isError,
    error,
    isPending,
    mutate,
  } = useLoginMutation();

  const onLogin = (email: string, password: string) => mutate({ email, password });
  const onCancel = () => navigate(routes.AUTH_RESET);

  // We need to handle this error as a success for the user, while waiting for the reset mail
  const changePassword = isError
    && (formatCaughtError(error).data.type === ErrorCategories.CHANGE_INITIAL_PASSWORD);

  useEffect(() => {
    if (changePassword) {
      navigate(routes.AUTH_CONFIRM);
      return;
    }
    if (isError) {
      setNotifContent('Vos identifiants sont incorrects! Veuillez réessayer.');
    }
  }, [error, isError]);

  useLoginRedirect();

  return (
    <AuthLoginForm
      error={isError ? formatCaughtError(error) : undefined}
      onLogin={onLogin}
      onCancel={onCancel}
      isLoading={isPending}
      notifType='warning'
      notifContent={notifContent}
    />
  );
}
