import { Paragraph } from './paragraph/Paragraph';
import { Span } from './span/Span';
import { Caption } from './caption/Caption';
import { Cta } from './cta/Cta';
import { Label } from './label/Label';
import { Link } from './link/Link';

export const Typo = {
  Span,
  Paragraph,
  Caption,
  Cta,
  Label,
  Link,
};
