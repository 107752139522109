import { CSSObject } from '@emotion/react';
import { fontSizes } from 'src/theme/fontSizes';
import { Theme } from 'src/theme/ThemeProvider';
import { INPUT_HEIGHT } from '../input/basic/Input.styles';

export const container = {
  width: '100%',
  height: `${INPUT_HEIGHT}px`,
  fontSize: `${fontSizes.text.md}px`,
};

interface IProps {
  highlight?: boolean;
  disabled?: boolean;
  menuIsOpen?: boolean;
  isSelected?: boolean;
  isFocused?: boolean;
  theme: Theme;
}
interface IBaseStyleOptions extends IProps {
  baseStyle: CSSObject;
}

export const renderControlStyles = ({ disabled, highlight, theme }: IProps) => ({
  borderRadius: theme.sizes.radii.xl,
  cursor: 'pointer',
  width: '100%',
  height: '100%',
  boxShadow: 'none',
  borderWidth: '2px',
  borderColor: (highlight || !disabled)
    ? theme.colors.actions.inputs.border
    : theme.colors.disable.text,
  '&:hover': {
    borderColor: theme.colors.actions.inputs.border,
  },
  background: disabled ? theme.colors.actions.inputs.disabled : 'transparent',
});

export const valueContainer = (theme: Theme) => ({
  padding: `0 0 0 ${theme.sizes.space.md}px`,
});

export const renderSingleValueStyles = ({ highlight, disabled, theme }: IProps) => ({
  ...((highlight && !disabled) && { color: theme.colors.actions.inputs.border }),
  fontWeight: theme.fontWeights.regular,
  textOverflow: 'ellipsis',
  color: theme.colors.actions.inputs.primary,
});

export const placeholder = (theme: Theme) => ({
  lineHeight: `${theme.lineHeights.text.md}px`,
  fontWeight: theme.fontWeights.regular,
  color: theme.colors.actions.inputs.placeholder,
});

export const indicatorSeparator = {
  display: 'none',
};

export const menuList = {
  padding: 0,
};

export const menuStyles = (theme: Theme) => ({
  borderRadius: `${theme.sizes.radii.sm}px`,
  lineHeight: `${theme.lineHeights.text.md}px`,
  boxShadow: 'none',
  padding: `${theme.sizes.space.sm}px`,
  backgroundColor: theme.colors.foreground[600],
  color: `${theme.colors.actions.inputs.primary}`,
});

const renderBackgroundColor = ({
  baseStyle,
  theme,
  isFocused,
  isSelected,
}: IBaseStyleOptions) => {
  if (isFocused) {
    return theme.colors.foreground[700];
  }
  if (isSelected) {
    return 'transparent';
  } else return baseStyle.backgroundColor;
};

export const renderOptionStyles = ({
  isFocused,
  isSelected,
  baseStyle,
  theme,
}: IBaseStyleOptions) => ({
  cursor: 'pointer',
  color: theme.colors.actions.inputs.primary,
  borderRadius: `${theme.sizes.radii.sm}px`,
  marginBottom: '2px',
  fontSize: `${fontSizes.text.sm}px`,
  padding: `${theme.sizes.space.sm}px`,

  '&:hover, &:active': {
    backgroundColor: theme.colors.foreground[700],
  },
  '&:last-of-type': {
    marginBottom: 0,
  },
  backgroundColor: `${renderBackgroundColor({
    baseStyle, theme, isFocused, isSelected,
  })}`,
});

export const dropdownIndicator = ({ theme, menuIsOpen, disabled }: IProps) => ({
  svg: {
    display: disabled ? 'none' : 'block',
    transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    height: theme.sizes.icons.md,
    width: theme.sizes.icons.md,
    color: theme.colors.actions.inputs.primary,
  },
  paddingRight: theme.sizes.space.lg,
  display: 'flex',
  alignItems: 'center',
});
