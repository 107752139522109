import styled from 'src/theme/ThemeProvider';
import { Alert } from '../../alert/Alert';

export const Container = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.sizes.space.lg}px;
  animation: moveOpen 1s;
  z-index: 999;
  left: 0;
  right: 0;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  
  @keyframes moveOpen {
    from {transform: translate(0, -100px);}
    to {transform: translate(0, 0);}
  }
`;

export const CustomAlert = styled(Alert)`
  display: inline-block;
`;
