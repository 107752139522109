import { useQuery } from '@tanstack/react-query';
import { getTicket, getTickets } from './TicketClient';
import { useGenerateQueryKey } from '../shared/useGenerateQueryKey';
import { ticketsQueriesEnum } from './TicketKeys';
import { ITicketsQuery } from './TicketsTypes';

export const useTicketsQuery = ({ page, size, filters }: ITicketsQuery) => useQuery({
  queryKey: useGenerateQueryKey(
    {
      path: ticketsQueriesEnum.GET_TICKETS,
      params: [page, size, filters.sort().join('-')],
    },
    false,
  ),
  queryFn: () => getTickets(page, size, filters),
  staleTime: Infinity,
});

export const useTicketDetailsQuery = (
  ticketId: number | undefined,
) => useQuery({
  queryKey: useGenerateQueryKey({
    path: ticketsQueriesEnum.GET_TICKET_DETAILS,
    params: [ticketId],
  }, false),
  enabled: !!ticketId,
  queryFn: () => (ticketId ? getTicket(ticketId) : undefined),
  select: (data) => {
    if (data) {
      // Keep this due to typo error in old ticket created tickets
      return data.description.replace('Coordonées', 'Coordonnées');
    } else {
      return undefined;
    }
  },
});
