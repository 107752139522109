import {
  IAddress,
  IBillConsolidationResponse,
  IBillConsolidations,
  IPointOfDeliveryResponse,
  IPointsOfDelivery,
} from 'src/domains/search/SearchTypes';
import searchText from 'src/texts/current/searchText.json';
import { replaceText } from 'src/libs/text.utils';
import { IInfoItemProps } from 'src/ui/infos/InfoItem.types';
import { formatEnergyType } from 'src/pages/perimeter/lib';
import { routes } from 'src/router/RouteConstants';
import {
  DataType,
  IParsedSearchResults,
  IReferences, ISearchResults,
  LevelTypes,
} from './SearchDrawer.types';

const { labels } = searchText.results;

// TODO: this util is a duplicate of a data parser util
// ask back to uniformize its payload and use with old parser formatContractAddresss
const formatStreetName = (data: IAddress) => {
  const { streetNumber, streetName } = data;
  // Formating like this allow us not checking validity of key to avoid null text
  const address = Object.values({ streetNumber, streetName }).join(' ');
  return address;
};

const formatMunicipalityAndZip = (data: IAddress) => {
  const zip = data?.zipCode ?? '';
  return `${data.municipality ?? ''} (${zip})`;
};

const formatAddressToItem = ({ address, level }: {
    address: IAddress; level: LevelTypes;
  }): IInfoItemProps => ({
  name: labels[level].address,
  value: formatStreetName(address),
  subtitle: formatMunicipalityAndZip(address),
});

const formatReferences = ({ dataConso, dataPod }: DataType): IReferences => {
  const data = dataPod || dataConso;
  return ({
    entity: data.childEntity.reference,
    billConsolidation: data.billConsolidation.reference,
    pointOfDelivery: dataPod?.number,
    energyType: dataPod && formatEnergyType(dataPod.energy),
  });
};

const parseOneBillConso = (data: IBillConsolidations): ISearchResults => ({
  name: data.billConsolidation.name,
  references: formatReferences({ dataConso: data }),
  data: [{
    name: replaceText(labels.billConsolidation.entity, { isFirstLetterCapital: true }),
    value: data.childEntity.name,
    subtitle: data.childEntity.reference,
  },
  formatAddressToItem({ address: data.address, level: 'billConsolidation' }),
  {
    name: labels.billConsolidation.contact,
    subtitle: data.email,
    value: (data?.firstName && data?.lastName) ? `${data.firstName} ${data.lastName}` : '',
  }],
});

export const parseBillConsolidationResponse = (
  data: IBillConsolidationResponse,
): IParsedSearchResults => {
  const result = data.billConsolidations.flatMap((item) => parseOneBillConso(item));
  return { pagination: data.pagination, itemList: result };
};

const parseOnePointOfDelivery = (data: IPointsOfDelivery): ISearchResults => {
  const { address } = data;
  return ({
    name: data.billingName,
    references: formatReferences({ dataPod: data }),
    data: [
      {
        value: data.childEntity.name,
        name: replaceText(labels.pointOfDelivery.entity, { isFirstLetterCapital: true }),
        subtitle: data.childEntity.reference,
      },
      {
        value: data.billConsolidation.name,
        name: replaceText(labels.pointOfDelivery.billConsolidation, { isFirstLetterCapital: true }),
        subtitle: data.billConsolidation.reference,
      },
      formatAddressToItem({ address, level: 'pointOfDelivery' }),
    ],
  });
};

export const parsePointsOfDeliveryResponse = (
  data: IPointOfDeliveryResponse,
): IParsedSearchResults => {
  const result = data.pointsOfDelivery.flatMap((item) => parseOnePointOfDelivery(item));
  return {
    pagination: data.pagination,
    itemList: result,
  };
};

export const computeRoute = ({ refs, level }: { refs: IReferences, level: LevelTypes}) => {
  const isSiteLevel = level === 'pointOfDelivery';
  const route = isSiteLevel ? routes.PERIMETER_SITE : routes.PERIMETER_SITES;
  return `${route}/${refs.entity}/${refs.billConsolidation}${isSiteLevel ? `/${refs.pointOfDelivery}/${refs.energyType}` : ''}`;
};
