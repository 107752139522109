import { Row } from 'src/ui/row/Row';

interface ICenterContentLayoutProps {
  children: React.ReactNode;
}

export const CenterContentLayout = ({ children }: ICenterContentLayoutProps) => (
  <Row
    alignItems='center'
    justifyContent='center'
    flex={1}
    height='inherit'
  >
    {children}
  </Row>
);
