import closePicto from '@caprikorn/dynamo-icons/closeMd.svg';
import { Title } from 'src/ui/typography/title/Title';
import { TextButton } from 'src/ui/button/textButton/TextButton';
import commonText from 'src/texts/current/commonText.json';

import styles from './DrawerContent.module.css';

export interface IDrawerContentProps {
  onHide(): void;
  title?: string;
  children: React.ReactNode;
  dataTestId?: string;
  className?: string;
}

export const DrawerContent = ({
  onHide,
  title,
  children,
  dataTestId = 'defaultId',
  className = '',
}: IDrawerContentProps) => (
  <div className={[className, styles.Content].join(' ')} data-testid={dataTestId}>
    <div className={styles.headerWrapper}>
      <Title tag='h2' titleStyle='h3'>{title}</Title>
      <TextButton
        rightIcon={closePicto}
        onClick={onHide}
        size='md'
        variant='primary'
        dataTestId={`Drawer-${dataTestId}-close`}
      >
        {commonText.buttons.close}
      </TextButton>
    </div>
    {children}
  </div>
);
