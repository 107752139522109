import { useTheme } from 'src/theme/ThemeProvider';
import { ReactSvgWrapper } from 'src/ui/pictos/wrappers/ReactSVGWrapper';

import * as Styled from './IconContainer.styles';
import { IIconContainer } from './IconContainer.types';

export const IconContainer = (({
  disabled,
  dataTestId,
  onClick,
  content,
  isPrefixed,
  onMouseLeave,
  onMouseDown,
  onMouseUp,
  pictoSize,
  cursor,
}: IIconContainer) => {
  const theme = useTheme();

  return (
    <Styled.Container
      isPrefixed={isPrefixed}
      onClick={onClick}
      data-testid={dataTestId}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      pictoSize={pictoSize}
      cursor={cursor}
    >
      <ReactSvgWrapper
        src={content}
        size={pictoSize}
        color={disabled
          ? theme.colors.actions.inputs.placeholder
          : theme.colors.actions.inputs.primary}
      />
    </Styled.Container>
  );
});
