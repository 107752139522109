import styled from 'src/theme/ThemeProvider';

export const IconArrowContainer = styled.div`
  box-sizing: border-box;
  padding: ${({ theme }) => theme.sizes.space.sm}px;
  height: 40px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ReactPaginateWrapper = styled.div`
   display: flex;
   justify-content: center;  
   flex: 1;

  .Container {
    --pageLinkWidth: 30px;
    padding-left: 0;
    list-style-type: none;
    font-size: ${({ theme }) => theme.fontSizes.text.md}px;
    font-weight: bold;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.text.secondary};
    display: flex;
    align-items: center;
    justify-content: center; 
  }

  .PageEllipsis {
    margin: 0  ${({ theme }) => theme.sizes.space.sm}px;
  }

  .PreviousLink, .NextLink {
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.colors.actions.buttons.primary};
    }
    
    &:focus {
      outline: none;
    }
  }

  .Page {
    cursor: pointer;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    &:hover {
      text-decoration: none;
    }
  }

  .ActivePage {
    background: ${({ theme }) => theme.colors.actions.buttons.primary};
    color: ${({ theme }) => theme.colors.text.reverse};
    border-radius: ${({ theme }) => theme.sizes.radii.lg}px;
    text-decoration: none;
  }

  .PageLink {
    min-width: var(--pageLinkWidth);
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      outline: none;
    }
  }
`;
