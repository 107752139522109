import React, { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';

import linkStyles from './Link.module.css';

export interface ILinkProps {
  children: string | React.ReactNode;
  href: string;
  type: 'paragraph-md' | 'paragraph-lg' | 'label';
  target?: '_self' | '_blank' | '_parent' | '_top';
  rel?: string;
  className?: string;
  isEmail?: boolean;
  dataTestId?: string;
  onClick?: (event?: MouseEvent<HTMLElement>) => void;
}

const elementSizes = {
  'paragraph-lg': 'lg',
  'paragraph-md': 'md',
  label: 'sm',
} as const;

export const Link: React.FC<ILinkProps> = ({
  children,
  href,
  type,
  className = '',
  isEmail,
  target,
  rel,
  dataTestId,
  onClick,
}: ILinkProps) => {
  const cx = [
    'TypoCommon',
    linkStyles.Link,
    'TypoCommon-primary',
    'TextsCommon-semi-bold',
    'TypoCommon-underline',
    `TextsCommon-${elementSizes[type]}`,
    className,
  ].join(' ');

  if (isEmail || target) {
    return (
      <a
        className={cx}
        href={isEmail ? `mailto:${href}` : href}
        target={target}
        rel={rel}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }

  return (
    <NavLink
      className={cx}
      to={href}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  );
};
