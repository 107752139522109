import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { fetchShieldCertificates, fetchShieldCustomerIds, postShieldForm } from './ShieldClient';
import { useGenerateQueryKey, useGenerateQueryKeyNotStrict } from '../shared/useGenerateQueryKey';
import { shieldQueriesEnum } from './ShieldKeys';

const SHIELD_QUERIES = {
  SHIELD_FORM: 'SHIELD_FORM_SUBMIT',
  SHIELD_CUSTOMER_IDS: 'SHIELD_CUSTOMER_IDS',
  SHIELD_CERTIFICATES: 'SHIELD_CERTIFICATES',
} as const;

export const useShieldCertificates = (customerId: string) => useQuery({
  queryKey: useGenerateQueryKey({
    path: shieldQueriesEnum.SHIELD_CERTIFICATES,
    params: [customerId],
  }),
  queryFn: () => fetchShieldCertificates(customerId),
  select: ({ data }) => ({
    certificate: data?.companyStatements[0]?.data,
    isShieldRejected: data?.companyStatements[0]?.customerRefusal,
  }),
});

export const useShieldFormMutation = () => {
  const queryClient = useQueryClient();
  const queryKey = useGenerateQueryKeyNotStrict([shieldQueriesEnum.SHIELD_CERTIFICATES]);
  return useMutation({
    mutationFn: postShieldForm,
    mutationKey: [SHIELD_QUERIES.SHIELD_FORM],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};

export const useShieldCustomerIds = (siren: string) => useQuery({
  queryKey: useGenerateQueryKey({
    path: shieldQueriesEnum.SHIELD_CUSTOMER_IDS,
    params: [siren],
  }),
  queryFn: () => fetchShieldCustomerIds(siren),
  select: ({ data }) => data?.customersBySiren,
});
