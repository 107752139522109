import { EnergyType } from 'src/domains/shared/DomainSharedTypes';
import { textParamValues } from '../texts/textParamValues';
import commonText from '../texts/current/commonText.json';

export const thousandsSeperator = (x: number) => {
  /** regexp explenation :
   * \B : assert position where it doesn't match given regexp (?=(\d{3})+(?!\d))
   * (\d{3})+ : match all group of exactly 3 digits. Repeat as much as possible
   * (?!\d) : DONT match all digit (force group of 3)
   *
   * do : match every "position" before each group of 3 digits in the regexp
   *      (except for first group)
   *
   * exemple : given '1245645' => '1 245 645'
   */
  const isSplit = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return isSplit;
};

export const phoneNumberSeparator = (num: string) => {
  if (num.length !== 10) return num;
  else {
    return num.replace(/\B(?=(\d{2})+(?!\d))/g, ' ');
  }
};

export const displayEnergyType = (type: EnergyType) => ((type === 'ELEC') ? "d'électricité" : 'de gaz');

export const capitalizeFirstLetter = (str: string) => (str.charAt(0).toUpperCase() + str.slice(1));

export const computeSupportEmail = (email?: string) => email
|| commonText.mails.supportEmail;

/**
 * @param text the text in which you want to replace one or more elements.
 * @param options.isFirstLetterCapital
 *        if you want the first letter of the given text to be uppercased.
 * @param options.isPlural
 *        only if it's a replacement using global keys, will use the plural option if one exist.
 * @param options.replaceValue
 *        if you want to replace with element not in global options. You need to give the
 *        dictionnary of key <=> value that will be used as reference for replacement.
 *        Will take priority over global value if a specific key is the same as a global key.
 * @returns the resulting text after performing replacements using both global and specific values.
 */
export const replaceText = (
  text: string,
  options?: {
    isFirstLetterCapital?: boolean;
    isPlural?: boolean;
    replaceValues?: Record<string, string | number>;
  },
) => {
  /**
   * it will match the shortest text until a '}' is reached.
   * @{.*?} match with '@{anyTextWithAnyCharacterExcept'}'}
   */
  const matchingRegexp = /@{.*?}/g;

  /**
   * subfunction given as replacer argument to String.replace()
   * will replace a key matched in given string with
   * corresponding text value in replaceValue upper param
   * or value in file TextParamValues if no replaceValue was given
   * @param match the matched substring
   * @returns the string in upper param replaceValue or in global text const
   *          with a key corresponding to @param match Or "undefined" if nothing matched
   */
  const replacer = (match: string): string => {
    const cleanedMatch = match.replace(/@{|}/g, '');
    // Search in priority in potential given specific values
    if (options?.replaceValues && options?.replaceValues[cleanedMatch]) {
      return options.replaceValues[cleanedMatch].toString();
    }
    // Search in global values if nothing matched previously
    const textKey = options?.isPlural ? textParamValues[`${cleanedMatch}_PLURAL`] : textParamValues[cleanedMatch];
    return options?.isFirstLetterCapital ? capitalizeFirstLetter(textKey) : textKey;
  };

  return text.replace(matchingRegexp, replacer);
};
