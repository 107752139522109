import { SemanticTypes } from 'src/domains/common/CommonTypes';

export type TextTypes = SemanticTypes | 'disabled' | 'primary' | 'secondary';

export interface ITextProps {
  children: string | React.ReactNode;
  type?: TextTypes;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  tag: 'p' | 'span';
  underline?: boolean;
  weight?: 'regular' | 'semi-bold' | 'bold';
  className?: string;
  dataTestId?: string;
  breakSpaces?: boolean;
}

export const Text: React.FC<ITextProps> = ({
  type = 'primary',
  children,
  size = 'md',
  tag = 'p',
  underline,
  weight = 'regular',
  className = '',
  breakSpaces = false,
  dataTestId,
}: ITextProps) => {
  const cx = [
    'TypoCommon',
    `TypoCommon-${type}`,
    `TextsCommon-${size}`,
    `TextsCommon-${weight}`,
    `${underline ? 'TypoCommon-underline' : ''}`,
    `${breakSpaces ? 'TypoCommon-break-spaces' : ''}`,
    className,
  ];

  const CustomTextTag = `${tag}` as keyof JSX.IntrinsicElements;

  return (
    <CustomTextTag className={cx.join(' ')} data-testid={dataTestId}>
      {children}
    </CustomTextTag>
  );
};
