import { format, parseISO } from 'date-fns';
import { SemanticTypes } from 'src/domains/common/CommonTypes';
import { DropdownOption } from 'src/ui/dropdown/Dropdown';
import { EnergiesWordingTypes } from 'src/domains/shared/DomainSharedTypes';
import commonText from '../texts/current/commonText.json';

/* SemanticEnum Should be import but we redefine it here,
   to prevent a cybress package compilation error.
   Since cypress is weird when interacting with typescript enum */
// import { SemanticEnum } from 'src/ui/infos/status/StatusItem.type';

enum SemanticEnum {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

const MINIMAL_PASSWORD_LENGTH = 8;

export const isInputsLengthValid = (inputs: Record<string, string>) => {
  const values = Object.values(inputs);
  return values.every((value) => value.length >= MINIMAL_PASSWORD_LENGTH);
};

export const isEqualValues = (values: Array<string | number>) => {
  const isEqual = values.every((value) => value !== undefined && value === values[0]);

  return isEqual;
};

export const dateConverter = (date: string) => format(parseISO(date), 'dd/MM/y');

export const dateConverterWithoutYear = (date: string) => format(parseISO(date), 'dd/MM');

export const dateComparator = (dateA: Date, dateB: Date) => dateA.getTime() - dateB.getTime();

export const isEqualLength = (values: Array<string>) => {
  const isEqual = values.every((value) => value !== undefined && value.length === values[0].length);
  return isEqual;
};

export const setType = (variation: number | null) => {
  if (variation === null) {
    return undefined;
  } else {
    if (variation > 20) {
      return SemanticEnum.error;
    }
    if (variation > 10) {
      return SemanticEnum.warning;
    }
    return SemanticEnum.success;
  }
};

export const getTypeFromEnergy = (type: EnergiesWordingTypes) => ((type === 'Électricité') ? 'ELEC' : 'GAS');

export const arrayIsNotEmpty = (value: Array<unknown>) => value.length > 0;

export const formatToDropdown = (dropDownnValues: string[]) => (
  dropDownnValues.reduce((acc: DropdownOption[], curr) => {
    acc.push({
      label: curr,
      value: curr,
    });
    return acc;
  }, []));

export const actionnableCode = [73, 72, 74, 75, 54, 56];
export const standByCode = [51, 70, 71, 52, 50, 80, 90];
export const processedCode = [55, 60];
export const errorCode = [53, 81, 99, 91, 92, 93];

export const setStatusType = (code: number): SemanticTypes | undefined => {
  if (processedCode.includes(code)) {
    return SemanticEnum.success;
  } else if (errorCode.includes(code)) {
    return SemanticEnum.error;
  } else if (actionnableCode.includes(code)) {
    return SemanticEnum.warning;
  } else if (standByCode.includes(code)) {
    return SemanticEnum.info;
  } else {
    return undefined;
  }
};

export const download = (filename: string, blob: Blob) => {
  const newBlob = new Blob([blob], { type: 'application/pdf' });

  const element = window.document.createElement('a');
  element.href = window.URL.createObjectURL(newBlob);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const splitArrayByChunck = <T>(
  arrayToSplit: T[],
  chunckSize: number,
): T[][] => {
  if (chunckSize < 1) {
    return [arrayToSplit];
  }

  const result = [];

  for (let i = 0; i < arrayToSplit.length; i += chunckSize) {
    result.push(arrayToSplit.slice(i, i + chunckSize));
  }

  return result;
};

/**
 * Remove empty values
 * max tested depth is 1
 *
 * @param obj Object to remove empty values
 * @returns Object without empty values
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeEmptyValues = <T, K extends keyof T, O extends Partial<Record<K, any>>>(
  obj: O,
): Partial<O> => Object.keys(obj)
    .filter((objectKey) => {
      const val = obj[objectKey as K];
      if (typeof val === 'string') return val?.trim() ?? true;
      return true;
    })
    .reduce((acc, objectKey) => ({ ...acc, [objectKey]: obj[objectKey as K] }), {});

const computeSizePerPageLabel = (value: string) => `${value} ${commonText.sizePerPage.label}`;

export const getOptionForSizePerPage = (
  value: string,
) => ({ value, label: computeSizePerPageLabel(value) });
