import { Button } from 'src/ui/button/basic/Button';
import { Row } from 'src/ui/row/Row';

import searchText from 'src/texts/current/searchText.json';

export const SearchDrawerFooter = () => (
  <Row padding={['sm']} justifyContent='flex-end'>
    <Button type='submit' variant='primary'>{searchText.button.search}</Button>
  </Row>
);
