import { EnergyType } from 'src/domains/shared/DomainSharedTypes';
import {
  dateConverterWithoutYear,
  dateConverter,
} from 'src/libs/miscellaneous';
import texts from 'src/texts/current/perimeterTableOverviewContentText.json';
import siteTexts from 'src/texts/current/wordingsSiteText.json';
import { replaceText, thousandsSeperator } from 'src/libs/text.utils';
import {
  IContractDataMinimal,
  ICustomerData,
  IDataStateFormatted,
  ISiteData,
} from '../data/DataTypes';
import {
  IOneMaxPower,
  IMaximalPowerAction,
  IReading,
} from '../site/site.types';

const tableText = texts.table;
const pmaxText = siteTexts.pmax;

export const heads = {
  clients: [
    tableText.client.name,
    replaceText(tableText.client.ref),
    tableText.client.siretNumber,
  ],
  regroupements: [
    replaceText(tableText.contract.name),
    replaceText(tableText.contract.ref),
    tableText.contract.contact,
    tableText.contract.address,
    tableText.contract.lastBill,
  ],
  sites: [
    tableText.sites.name,
    tableText.sites.type,
    replaceText(tableText.sites.ref),
    tableText.sites.status,
    tableText.sites.address,
  ],
};

type AddressType = {
  additionalAddress?: string;
  city: string;
  street: string;
  streetNumber: string;
  zipCode: string;
};

export const formatContractAddress = (data: AddressType) => {
  const {
    streetNumber, zipCode, street, city,
  } = data;

  // Formating like this allow us not checking validity of key to avoid null text
  const address = Object.values({
    streetNumber,
    street,
    zipCode,
    city,
  }).join(' ');

  return address;
};

export const parseCustomersData = (
  customers: ICustomerData[],
): IDataStateFormatted => {
  const customersData = customers.reduce((acc: Array<string[]>, curr) => {
    // The order in the array below is important
    const data = [
      curr.companyName,
      curr.customerId, // Serves as react key prop in Table
      curr.siren,
    ];
    return [...acc, data];
  }, []);

  return {
    head: heads.clients,
    rows: customersData,
  };
};

export const parseContractsData = (
  contracts: IContractDataMinimal[],
): IDataStateFormatted => {
  const contractsData = contracts.reduce((acc: Array<string[]>, curr) => {
    const address = formatContractAddress(curr.billingAddress);

    const { contractId, contractName } = curr.contractInformation;
    const { firstName, lastName } = curr.billingContact;
    const contact = firstName && lastName ? `${firstName} ${lastName}` : '--';

    // The order in the array below is important
    const data = [
      contractName,
      contractId, // Serves as react key prop in Table
      contact,
      address,
    ];
    return [...acc, data];
  }, []);

  return {
    head: heads.regroupements,
    rows: contractsData,
  };
};

export const parseSitesData = (sites: ISiteData[]): IDataStateFormatted => {
  const sitesData = sites.reduce((acc: string[][], curr) => {
    const address = formatContractAddress(curr.consumptionAddress);

    const {
      siteId, podName, status, energy,
    } = curr.siteInformation;

    // The order in the array below is important
    const data = [
      podName,
      energy,
      siteId, // Serves as react key prop in Table
      status ? `${status.code} - ${status.name}` : '',
      address,
    ];
    return [...acc, data];
  }, []);

  return {
    head: heads.sites,
    rows: sitesData,
  };
};

const formatMaxPower = (maxPower?: IOneMaxPower | null) => {
  if (maxPower) {
    const validDate = Number.isInteger(new Date(maxPower.date).getTime());
    return `${thousandsSeperator(maxPower.maximalPower)} ${pmaxText.kVA} ${
      validDate
        ? replaceText(pmaxText.reached, {
          replaceValues: { date: dateConverterWithoutYear(maxPower.date) },
        })
        : ''
    }
  `;
  }
  return pmaxText.noMaxPower;
};

export type parsedSIteDetailsMawPowerDataType = {
  rowId: string;
  rowItems: string[];
}[];

export const parseSiteDetailsMaxPowerData = (
  data: IMaximalPowerAction,
): parsedSIteDetailsMawPowerDataType => {
  const weekMaxPow = formatMaxPower(data.weekMaximalPower);
  const montMaxPow = formatMaxPower(data.monthMaximalPower);
  const yearMawPow = formatMaxPower(data.yearMaximalPower);

  return [
    {
      rowId: `${weekMaxPow}+${montMaxPow}+${yearMawPow}`,
      rowItems: [weekMaxPow, montMaxPow, yearMawPow],
    },
  ];
};

export const parseSiteDetailsReadingsTableFromData = (
  readings: IReading[],
  energyType: EnergyType,
) => {
  const isGas = energyType === 'GAS';
  const unitValues = isGas ? 'm3' : 'kWh';
  const head = [
    tableText.site.date,
    ...readings[0].measures.map((item) => item.category),
    tableText.site.consumption,
  ];
  if (isGas) {
    head.push(tableText.site.higherHeatingValue);
  }
  const rows = readings.reduce((acc: string[][], curr: IReading) => {
    const validDate = Number.isInteger(new Date(curr.date).getTime());

    const row = [
      validDate ? dateConverter(curr.date) : siteTexts.readings.invalidDate,
      ...curr.measures.map(
        (item) => `${thousandsSeperator(item.index)} ${unitValues}`,
      ),
      `${thousandsSeperator(curr.consumption)} kWh`,
    ];

    if (isGas) {
      row.push(curr?.higherHeatingValue ?? siteTexts.readings.invalidDate);
    }
    return [...acc, row];
  }, []);
  return {
    head,
    rows,
  };
};

export const indexesARCParser = (indexes: Record<string, string>) => Object.keys(indexes)
  .reduce((acc: Array<Record<string, string>>, curr) => {
    const item = { temporalCategory: curr, value: indexes[curr] };
    acc.push(item);
    return acc;
  }, []);
