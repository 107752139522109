import { useGroupDetailsQuery } from 'src/domains/common/CommonQueries';
import { useDocumentsQuery } from 'src/domains/documents/DocumentsQueries';
import { usePerimeterExtractStatusQuery } from 'src/domains/perimeter/PerimeterQueries';

export const useDocumentPage = () => {
  const { data, isFetching: isGroupLoading, isError: isGroupDetailError } = useGroupDetailsQuery();

  const {
    isError: isGetDocumentsError,
    isLoading: isDocumentsLoading,
    refetch: documentRefetch,
  } = useDocumentsQuery();

  const {
    isError: isExtractError,
    isLoading: isExtractLoading,
    refetch: extractPerimRefetch,
  } = usePerimeterExtractStatusQuery({ isAdmin: !!data?.isAdmin });

  return {
    isError: (isGetDocumentsError || isExtractError || isGroupDetailError),
    isLoading: (isExtractLoading || isDocumentsLoading || isGroupLoading),
    refetch: () => {
      documentRefetch();
      extractPerimRefetch();
    },
  };
};
