import {
  IGroups,
  ITechRequestDataDico,
  ITechRequestDescriptionField,
} from 'src/domains/common/CommonTypes';
import { DropdownOption } from 'src/ui/dropdown/Dropdown';
import commonText from 'src/texts/current/commonText.json';

const valueDico: ITechRequestDataDico = {
  group: 'Groupe',
  customer: 'Client',
  contract: 'Regroupement de factures',
  site: 'Site',
  civility: 'Civilité',
  lastName: 'Nom de famille',
  firstName: 'Prénom',
  phone: 'Numéro de téléphone',
  email: 'Email',
  potency: 'Puissance demandée',
  options: 'Option demandée',
  date: 'Date',
  onSiteContact: 'Une autre personne que le demandeur sera sur site ',
  perimeter: 'Périmètre',
  contact: 'Coordonnées du demandeur',
  potencyForm: 'Formulaire',
  comment: 'Commentaire',
  cancellationForm: 'Résiliation',
};

const descriptionOrder: { [key: string]: number } = {
  perimeter: 1,
  contact: 2,
  potencyForm: 3,
  cancellationForm: 3,
  onSiteContact: 4,
  comment: 5,
};

// Return the translated value if it exists otherwhise return the key
export const translateKey = (key: string) => (valueDico[key] !== undefined ? valueDico[key] : key);

export const convertToHtmlString = (object: Record<string, string>) => {
  const stringHtml = Object.keys(object).reduce((acc, currKey) => {
    const value = `<li><strong>${translateKey(currKey)} :</strong> ${object[currKey]}</li>`;
    return acc + value;
  }, '');
  return `<ul>${stringHtml}</ul>`;
};

const convertToHtmlStringWithTitle = (value: [string, Record<string, string> ]) => {
  const htmlTitle = `<h1>${translateKey(value[0])}</h1>`;
  const contentHtml = convertToHtmlString(value[1]);
  return htmlTitle + contentHtml;
};

export const convertDescriptionToHtmlString = (description: ITechRequestDescriptionField) => {
  const array = Object.entries(description);

  const stringHtml: string = array
    .map((value) => ({
      // this ternary allows us to give an order to a value not found in descriptionOrder
      // This is in case we create a new form and forgot to update the descriptionOrder
      order: descriptionOrder[value[0]]
        ? descriptionOrder[value[0]]
        : (Object.keys(descriptionOrder).length + 1),
      htmlValue: convertToHtmlStringWithTitle(value),
    }))
    .sort((a, b) => a.order - b.order)
    .reduce((acc, currKey) => acc + currKey.htmlValue, '');

  return `<div>${stringHtml}</div>`;
};

// TODO: test this function
export const computeGroupOptions = (
  array?: IGroups[],
) => array?.map(({ name, reference }) => <DropdownOption>({ label: name, value: reference })) ?? [];

export const labelForGroupDropdown = (
  formattedGroups: DropdownOption[],
  currGroupRef: string,
) => formattedGroups.find(
  (opt) => opt.value === currGroupRef,
)?.label ?? commonText.groups.dropdownNoMatch;

export const dateIsPast = (checkedDate: Date) => new Date() > checkedDate;
