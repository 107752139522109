import React, { CSSProperties } from 'react';
import styled from 'src/theme/ThemeProvider';
import { sizes } from 'src/theme/sizes';
import { getSpaceSize } from 'src/theme/theme.utils';

export interface IColumnProps {
  children: React.ReactNode;
  alignItems?: CSSProperties['alignItems'];
  alignContent?: CSSProperties['alignContent'];
  justifyContent?: CSSProperties['justifyContent'];
  flex?: number;
  flexWrap?: CSSProperties['flexWrap'];
  height?: string;
  width?: string;
  gap?: keyof typeof sizes.space;
  reverse?: boolean;
  padding?: (keyof typeof sizes.space | 0 | 'auto')[];
  margin?: (keyof typeof sizes.space | 0 | 'auto')[];
  overflow?: CSSProperties['overflow'];
  dataTestId?: string;
  textAlign?: CSSProperties['textAlign'];
}

const ColumnContainer = styled.div<IColumnProps>(({
  alignItems,
  alignContent,
  justifyContent,
  flex,
  flexWrap,
  height,
  width,
  gap,
  reverse,
  padding,
  margin,
  overflow,
  textAlign,
}) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: reverse ? 'column-reverse' : 'column',
  ...(flex && { flex }),
  ...(flexWrap && { flexWrap }),
  ...(width && { width }),
  height: height ?? '100%',
  ...(alignItems && { alignItems }),
  ...(alignContent && { alignContent }),
  ...(justifyContent && { justifyContent }),
  ...(gap && { gap: sizes.space[gap] }),
  ...(padding && { padding: padding.map((pad) => (getSpaceSize(pad))).join(' ') }),
  ...(margin && { margin: margin.map((mar) => (getSpaceSize(mar))).join(' ') }),
  ...(overflow && { overflow }),
  ...(textAlign && { textAlign }),
}));

export const Column = ({
  children,
  dataTestId = 'column',
  ...props
}: IColumnProps) => (
  <ColumnContainer data-testid={dataTestId} {...props}>
    {children}
  </ColumnContainer>
);
