/* eslint-disable react/jsx-no-useless-fragment */

import { Skeleton, ISkeletonProps } from 'src/ui/skeleton/Skeleton';

export interface IWithSkeletonProps extends ISkeletonProps {
  show: boolean;
  children: React.ReactNode;
}

export const WithSkeleton = ({
  show,
  children,
  numberOfLines,
  className,
}: IWithSkeletonProps) => {
  if (show) {
    return <>{children}</>;
  }

  return <Skeleton numberOfLines={numberOfLines} className={className} />;
};
