export const routes = {
  DASHBOARD: '/',
  SETTINGS: '/settings',

  AUTH_LOGIN: '/login',
  AUTH_RESET: '/login/reset',
  AUTH_RESET_CONFIRM_NEW_PASSWORD: '/login/reset/:email/:confirm',
  AUTH_CONFIRM: '/login/confirm',

  PERIMETER: '/search',

  PERIMETER_CONTRACTS: '/search/regroupement',
  PERIMETER_CONTRACTS_PARAMS: '/search/regroupement/:customerId',
  SHIELD: '/search/regroupement/:customerId/shield',

  PERIMETER_SITES: '/search/sites',
  PERIMETER_SITES_PARAMS: '/search/sites/:customerId/:contractId',

  PERIMETER_SITE: '/search/site',
  PERIMETER_SITE_DETAIL: '/search/site/:customerId/:contractId/:siteId/:energyType',

  DOCUMENTS: '/documents',

  FRESHDESK_TICKETS: '/tickets',

  ACCESS_MANAGER: '/access-manager',
  ACCESS_MANAGER_CREATE: '/create',
  ACCESS_MANAGER_USER: '/:userId',
} as const;

export const loginRoutes = {
  AUTH_LOGIN_ROUTER: '/login/*',
  AUTH_LOGIN: '/',
  AUTH_CONFIRM: '/confirm',

  AUTH_RESET_ROUTER: '/reset/*',
  AUTH_RESET: '/',
  AUTH_RESET_CONFIRM_NEW_PASSWORD: '/:email/:confirm',
} as const;
