import React, { CSSProperties } from 'react';
import styled from 'src/theme/ThemeProvider';
import { sizes } from 'src/theme/sizes';
import { getSpaceSize } from 'src/theme/theme.utils';

export interface IRowProps {
  children: React.ReactNode;
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  flex?: number;
  flexWrap?: CSSProperties['flexWrap'];
  height?: string;
  width?: string;
  gap?: keyof typeof sizes.space;
  reverse?: boolean;
  padding?: (keyof typeof sizes.space | 0 | 'auto')[];
  margin?: (keyof typeof sizes.space | 0 | 'auto')[];
  dataTestId?: string;
  textAlign?: CSSProperties['textAlign'];
}

const RowContainer = styled.div<IRowProps>(({
  alignItems,
  justifyContent,
  flex,
  flexWrap,
  height,
  width,
  gap,
  reverse,
  padding,
  margin,
  textAlign,
}) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: reverse ? 'row-reverse' : 'row',
  ...(flex && { flex }),
  ...(flexWrap && { flexWrap }),
  ...(height && { height }),
  width: width ?? '100%',
  ...(alignItems && { alignItems }),
  ...(justifyContent && { justifyContent }),
  ...(gap && { gap: sizes.space[gap] }),
  ...(padding && { padding: padding.map((pad) => (getSpaceSize(pad))).join(' ') }),
  ...(margin && { margin: margin.map((mar) => (getSpaceSize(mar))).join(' ') }),
  ...(textAlign && { textAlign }),
}));

export const Row = ({
  children,
  dataTestId = 'row',
  ...props
}: IRowProps) => (
  <RowContainer data-testid={dataTestId} {...props}>
    {children}
  </RowContainer>
);
