import React, { HTMLAttributes } from 'react';
import currTheme from 'src/theme';

import styled, { Theme } from 'src/theme/ThemeProvider';

const boxColors = ({ colors }: Theme) => ({
  background: colors.background.elevated,
  foreground1: colors.foreground[800],
  foreground2: colors.foreground[700],
  foreground3: colors.foreground[600],
  error: colors.semantic.error[50],
  success: colors.semantic.success[50],
  warning: colors.semantic.warning[50],
  info: colors.semantic.info[50],
  transparent: 'transparent',
});

type boxColorsKeys = keyof ReturnType<typeof boxColors>;

type radiusKeys = keyof typeof currTheme.sizes.radii;

type boxSpacingKey = keyof typeof currTheme.sizes.space;

type boxShadowKey = keyof typeof currTheme.shadows;

export interface IBoxProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
  backgroundColor?: boxColorsKeys;
  borderColor?: boxColorsKeys;
  radius?: radiusKeys;
  shadow?: boxShadowKey;
  spacing?: boxSpacingKey;
  dataTestId?: string;
  onClick?: () => void;
}

type BoxRef = HTMLDivElement | null;

const StyledBox = styled.div<IBoxProps>`
  position: relative;
  border-radius: ${({ theme, radius = 'md' }) => `${theme.sizes.radii[radius]}px`};
  box-sizing: border-box;

  background: ${({ backgroundColor, theme }) => boxColors(theme)[backgroundColor || 'background']};
  padding: ${({ spacing, theme }) => (spacing ? `${theme.sizes.space[spacing]}px` : '')};
  box-shadow: ${({ theme, shadow }) => (shadow ? theme.shadows[shadow] : '')};

  border: 1px solid;
  border-color: ${({ borderColor, backgroundColor = 'background', theme }) => boxColors(theme)[borderColor || backgroundColor]};
`;

export const Box = React.forwardRef<BoxRef, IBoxProps>(({
  children,
  backgroundColor = 'background',
  className,
  borderColor,
  shadow,
  spacing = 'md',
  dataTestId,
  radius = 'md',
  onClick,
  ...props
}: IBoxProps, ref) => (
  <StyledBox
    {...props}
    className={className}
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    shadow={shadow}
    spacing={spacing}
    ref={ref}
    data-testid={dataTestId}
    radius={radius}
    onClick={onClick}
  >
    {children}
  </StyledBox>
));

// Prevent eslint error: Component definition is missing display name  react/display-name
Box.displayName = 'Box';
