import styled from 'src/theme/ThemeProvider';
import { Spacer } from '../spacer/Spacer';
import { IInfosContainerProps, IInfoItemProps } from './InfoItem.types';
import { Typo } from '../typography/Typo';

const Container = styled.li<IInfosContainerProps>`
  display:  ${({ display }) => (display || 'flex')};
  flex-direction: ${({ direction }) => (direction || 'row')};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  width: 100%;
  white-space: nowrap;
`;

const CustomLink = styled(Typo.Link)`
  overflow: hidden; 
  text-overflow: ellipsis;
`;

const CustomText = styled(Typo.Span)`
  overflow: hidden; 
  text-overflow: ellipsis;
`;

export const InfoItem = ({
  name,
  value,
  direction,
  justifyContent,
  alignItems,
  className,
  link,
  display,
  isEmail,
  subtitle,
}: IInfoItemProps) => (
  <Container
    direction={direction}
    justifyContent={justifyContent}
    className={className}
    display={display}
    alignItems={alignItems}
  >
    <Typo.Span>{name}</Typo.Span>
    <Spacer width='xxs' />
    {link && value
      ? (
        <CustomLink
          type='paragraph-md'
          href={value}
          isEmail={isEmail}
        >
          {value}
        </CustomLink>
      )
      : (
        <CustomText weight='semi-bold'>
          {value}
        </CustomText>
      )}
    {subtitle
      && (
        <>
          <Spacer width='xxs' />
          <CustomText>
            {subtitle}
          </CustomText>
        </>
      )}
  </Container>
);
