import { TabSwitcher } from 'src/ui/menu/section/switcher/TabSwitcher';
import { LevelTypes } from '../SearchDrawer.types';
import { menuItems } from '../SearchDrawer.constants';

import * as Styled from '../SearchDrawer.styles';

interface ISearchDrawerProps {
  level: LevelTypes;
  setLevel(level: LevelTypes): void;
}

export const SearchDrawerHeader = ({ level, setLevel }: ISearchDrawerProps) => (
  <Styled.CustomList>
    {menuItems.map((item) => (
      <TabSwitcher
        content={item.content}
        isActive={level === item.level}
        onClick={() => setLevel(item.level)}
        key={item.content}
      />
    ))}
  </Styled.CustomList>
);
