import commonText from './current/commonText.json';
import perimText from './current/wordingsPerimeterText.json';

export const textParamValues: {[keyName: string]: string} = {
  BRAND_NAME: commonText.brandName,

  PERIM_CLIENT: perimText.paramsKey.singular.client,
  PERIM_CLIENT_PLURAL: perimText.paramsKey.plural.client,

  PERIM_ENTITY: perimText.paramsKey.singular.entity,
  PERIM_ENTITY_PLURAL: perimText.paramsKey.plural.entity,

  PERIM_REGROUPEMENT: perimText.paramsKey.singular.regroupement,
  PERIM_REGROUPEMENT_PLURAL: perimText.paramsKey.plural.regroupement,

  PERIM_SITE: perimText.paramsKey.singular.site,
  PERIM_SITE_PLURAL: perimText.paramsKey.plural.site,

  TEST_KEY_1: 'pouet pouet',
  TEST_KEY_2: 'not funny',
  TEST_KEY_1_PLURAL: 'pouets pouets',
  TEST_KEY_BOTH_MATCH: 'text des valeurs globales',
};
