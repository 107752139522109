import styled from 'src/theme/ThemeProvider';
import { Card } from 'src/ui/card/Card';

export const CustomCard = styled(Card)`
  width: 500px;
  min-height: 486px;
  display: flex;
  // allow notification to came from the top of the page
  position: inherit;
`;
