import { AxiosResponse } from 'axios';

import { getRequest, postRequest } from 'src/libs/requests';
import { SEARCH_OVERVIEW_BASE_URL } from './PerimeterConstants';

import { EnergyType } from '../shared/DomainSharedTypes';
import { IConsumptionData, IContractDataDetailed, ICustomerOverview } from './overview/OverviewTypes';
import { ICustomersByGroup, IExportPerimeterExtractStatus } from './PerimeterTypes';

const URL = {
  PERIMETER_ENERGY_CONSUMPTION: '/consumption',
  CUSTOMERS_BY_GROUP: '/customers/all-by-group',
  CONTRACTS_BY_CUSTOMER: '/contracts/all-by-customer?',
  EXTRACT_PERIMETER: '/perimeter-export',
};

export const fetchEnergyConsumption = async (
  customerId?: string,
  contractId?: string,
  siteId?: string,
  energy?: EnergyType,
  signal?: AbortSignal,
): Promise<AxiosResponse<IConsumptionData>> => {
  const customer = customerId ? `/${customerId}` : '';
  const contract = contractId ? `/${contractId}` : '';
  const site = siteId ? `/${siteId}` : '';

  const url = `${URL.PERIMETER_ENERGY_CONSUMPTION + customer + contract + site}?energy=${energy}`;

  try {
    return await getRequest(url, { signal });
  } catch (error) {
    throw error;
  }
};

export const fetchCustomerOverview = async (customerId?: string):
  Promise<AxiosResponse<ICustomerOverview>> => await getRequest(`${SEARCH_OVERVIEW_BASE_URL}/${customerId}`);

export const fetchContractOverview = async (
  customerId?: string,
  contractId?: string,
): Promise<AxiosResponse<IContractDataDetailed>> => {
  const url = `${SEARCH_OVERVIEW_BASE_URL}/${customerId}/contracts/${contractId}`;

  try {
    return await getRequest(url);
  } catch (error) {
    throw error;
  }
};

export const fetchCustomersByGroup = async ():
  Promise<AxiosResponse<{customers: ICustomersByGroup[]}>> => {
  try {
    return await getRequest(URL.CUSTOMERS_BY_GROUP);
  } catch (error) {
    throw error;
  }
};

export const postExtractPerimeterRequest = async () => {
  try {
    return await postRequest(URL.EXTRACT_PERIMETER, null);
  } catch (error) {
    throw error;
  }
};

export const getExtractPerimeterStatus = async () => {
  try {
    const { data }: {
       data: IExportPerimeterExtractStatus;
      } = await getRequest(URL.EXTRACT_PERIMETER);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getExtractPerimeterDownload = async () => {
  try {
    return await getRequest<Blob>(`${URL.EXTRACT_PERIMETER}/download`);
  } catch (error) {
    throw error;
  }
};
