import { ISiteUrlParamTypes } from 'src/router/RouteTypes';

export interface ICustomersByGroup {
  customerId: string;
  customerName: string;
}

export interface IDetailSiteLastStatementsQuery extends ISiteUrlParamTypes {
  readingPage?: number;
}

export enum perimeterExtractStatus {
  DONE = 'DONE',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  EMPTY = 'EMPTY',
}

type PerimeterExtractStatusType = keyof typeof perimeterExtractStatus;

export interface IExportPerimeterExtractStatus {
  status: PerimeterExtractStatusType;
  canRegenerate: boolean;
}

export type DetailSitePerimeterType = Omit<ISiteUrlParamTypes, 'energyType'>;
