import { css } from '@emotion/react';

import styled, { Theme } from 'src/theme/ThemeProvider';
import { Button } from 'src/ui/button/basic/Button';
import { ReactSvgWrapper } from 'src/ui/pictos/wrappers/ReactSVGWrapper';
import { ISharedButtonProps, TButtonSize } from '../basic/Button.types';

export interface IconButtonProps extends ISharedButtonProps {
  isLoading?: boolean;
  leftIcon?: string;
  rightIcon?: string;
  width?: string;
}

// TODO check hover style on outline, primary and not disabled
export const iconButtonVariants = {
  outline: {
    primary: ({ colors }: Theme) => css`
      & > div > div > svg > path {
        fill: ${colors.actions.buttons.primary};
      }
      &:hover > div > div > svg > path  {
        fill: ${colors.actions.buttons.primaryHover};
       }
    `,
    danger: ({ colors }: Theme) => css`
      & > div > div > svg > path {
        fill: ${colors.actions.buttons.dangerHover};
      }
      &:hover > div > div > svg > path  {
        fill: ${colors.actions.buttons.dangerHover};
       }
    `,
  },
};

export const CustomButton = styled(Button)<Omit<IconButtonProps, 'rightIcon'>>`
  width: ${({ width }) => (width ? `${width}px` : '')};
  cursor: ${({ isLoading }) => isLoading && 'not-allowed'};
  display: flex; 
  align-items: center;
  justify-content: center;
  transition: fill .2s;

  & > div > div > svg > path {
    fill: ${({ theme, disabled }) => (disabled ? theme.colors.disable.text : theme.colors.text.reverse)};
  }

  ${({
    theme,
    variant,
    disabled,
    outline,
  }) => (!disabled && outline) && iconButtonVariants.outline[variant](theme)}  
`;

const iconSpacing = {
  sm: ({ sizes }: Theme, iconLeft?: boolean) => css`
      margin-left: ${(!iconLeft && `${sizes.space.xxs}px`)};
      margin-right: ${(iconLeft && `${sizes.space.xxs}px`)};
    `,
  md: ({ sizes }: Theme, iconLeft?: boolean) => css`
      margin-left: ${(!iconLeft && `${sizes.space.xs}px`)};
      margin-right: ${(iconLeft && `${sizes.space.xs}px`)};
    `,
  lg: ({ sizes }: Theme, iconLeft?: boolean) => css`
      margin-left: ${(!iconLeft && `${sizes.space.xs}px`)};
      margin-right: ${(iconLeft && `${sizes.space.xs}px`)};
    `,
};

export const CustomSvgWrapper = styled(ReactSvgWrapper)<{ iconLeft?: boolean, buttonSize: TButtonSize }>`
  display: flex;
  align-items: center;
  ${({ theme, buttonSize, iconLeft }) => iconSpacing[buttonSize](theme, iconLeft)};
`;
