import { ApiErrorType, IFormattedError } from 'src/domains/error/ErrorTypes';
import { CustomErrors } from 'src/domains/error/ErrorConstants';

export const formatCaughtError = (error: unknown): IFormattedError => {
  if ((error as ApiErrorType).response) {
    return ({
      data: {
        type: (error as ApiErrorType).response?.data?.type ?? '',
        message: (error as ApiErrorType).response?.data?.message ?? '',
        details: (error as ApiErrorType).response?.data?.details ?? '',
      },
      status: (error as ApiErrorType).response?.status || CustomErrors.UNSPECIFIED_ERROR.status,
    });
  }

  if (error instanceof Error || ((error as Error).name && (error as Error).message)) {
    return ({
      data: {
        type: (error as Error).name,
        message: (error as Error).message,
        details: (error as Error).stack ?? '',
      },
      status: CustomErrors.UNSPECIFIED_ERROR.status,
    });
  }

  return ({
    data: {
      type: CustomErrors.UNSPECIFIED_ERROR.type,
      message: CustomErrors.UNSPECIFIED_ERROR.message,
      details: '',
    },
    status: CustomErrors.UNSPECIFIED_ERROR.status,
  });
};

/* Custom Front Errors
  Throw Custom Errors from front with the same return value than api error handling:
  Use like this => throw createCustomError(CustomErrorTypes.UNSPECIFIED_ERROR);
*/

// const createCustomError = (customErrorKey: keyof typeof CustomErrors): IFormattedError => {
//   const formattedError = {
//     data: {
//       ...CustomErrors[customErrorKey],
//     },
//     status: CustomErrors[customErrorKey].status,
//   };

//   return formattedError;
// };
