import { CenterContentLayout } from 'src/components/layouts/position/CenterContentLayout';
import { useTicketDetailsQuery } from 'src/domains/ticket/TicketQueries';
import commonText from 'src/texts/current/commonText.json';
import ticketPageText from 'src/texts/current/ticketPageText.json';

import { ErrorTemplate } from 'src/components/errors/templates/ErrorTemplate';
import { Loader } from 'src/ui/loader/Loader';
import { DrawerContent } from 'src/ui/overlays/drawer/content/DrawerContent';
import { DrawerWrapper } from 'src/ui/overlays/drawer/wrapper/DrawerWrapper';
import { DrawerContainer } from 'src/ui/overlays/drawer/container/DrawerContainer';

import * as Styled from './TicketDrawer.styles';

interface ITicketPageDrawerProps {
  ticketId?: number;
  show: boolean;
  onHide(): void;
}

export const TicketDrawer = ({ ticketId, show, onHide }: ITicketPageDrawerProps) => {
  const {
    data,
    isError,
    isLoading,
  } = useTicketDetailsQuery(ticketId);

  return (
    <DrawerWrapper show={show}>
      <DrawerContent onHide={onHide} title={ticketPageText.drawerTitle}>
        <DrawerContainer backgroundColor='foreground1' data-testId='DrawerContainer-TicketDetail-Drawer'>
          {data && (
            <Styled.TicketDrawerWrapper
              data-testId='TicketDrawerWrapper' dangerouslySetInnerHTML={{ __html: data }}
            />
          )}
          {(isLoading || isError) && (
          <CenterContentLayout>
            {isLoading
              ? <Loader />
              : (
                <ErrorTemplate
                  title={commonText.errors.data}
                  text=''
                  imageType='error'
                />
              )}
          </CenterContentLayout>
          )}
        </DrawerContainer>
      </DrawerContent>
    </DrawerWrapper>
  );
};
