import { useFormContext } from 'react-hook-form';

import { Checkbox } from 'src/ui/input/checkbox/Checkbox';
import { Typo } from 'src/ui/typography/Typo';
import texts from 'src/texts/current/shieldPageText.json';
import { Column } from 'src/ui/Column/Column';
import { Row } from 'src/ui/row/Row';
import { FormInput } from 'src/ui/input/form/FormInput';

const formText = texts.form.radios;

export const ShieldFormStatement = () => {
  const { register, formState: { errors }, watch } = useFormContext();
  const isError = errors.statement1 || errors.statement2 || errors.statement3;
  return (
    <Column>
      <FormInput
        htmlFor='clarification'
        label={formText.label}
        hint={isError && formText.hint}
        required
        isError={!!isError}
      >
        {formText.values.map((val, index) => (
          <Row
            gap='md'
            flex={1}
            alignItems='center'
            key={val.key}
            margin={['md']}
          >
            <Checkbox
              checked={!!watch(`statement${index + 1}`)}
              value='checked'
              {...register(`statement${index + 1}`)}
            />
            <Typo.Paragraph>
              {val.text}
            </Typo.Paragraph>
          </Row>
        ))}
      </FormInput>
    </Column>

  );
};
