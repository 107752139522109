import ReactPaginate from 'react-paginate';

import chevronPictoLeftMd from '@caprikorn/dynamo-icons/chevronLeftSm.svg';
import chevronPictoRightMd from '@caprikorn/dynamo-icons/chevronRightSm.svg';
import { ReactSvgWrapper } from '../pictos/wrappers/ReactSVGWrapper';
import * as Styled from './Pagination.styles';

export type PageObject = { selected: number };

export interface IPaginationProps {
  onPageChange(page: PageObject): void;
  numberOfPages: number;
  forcePage?: number;
}

// To be able to target pages number for tests
// const setTestIdPages = () => {
//   document.querySelectorAll('li.Page').forEach((li, index) => {
//     li.id = `test-pagination-inactivePages-${index}`;
//   });
//   document.querySelectorAll('li.Page.ActivePage').forEach((li) => {
//     li.id = 'test-pagination-activePage';
//   });
// };
// setTestIdPages();

const renderChangePageIcons = (src: string, testId: string) => (
  <Styled.IconArrowContainer>
    <ReactSvgWrapper
      src={src}
      size='18'
      dataTestId={testId}
    />
  </Styled.IconArrowContainer>
);

// https://github.com/AdeleD/react-paginate/issues/459
// React Paginate has a minor accessibility issue
export const Pagination = ({
  onPageChange,
  numberOfPages,
  forcePage,
}: IPaginationProps) => (
  <Styled.ReactPaginateWrapper className='Pagination-wrapper' data-testid='Pagination'>
    <ReactPaginate
      pageCount={numberOfPages}
      pageRangeDisplayed={5}
      marginPagesDisplayed={3}
      breakClassName='PageEllipsis'
      previousLabel={
        numberOfPages > 1 ? renderChangePageIcons(chevronPictoLeftMd, 'test-pagination-left') : ''
          }
      nextLabel={
          numberOfPages > 1 ? renderChangePageIcons(chevronPictoRightMd, 'test-pagination-right') : ''
         }
      breakLabel='...'
      previousLinkClassName='PreviousLink'
      nextLinkClassName='NextLink'
      // disabledClassName='DisabledLink'
      onPageChange={onPageChange}
      containerClassName='Container'
      pageClassName='Page'
      activeClassName='ActivePage'
      pageLinkClassName='PageLink'
      forcePage={forcePage}
    />
  </Styled.ReactPaginateWrapper>
);
