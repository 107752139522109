import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';

import tenantConfig from 'src/configuration';
import { onLogout } from 'src/domains/auth/auth.utils';
import { getLocalStorageData, localStorageKey, setLocalStorageData } from './localStorage.utils';

const URL = {
  BASE: tenantConfig.API_ROOT,
  REFRESH: '/public/refresh-token',
};

export const axiosInstance: AxiosInstance = axios.create({
  headers: {
    'x-tenant-id': tenantConfig.TENANT_NAME,
  },
  baseURL: URL.BASE,
});

const getRefreshToken = async (
  refreshToken: string | null,
  userId: string | null,
): Promise<AxiosResponse<{ accessToken: string }>> => await axios.post(URL.BASE + URL.REFRESH, {
  username: userId,
  refreshToken,
});

const hydrateToken = async (): Promise<string> => {
  const refreshToken = getLocalStorageData(localStorageKey.refreshToken);
  const userId = getLocalStorageData(localStorageKey.email);
  const { data } = await getRefreshToken(refreshToken, userId);
  return data.accessToken;
};

const validateAccessToken = async () => {
  try {
    const accessToken = getLocalStorageData(localStorageKey.accessToken);
    const TEN_MINUTE_TIMESTAMP = 600000;
    const currentTime = Date.now();

    // uncomment to trigger an automatic logout
    // eslint-disable-next-line no-throw-literal
    // throw 'token';

    const decodedToken: { exp: number } = jwtDecode(accessToken || '');
    if ((currentTime + TEN_MINUTE_TIMESTAMP) < decodedToken.exp * 1000) {
      return accessToken;
    }

    const newAccessToken = await hydrateToken();
    setLocalStorageData(localStorageKey.accessToken, newAccessToken);
    return newAccessToken;
  } catch (error) {
    onLogout();
    throw error;
  }
};

export async function getRequest<ReturnType>(url: string, config?: AxiosRequestConfig) {
  const accessToken = await validateAccessToken();
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  const response = await axiosInstance.get<ReturnType>(url, config);

  return response;
}

export async function postRequest<ReturnType, dataType>(
  url: string,
  data: dataType,
  config?: AxiosRequestConfig,
) {
  const accessToken = await validateAccessToken();
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  const response = await axiosInstance.post<ReturnType>(url, data, config);

  return response;
}

export async function putRequest<ReturnType>(url: string, config?: Record<string, string>) {
  const accessToken = await validateAccessToken();
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  const response = await axiosInstance.put<ReturnType>(url, config);

  return response;
}

export async function deleteRequest<ReturnType>(url: string) {
  const accessToken = await validateAccessToken();
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  return await axiosInstance.delete<ReturnType>(url);
}

export async function patchRequest<ReturnType, dataType>(
  url: string,
  data: dataType,
  config?: Record<string, string>,
) {
  const accessToken = await validateAccessToken();
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  const response = await axiosInstance.patch<ReturnType>(url, data, config);

  return response;
}
