import { Label } from 'src/ui/typography/label/Label';
import { Typo } from 'src/ui/typography/Typo';
import { Row } from 'src/ui/row/Row';
import { InputDescription } from '../description/InputDescription';

export interface IFormInputProps {
  htmlFor: string;
  label: string;
  children: React.ReactNode;
  hint?: string;
  required?: boolean;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  isError?: boolean;
}

export const FormInput: React.FC<IFormInputProps> = ({
  htmlFor,
  label,
  hint,
  children,
  className,
  required,
  dataTestId,
  disabled,
  isError,
}: IFormInputProps) => (
  <div data-testid={dataTestId} className={className}>
    <Row margin={[0, 'lg', 'xxs', 'lg']} alignItems='flex-start'>
      <Label
        htmlFor={htmlFor}
        type={disabled ? 'disabled' : 'secondary'}
        weight='semi-bold'
      >
        {label}
        {required && <Typo.Span type='warning'>&nbsp;*</Typo.Span>}
      </Label>
    </Row>
    {children}
    <Row margin={['xs', 'auto', 'auto', 'lg']}>
      <InputDescription
        description={hint}
        disabled={disabled}
        isError={isError}
      />
    </Row>
  </div>
);
