import { ButtonHTMLAttributes } from 'react';
import styled from 'src/theme/ThemeProvider';

export interface IStyledButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  size: number;
  color?: string;
}

export const StyledButton = styled.button<IStyledButtonProps>`
  background: none;
  padding: 0;
  border: none;
  height: ${({ size }) => `${size}px`};
  cursor: pointer;
  outline: inherit;
  pointer-events: ${({ isLoading, disabled }) => ((isLoading || disabled) ? 'none' : 'auto')};
  ${({ color }) => color && `
      & > div > div > svg > path {
        fill: ${color};
      }
  `};
`;
