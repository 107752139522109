import styled from 'src/theme/ThemeProvider';
import { INPUT_HEIGHT } from '../basic/Input.styles';

export const Container = styled.div<{isPrefixed?: boolean, pictoSize: number, cursor?: string }>`
  cursor: ${({ cursor }) => cursor || 'pointer'};
  position: absolute;
  right: ${({ isPrefixed, theme }) => !isPrefixed && `${theme.sizes.space.lg}px`};
  left: ${({ isPrefixed, theme }) => isPrefixed && `${theme.sizes.space.lg}px`};
  // -2 correspond to the border height
  top: ${({ pictoSize }) => (`${(INPUT_HEIGHT - pictoSize - 2) / 2}px`)};
`;
