import { useState } from 'react';
import { useTicketsQuery } from 'src/domains/ticket/TicketQueries';
import { Header } from 'src/ui/header/Header';
import ticketPicto from '@caprikorn/dynamo-icons/ticketSimple.svg';
import { routes } from 'src/router/RouteConstants';
import ticketPageText from 'src/texts/current//ticketPageText.json';
import { filterValues } from 'src/domains/ticket/TicketsTypes';
import TicketsContent from './TicketsContent';

const TicketsPageProvider = () => {
  const [pageSize, setPageSize] = useState<number>(15);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterStatus, setFilterStatus] = useState<filterValues[]>(['2', '3', '5']);

  const {
    isLoading,
    data,
  } = useTicketsQuery({ page: currentPage, size: pageSize, filters: filterStatus });

  return (
    <>
      <Header
        breadcrumbPaths={[{
          pathName: ticketPageText.header,
          path: routes.FRESHDESK_TICKETS,
          pathIcon: ticketPicto,
        }]}
      />
      <TicketsContent
        isLoading={isLoading}
        data={data}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
      />
    </>
  );
};

export default TicketsPageProvider;
