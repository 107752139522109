import ticketText from 'src/texts/current/ticketPageText.json';
import commonText from 'src/texts/current/commonText.json';
import { Theme } from 'src/theme/ThemeProvider';

export const pageStatusOptions = ({ colors }: Theme) => [
  {
    label: ticketText.statusFilterOptions.closed,
    value: '5',
    customSelectedColor: colors.semantic.success[50],
  },
  {
    label: ticketText.statusFilterOptions.waiting,
    value: '3',
    customSelectedColor: colors.semantic.warning[50],
  },
  {
    label: ticketText.statusFilterOptions.open,
    value: '2',
    customSelectedColor: colors.semantic.info[50],
  }];

export const pageSizeOptions = [
  { value: '15', label: commonText.sizePerPage.options[15] },
  { value: '30', label: commonText.sizePerPage.options[30] },
  { value: '50', label: commonText.sizePerPage.options[50] },
  { value: '75', label: commonText.sizePerPage.options[75] },
  { value: '100', label: commonText.sizePerPage.options[100] },
];
