import theme from 'src/theme';
import styled from 'src/theme/ThemeProvider';
import { Input } from '../basic/Input.styles';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;
// placeholder margin + icon pading
const computedPadding = theme.sizes.space.md + theme.sizes.space.xs;

export const CustomInput = styled(Input) <{
  isPrefixed?: boolean;
  isSuffixed?: boolean;
  pictoSize: number;
}>`
  padding-right: ${({ isSuffixed, pictoSize }) => isSuffixed && `${(computedPadding + pictoSize)}px`};
  padding-left: ${({ isPrefixed, pictoSize }) => isPrefixed && `${(computedPadding + pictoSize)}px`};
`;
