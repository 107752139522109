import styled from 'src/theme/ThemeProvider';
import { Card } from 'src/ui/card/Card';

export const DrawerContainer = styled(Card)`
  // TODO: This should not be override, but they should be card props
  margin: ${({ theme }) => `${theme.sizes.space.xs}px ${theme.sizes.space.lg}px`};
  padding: ${({ theme }) => theme.sizes.space.md}px;
  height: inherit;
  overflow: hidden;
`;
