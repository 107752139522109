import { AxiosResponse } from 'axios';
import { postRequest } from 'src/libs/requests';
import {
  IBillConsolidationPayload,
  IBillConsolidationResponse,
  IPointOfDeliveryPayload,
  IPointOfDeliveryResponse,
} from './SearchTypes';

const URL = {
  BILL_CONSOLIDATIONS: '/bill-consolidations/filter',
  POINT_OF_DELIVERY: '/points-of-delivery/filter',
};

export const fetchSearchBillConsolidationResults = async (
  payload: IBillConsolidationPayload,
): Promise<AxiosResponse<IBillConsolidationResponse>> => {
  try {
    return await postRequest(
      `${URL.BILL_CONSOLIDATIONS}?page=${payload.paginationPayload.page}&size=${payload.paginationPayload.size}`,
      payload.inputsPayload,
    );
  } catch (error) {
    throw error;
  }
};

export const fetchSearchPointOfDeliveryResults = async (
  payload: IPointOfDeliveryPayload,
): Promise<AxiosResponse<IPointOfDeliveryResponse>> => {
  try {
    return await postRequest(
      `${URL.POINT_OF_DELIVERY}?page=${payload.paginationPayload.page}&size=${payload.paginationPayload.size}`,
      payload.inputsPayload,
    );
  } catch (error) {
    throw error;
  }
};
