import { CenterContentLayout } from 'src/components/layouts/position/CenterContentLayout';
import { Loader } from 'src/ui/loader/Loader';
import { ErrorTemplate } from 'src/components/errors/templates/ErrorTemplate';
import { Column } from 'src/ui/Column/Column';
import commonText from 'src/texts/current/commonText.json';

import { IParsedSearchResults, LevelTypes } from '../SearchDrawer.types';
import { SearchDrawerResults } from './SearchDrawerResults';
import { EmptyState } from './empty/EmptyState';

interface ISearchDrawerSwitcherProps {
  level: LevelTypes;
  isLoading?: boolean;
  isError?: boolean;
  data?: IParsedSearchResults;
  toggleDrawer(): void;
  submitPageChange(page: number): void;
  submitSizeChange(size: number): void;
}

export const SearchDrawerSwitcher = ({
  level,
  isLoading,
  isError,
  data,
  toggleDrawer,
  submitPageChange,
  submitSizeChange,
}: ISearchDrawerSwitcherProps) => {
  if (isLoading || isError) {
    return (
      <CenterContentLayout>
        {isError ? <ErrorTemplate imageType='error' title={commonText.errors.tryAgain} /> : <Loader />}
      </CenterContentLayout>
    );
  }

  if (data?.pagination.recordsCount === 0) {
    return (
      <Column overflow='auto'>
        <EmptyState level={level} />
      </Column>
    );
  }

  if (data) {
    return (
      <SearchDrawerResults
        toggleDrawer={toggleDrawer}
        level={level}
        data={data}
        submitPageChange={submitPageChange}
        submitSizeChange={submitSizeChange}
      />
    );
  }

  return null;
};
