import { DocumentExtensionType, DocumentExtensionTypeXls } from 'src/domains/common/CommonTypes';

export enum DocumentNameValues {
  CONTRACT= 'CONTRACT',
  BUDGETARY_ANNEX='BUDGETARY_ANNEX',
  TERMS_CONDITIONS='TERMS_CONDITIONS',
  DATA_COLLECTION_AUTHORIZATION='DATA_COLLECTION_AUTHORIZATION',
  METERS_ANNEX='METERS_ANNEX',
  CONSUMPTION_RECORD='CONSUMPTION_RECORD',
  BILLING_RECORD='BILLING_RECORD',
  OTHER='OTHER'
}

export type DocumentNameValuesType = keyof typeof DocumentNameValues;

export type DocAggregatedByCategoriesType = Record<DocumentNameValuesType, DocByFileExtensionType>;

export type DocByFileExtensionType = {
  documentsPdf: IDocumentInformation[];
  documentsXls: IDocumentInformation[];
};

export interface IDocumentInformation {
  created: string;
  documentType: DocumentNameValuesType;
  fileExtension: DocumentExtensionType | DocumentExtensionTypeXls;
  fileName: string;
  s3VersionID: string;
}

export interface IDocumentDownloadInfo {
  fileName: string;
  s3VersionID: string;
  fileExtension: DocumentExtensionType | DocumentExtensionTypeXls;
}

export enum DocumentErrorNames {
  DOCUMENT_EMPTY = 'DOCUMENT_EMPTY',
  DOCUMENT_DOWNLOAD = 'DOCUMENT_DOWNLOAD',
  EXTRACT_PERIM_GENERATE = 'EXTRACT_PERIM_GENERATE',
  EXTRACT_DOWNLOAD = 'EXTRACT_DOWNLOAD',
  NO_ERROR = 'NO_ERROR',
}

export type DocumentErrorNamesType = keyof typeof DocumentErrorNames;
