import styled from 'src/theme/ThemeProvider';
import { ErrorBoundary } from 'src/components/errors/ErrorBoundary';
import { useLocation } from 'react-router-dom';
import { Menu } from 'src/components/menu/Menu';

interface IProps {
  children: React.ReactNode;
}

const StyledContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const StyledMain = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column; // Warn: this force all page to be in a column flex layout
  flex: 1; 
  overflow-y: scroll;
`;

const AppLayout = ({ children }: IProps) => {
  const { pathname } = useLocation();

  return (
    <StyledContainer>
      <Menu />
      <ErrorBoundary pathname={pathname}>
        <StyledMain>
          {children}
        </StyledMain>
      </ErrorBoundary>
    </StyledContainer>
  );
};
export default AppLayout;
