import { ImgHTMLAttributes } from 'react';
import placeholder from 'src/assets/pictos/placeholder.svg';

export interface IImageWrapperProps extends ImgHTMLAttributes<HTMLImageElement> {
  size?: number;
  dataTestId?: string;
}

export const ImgWrapper = ({
  src,
  alt,
  size = 24,
  height,
  width,
  loading = 'lazy',
  className,
  dataTestId,
}: IImageWrapperProps) => (
  <img
    src={src}
    alt={alt}
    height={height ?? size}
    width={width ?? size}
    className={className}
    onError={({ currentTarget }) => {
      currentTarget.onerror = null; // prevents infinite looping
      currentTarget.src = placeholder;
    }}
    loading={loading}
    data-testid={dataTestId}
  />
);
