import { Box, IBoxProps } from '../box/Box';

export type ICardProps = Omit<IBoxProps, 'radius'>;

export const Card = ({
  children,
  className,
  dataTestId,
  borderColor,
  onClick,
  backgroundColor,
  spacing = 'lg',
  ...props
}: ICardProps) => (
  <Box
    {...props}
    borderColor={borderColor}
    className={className}
    dataTestId={dataTestId}
    backgroundColor={backgroundColor}
    spacing={spacing}
    shadow='sm'
    radius='md'
    onClick={onClick}
  >
    {children}
  </Box>
);
