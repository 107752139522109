export const lineHeights = {
  title: {
    h5: 26,
    h4: 30,
    h3: 34,
    h2: 40,
    h1: 48,
  },
  text: {
    xs: 16,
    sm: 20,
    md: 24,
    lg: 28,
  },
};
