import { Typo } from 'src/ui/typography/Typo';
import texts from 'src/texts/current/shieldPageText.json';
import { Column } from 'src/ui/Column/Column';
import { replaceText } from 'src/libs/text.utils';
import commonText from 'src/texts/current/commonText.json';
import { Row } from 'src/ui/row/Row';
import { useFormContext } from 'react-hook-form';
import { SegmentedControl } from '@caprikorn/dynamo-ui';

const shieldTexts = texts.shieldPossible;

export const EligibilityForm = () => {
  const { setValue, watch } = useFormContext();
  const elegibilityValue = 'Éligible';
  const nonElegibilityValue = 'Non éligible';
  const defVal = watch('isEligible') ? elegibilityValue : nonElegibilityValue;

  return (
    <Column>
      <Row gap='md' alignItems='center'>
        <Typo.Paragraph weight='bold'>
          {replaceText(shieldTexts.declareUneligibility, {
            replaceValues: { brandName: commonText.brandName },
          })}
        </Typo.Paragraph>
        <SegmentedControl
          onChange={(val: string) => {
            setValue('isEligible', val === elegibilityValue);
          }}
        >
          <SegmentedControl.Item
            isSelected={defVal === elegibilityValue}
            value={elegibilityValue}
          />
          <SegmentedControl.Item
            isSelected={defVal === nonElegibilityValue}
            value={nonElegibilityValue}
          />
        </SegmentedControl>
      </Row>
    </Column>
  );
};
