import styled from 'src/theme/ThemeProvider';
import { SpaceType } from '../spacer/Spacer';

export interface ISplitLineProps {
  upDownSpacing: SpaceType;
  size?: number;
  color: string;
}

const StyledDiv = styled.div<ISplitLineProps>`
  display: flex;
  margin: ${({ theme, upDownSpacing }) => theme.sizes.space[upDownSpacing]}px 0px;
  height:  ${({ size }) => size}px;
  border-radius: 1px;
  background: ${({ color }) => color};
`;

export const SplitLine = ({
  upDownSpacing,
  size = 2,
  color,
}: ISplitLineProps) => (
  <StyledDiv
    upDownSpacing={upDownSpacing}
    size={size}
    color={color}
  />
);
