import { useEffect, useState } from 'react';

import { IAlertProps } from 'src/ui/alert/Alert';
import closePictoSm from '@caprikorn/dynamo-icons/closeSm.svg';

import { useTheme } from 'src/theme/ThemeProvider';
import { Row } from 'src/ui/row/Row';
import { PictoAsButton } from '../../button/picto/PictoAsButton';
import * as Styled from './Notification.styles';
import { Spacer } from '../../spacer/Spacer';

export interface INotificationProps extends Pick<IAlertProps, 'children' | 'type'> {
  closeAction?: () => void;
  dataTestId?: string;
  showNotif: boolean;
}

export const Notification = ({
  children,
  type,
  dataTestId = '',
  closeAction = () => true,
  showNotif = true,
}: INotificationProps) => {
  const theme = useTheme();
  const [show, setShow] = useState<boolean>(showNotif);

  useEffect(() => {
    setShow(showNotif);
  }, [showNotif]);

  const onClose = () => {
    setShow(false);
    closeAction();
  };

  if (show) {
    return (
      <Styled.Container data-testid={`Notification ${dataTestId}`}>
        <Styled.CustomAlert type={type}>
          <Row alignItems='center'>
            {children}
            <Spacer width='md' />
            <PictoAsButton
              size='md'
              src={closePictoSm}
              onClick={onClose}
              dataTestId='test-close-notif'
              color={theme.colors.text.primary}
            />
          </Row>
        </Styled.CustomAlert>
      </Styled.Container>
    );
  }

  return null;
};
