import React from 'react';

import * as Styled from './Input.styles';
import { IInputProps } from './Input.types';

export const Input = React.forwardRef<HTMLInputElement, IInputProps>(({
  type,
  placeholder,
  name,
  dataTestId,
  isError,
  disabled,
  className,
  onChange,
  onBlur,
  ddPrivacyAllow,
}: IInputProps, ref) => (
  <Styled.Input
    className={className}
    onChange={onChange}
    onBlur={onBlur}
    type={type}
    placeholder={placeholder}
    name={name}
    isError={isError}
    ref={ref}
    data-testid={dataTestId}
    data-dd-privacy={ddPrivacyAllow}
    disabled={disabled}
    min={0} // For input type number
  />
));

// Prevent eslint error: Component definition is missing display name  react/display-name
Input.displayName = 'Input';
