export const enum accessQueriesEnum {
  USERS_LIST_QUERY = 'USERS_LIST',
  USER_BY_ID_QUERY = 'USER_BY_ID',
  CREATE_USER = 'CREATE_USER',
  DELETE_USER = 'DELETE_USER',
  EDIT_USER_AUTHORIZATIONS = 'EDIT_USER_AUTHORIZATIONS',
  EDIT_USER_IDENTITY = 'EDIT_USER_IDENTITY',
  CHECK_EMAIL = 'CHECK_EMAIL',
}

export const accessKeysEnum = {
  [accessQueriesEnum.USER_BY_ID_QUERY]: (
    id: string,
  ) => [accessQueriesEnum.USER_BY_ID_QUERY, id],
  [accessQueriesEnum.USERS_LIST_QUERY]: [accessQueriesEnum.USERS_LIST_QUERY],
  [accessQueriesEnum.CREATE_USER]: [accessQueriesEnum.CREATE_USER],
  [accessQueriesEnum.DELETE_USER]: [accessQueriesEnum.DELETE_USER],
  [accessQueriesEnum.CHECK_EMAIL]: [accessQueriesEnum.CHECK_EMAIL],
  [accessQueriesEnum.EDIT_USER_IDENTITY]: (
    id: string,
  ) => [accessQueriesEnum.EDIT_USER_IDENTITY, id],
  [accessQueriesEnum.EDIT_USER_AUTHORIZATIONS]: [accessQueriesEnum.EDIT_USER_AUTHORIZATIONS],
} as const;
