import styled from 'src/theme/ThemeProvider';
import { Spacer } from 'src/ui/spacer/Spacer';
import { IconButton } from 'src/ui/button/icon/IconButton';
import retryPicto from '@caprikorn/dynamo-icons/arrowReload.svg';
import errorsText from 'src/texts/current/errorsText.json';
import { ErrorTemplate, IErrorTemplateTitleProps } from './templates/ErrorTemplate';

interface IRetryErrorProps extends IErrorTemplateTitleProps{
  onRetry(): void;
  dataTestId?: string;
  title?: string;
  onRetryButtonText?: string;
  text?: string;
  isSubmit?: boolean;
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
`;

// TO change this title to default
export const RetryError = ({
  onRetry,
  dataTestId = 'RetryError-container',
  title,
  text,
  onRetryButtonText,
  titleTag,
  titleStyle,
  isSubmit,
}: IRetryErrorProps) => (
  <ErrorTemplate
    title={title ?? errorsText.retryError.title}
    text={text ?? errorsText.retryError.text}
    imageType='error'
    dataTestId={dataTestId}
    titleTag={titleTag}
    titleStyle={titleStyle}
  >
    <Spacer height='lg' />
    <StyledDiv>
      <IconButton
        onClick={onRetry}
        variant='primary'
        rightIcon={retryPicto}
        dataTestId='RetryError-retry'
        type={isSubmit ? 'submit' : 'button'}
      >
        {onRetryButtonText ?? errorsText.retryError.buttonText}
      </IconButton>
    </StyledDiv>
  </ErrorTemplate>

);
