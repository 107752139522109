import empty from 'src/assets/images/current/empty_state.svg';
import error from 'src/assets/images/current/generic_error.svg';
import gas from 'src/assets/pictos/gasPicto.svg';
import elec from 'src/assets/pictos/elecPicto.svg';
import notFound from 'src/assets/images/current/not_found_error.svg';
import major from 'src/assets/images/current/major_error.svg';

export type ErrorImageType = 'error' | 'empty' | 'gas' | 'elec' | 'notFound' | 'major';

export const errorImageSelector = (value: ErrorImageType) => {
  switch (value) {
    case 'notFound':
      return notFound;

    case 'error':
      return error;

    case 'empty':
      return empty;

    case 'gas':
      return gas;

    case 'elec':
      return elec;

    case 'major':
      return major;

    default:
      return error;
  }
};
