import { EnergyAccessValuesType } from './utils/utils.types';

interface IAppConfig {
  API_ROOT: string ;
  S3_STATIC_CLOUDFRONT: string ;
  ENERGY_TYPE: EnergyAccessValuesType;
  TENANT_NAME: string;
  DOMAIN: string ;
  ENV: 'dev' | 'qual' | 'preprod' | 'prod' | 'local';
}

export default {
  API_ROOT: import.meta.env.VITE_APP_API_ROOT,
  S3_STATIC_CLOUDFRONT: import.meta.env.VITE_APP_DOMAIN_S3_STATIC_CLOUDFRONT_PATH,
  ENERGY_TYPE: import.meta.env.VITE_APP_ENERGY_TYPE,
  TENANT_NAME: import.meta.env.VITE_APP_TENANT_NAME,
  DOMAIN: import.meta.env.VITE_APP_DOMAIN,
  ENV: import.meta.env.VITE_APP_ENV,
} as IAppConfig;
