import { ICardProps } from 'src/ui/card/Card';
import * as Styled from './DrawerContainer.styles';

export interface IDrawerContainerProps extends ICardProps {
  children: React.ReactNode;
  dataTestId?: string;
  className?: string;
}

export const DrawerContainer = ({
  children,
  dataTestId = 'Drawer-container',
  className,
  ...props
}: IDrawerContainerProps) => (
  <Styled.DrawerContainer
    dataTestId={dataTestId}
    className={className}
    {...props}
  >
    {children}
  </Styled.DrawerContainer>
);
