import * as yup from 'yup';
import yupHelpersText from 'src/texts/current/yupHelpersText.json';

// If you need to change this email, you need to ask for a complete user database
// and check the regex against user database
export const emailSchema = yup.string().email().matches(/([A-Za-z0-9._%-])+@[A-Za-z0-9._%-]+\.([A-Za-z-.]){1,}/);

export const phoneSchema = yup.string().matches(/^((\+)33|0|0033)[1-9](\d{2}){4}$/);

const passwordSchema = yup.string()
  .min(8, yupHelpersText.password.minCharacter)
  .matches(/^(?=.*[a-z])/, yupHelpersText.password.minMinus)
  .matches(/^(?=.*[A-Z])/, yupHelpersText.password.minMaj)
  .matches(/^(?=.*[0-9])/, yupHelpersText.password.minDigit)
  .matches(/^(?=.*[!'#"*`@$%^&(){}[\]:;<>,.?~_+\-=§€£°|/\\])/, yupHelpersText.password.minSpecial);

export const modifyPasswordSchema = yup.object().shape({
  previousPassword: yup.string().required(),
  newPassword: passwordSchema.required(),
  confirmNewPassword: passwordSchema.oneOf(
    [yup.ref('newPassword')],
    yupHelpersText.password.pwdEqual,
  ).required(),
});

export const searchInputSchema = yup.object().shape({
  search: yup.string().required().min(3, yupHelpersText.searchInput),
});

export const resetPasswordSchema = yup.object().shape({
  newPassword: passwordSchema.required(),
  confirmNewPassword: passwordSchema.oneOf(
    [yup.ref('newPassword')],
    yupHelpersText.password.pwdEqual,
  ).required(),
});

export const cpSchema = yup.string().length(5, yupHelpersText.fiveDigitsCode);

export const sirenSchema = yup.string().length(9, yupHelpersText.codePdlPce);
