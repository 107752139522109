import { useState } from 'react';
import { useDocumentDownloadMutation, useDocumentsQuery } from 'src/domains/documents/DocumentsQueries';
import { DocumentErrorNamesType, IDocumentDownloadInfo } from './Documents.types';
import { sortAndFormatForTableDocuments } from './formatDocTableDataFunction';

export const useDocumentDownloadAction = (
  onErrorActions: (errorCase: DocumentErrorNamesType) => void,
) => {
  const {
    data: documentsData,
    isError: isGetDocumentsError,
    refetch: documentRefetch,
  } = useDocumentsQuery();

  const [processingId, setProcessingId] = useState('');
  const mutation = useDocumentDownloadMutation(onErrorActions);

  const mutateFunction = async ({
    fileName,
    s3VersionID,
    fileExtension,
  }: IDocumentDownloadInfo) => {
    setProcessingId(s3VersionID);
    try {
      const data = await mutation.mutateAsync({ fileName, s3VersionID, fileExtension });
      return data;
    } catch (error) {
      return error;
    }
  };

  const formattedData = documentsData?.data.groupDocuments ? sortAndFormatForTableDocuments({
    documents: documentsData?.data.groupDocuments,
    isLoading: mutation.isPending,
    processingId,
    mutateFunction,
  }) : [];

  return {
    mutationStatus: documentsData?.status ?? mutation.error?.response?.status,
    mutateFunction,
    processingId,
    formattedData,
    isGetDocumentsError,
    documentRefetch,
  };
};
