import { TextTypes } from 'src/ui/typography/text/Text';
import styles from 'src/ui/typography/cta/Cta.module.css';

export interface ICtaProps {
  children: string | React.ReactNode;
  className?: string;
  type?: TextTypes;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  dataTestId?: string;
}

export const Cta: React.FC<ICtaProps> = ({
  children,
  className,
  type,
  size,
  dataTestId,
}: ICtaProps) => {
  const cx = [
    'TypoCommon',
    styles.Cta,
    'TitlesFontFamily',
    `TypoCommon-${type}`,
    styles[`Cta-${size}`],
    `TitlesCommon-${size === 'xs' ? 'bold' : 'semi-bold'}`,
    className,
  ];

  const CustomCTATag = 'span' as keyof JSX.IntrinsicElements;

  return (
    <CustomCTATag className={cx.join(' ')} data-testid={dataTestId}>
      {children}
    </CustomCTATag>
  );
};
