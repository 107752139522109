import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import TicketsPageProvider from 'src/pages/ticket/TicketsPageProvider';
import { DocumentsPage } from 'src/pages/documents/DocumentsPage';
import { ShieldPage } from 'src/pages/shield/ShieldPage';
import AppLayout from 'src/components/layouts/app/AppLayout';
import { CenterContentLayout } from 'src/components/layouts/position/CenterContentLayout';
import { ErrorNotFound } from 'src/components/errors/ErrorNotFound';
import { GroupReferenceContextProvider } from 'src/contexts/GroupReferences.context';
import { routes } from './RouteConstants';
import { PerimeterRouter } from './PerimeterRouter';
import { AccessManagerRouter } from './AccessManagerRouter';

interface IPrivateRouterProps {
  authenticated: boolean;
}

const SettingsPage = lazy(() => import('src/pages/settings/SettingsPage'));
const DashboardPage = lazy(() => import('src/pages/dashboard/DashboardPage'));

export const PrivateRouter = ({ authenticated }: IPrivateRouterProps) => {
  // https://github.com/remix-run/react-router/issues/7285

  if (authenticated) {
    return (
      <AppLayout>
        <GroupReferenceContextProvider>
          <Suspense fallback={null}>
            <Routes>
              <Route index element={<DashboardPage />} />
              <Route path={routes.SETTINGS} element={<SettingsPage />} />
              <Route path={routes.FRESHDESK_TICKETS} element={<TicketsPageProvider />} />
              <Route path={routes.DOCUMENTS} element={<DocumentsPage />} />
              <Route path={routes.SHIELD} element={<ShieldPage />} />
              <Route path={`${routes.ACCESS_MANAGER}/*`} element={<AccessManagerRouter />} />
              <Route path='/search/*' element={<PerimeterRouter />} />
              <Route
                path='*'
                element={(
                  <CenterContentLayout>
                    <ErrorNotFound />
                  </CenterContentLayout>
              )}
              />
            </Routes>
          </Suspense>
        </GroupReferenceContextProvider>

      </AppLayout>
    );
  }

  return (
    <Navigate to='/login' />
  );
};
