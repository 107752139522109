import { Column } from 'src/ui/Column/Column';

import { IParsedSearchResults, LevelTypes } from '../SearchDrawer.types';
import { CustomDrawerContainer } from '../SearchDrawer.styles';
import { SearchDrawerFooter } from '../components/SearchDrawerFooter';
import { SearchDrawerSwitcher } from '../components/SearchDrawerSwitcher';

interface ISearchForm {
  toggleDrawer(): void;
  level: LevelTypes;
  children: React.ReactNode;
  data?: IParsedSearchResults;
  isLoading?: boolean;
  isError?: boolean;
  submitPageChange(page: number): void;
  submitSizeChange(size: number): void;
}

export const SearchForm = ({
  toggleDrawer,
  level,
  children,
  data,
  isError,
  isLoading,
  submitPageChange,
  submitSizeChange,
}: ISearchForm) => (
  <Column padding={['xs']}>
    <CustomDrawerContainer backgroundColor='foreground1'>
      <Column gap='sm'>
        {children}
        <SearchDrawerSwitcher
          level={level}
          isError={isError}
          isLoading={isLoading}
          toggleDrawer={toggleDrawer}
          data={data}
          submitPageChange={submitPageChange}
          submitSizeChange={submitSizeChange}
        />
      </Column>
    </CustomDrawerContainer>
    <SearchDrawerFooter />
  </Column>
);
