import { disabledTariffFormCompletion } from 'src/configuration/utils/feature_flag';
import { Column } from 'src/ui/Column/Column';
import { Alert } from 'src/ui/alert/Alert';
import { Typo } from 'src/ui/typography/Typo';
import texts from 'src/texts/current/shieldPageText.json';
import { Button } from 'src/ui/button/basic/Button';
import { Row } from 'src/ui/row/Row';
import { ShieldCertificateDisplay } from './ShieldCertificateDisplay';
import { IFormattedShieldData } from '../ShieldPage.types';
import { ShieldNoCertificateSwitcher } from './ShieldNoCertificateSwitcher';
import { computeNoCertificateCases } from '../shield.utils';

interface IShieldStatusSwitcherProps {
  data: IFormattedShieldData;
  toggleForm(): void;
}

export const ShieldStatusSwitcher = ({ data, toggleForm }: IShieldStatusSwitcherProps) => {
  const noCertificateCases = computeNoCertificateCases({
    isShieldRejected: data.isShieldRejected,
    isTariffShieldFormClose: disabledTariffFormCompletion,
  });

  const isCertificateAvailable = data.certificate && !data.isShieldRejected;
  const isCertificateDoneIn2023 = data?.certificate && new Date(data.certificate?.signatureDate)
    .getFullYear() === 2023;

  const isCertificatePossible = ((data.certificate || data.isShieldRejected)
    && !disabledTariffFormCompletion);

  return (
    <Column gap='md'>
      { isCertificateAvailable
        ? <ShieldCertificateDisplay certificate={data.certificate} />
        : <ShieldNoCertificateSwitcher noCertificateCases={noCertificateCases} />}
      {isCertificatePossible && (
      <Alert type='warning'>
        <Row
          gap='xxl'
          alignItems='center'
          justifyContent='flex-start'
          flex={1}
        >
          <Typo.Paragraph weight='bold' size='lg'>
            {texts.newStatement[(isCertificateAvailable && isCertificateDoneIn2023) ? 'isEligible' : 'isNotEligible']}
          </Typo.Paragraph>
          <Button
            variant='primary'
            outline
            onClick={toggleForm}
          >
            {texts.newStatement.button}
          </Button>
        </Row>
      </Alert>
      )}
    </Column>
  );
};
