import { ChoiceButton } from './button/ChoiceButton';
import { Row } from '../row/Row';

export interface ChoicesProps {
  onChange: (newValue: string[]) => void;
  options: OptionType[];
  multiChoice?: boolean;
  selectedValues: string[];
  size?: 'sm' | 'md';
  variant?: 'primary' | 'danger';
  dataTestId?: string;
  layout?: 'inline' | 'list';
}

export interface OptionType {
  label: string;
  value: string;
  isDisabled?: boolean;
  dataTestId?: string;
  customSelectedColor?: string;
  customSelectedBackgroundColor?: string;
}

/*
Note : There is a performance problem with this component
handleOnClick creates a new function everytime which causes
every ChoiceButton to re-render at each click... Even though
it would only makes sense to re-render if isSelected changed.

We decided to keep it as it is because this implementation
keeps the code simple and we didn't think that the performance
would be a real issue.

We though hard about it but couldn't find a solution.
A lead we found :
https://stackoverflow.com/questions/59867996/react-usecallback-for-chained-anonymous-functions
But the issue is that our child's handleClick function
will always depend on selectedValues which is fated to
change at every click. Sooooo we're pretty much ******
*/

export const Choices = ({
  onChange,
  options,
  multiChoice,
  selectedValues,
  size = 'md',
  variant = 'primary',
  dataTestId = '',
  layout = 'inline',
}: ChoicesProps) => {
  const handleOnClick = (optionValue: string, alreadySelected: boolean) => () => {
    if (alreadySelected) {
      const updatedValues = [...selectedValues.filter((e) => e !== optionValue)];
      return onChange(updatedValues);
    }

    const updatedValues = multiChoice ? [...selectedValues, optionValue] : [optionValue];
    return onChange(updatedValues);
  };

  return (
    <Row flexWrap='wrap' data-testid={`${dataTestId}-Choices`}>
      {options.map((option) => {
        const isSelected = selectedValues?.includes(option.value);
        return (
          <ChoiceButton
            key={option.value}
            disabled={option.isDisabled}
            isSelected={isSelected}
            onClick={handleOnClick(option.value, isSelected)}
            dataTestId={`ChoiceButton-${option.value}`}
            size={size}
            variant={variant}
            layout={layout}
          >
            {option.label}
          </ChoiceButton>
        );
      })}
    </Row>
  );
};
