import styled from 'src/theme/ThemeProvider';
import { IInlineLoaderProps } from 'src/ui/loader/InlineLoader.types';

export const Loader = styled.div`
  display: flex;
  justify-content: center;

  @keyframes grow {
    to { transform: translateX(-50%) scale(0); }
  }
`;

export const Ball = styled.div<IInlineLoaderProps>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  animation: grow 1s ease-in-out infinite alternate;
  transform-origin: 100% 50%;
  margin: 0 2px;
  background-color: ${({ theme, color }) => (color || theme.colors.disable.text)};
`;

export const SecondBall = styled(Ball)`
  animation-delay: 0.33s;
`;

export const ThirdBall = styled(Ball)`
  animation-delay: 0.66s;
`;
