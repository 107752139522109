import { ThemeProvider as EmotionThemeProvider, useTheme as defaultUseTheme } from '@emotion/react';
import styled, { CreateStyled } from '@emotion/styled';

import ecmsTheme from '.';

type ThemeProps = Omit<typeof ecmsTheme, 'colors'>;
export interface Theme extends ThemeProps {
  colors: ReturnType<typeof ecmsTheme.colors>;
}

export type IconSizeType = keyof typeof ecmsTheme.sizes.icons;

export default styled as CreateStyled;

export const useTheme = () => defaultUseTheme();

interface IThemeProviderProps {
  children: React.ReactNode;
  theme?: Theme;
}

export const ThemeProvider = ({ children, theme }: IThemeProviderProps) => (
  <EmotionThemeProvider theme={theme as Theme}>
    {children}
  </EmotionThemeProvider>
);
