import { ChoiceButtonProps } from './ChoiceButton.types';
import * as Styled from './ChoiceButton.styles';

export const ChoiceButton = ({
  variant,
  children,
  className,
  dataTestId,
  onClick,
  disabled,
  isSelected,
  ariaLabel,
  size = 'md',
  layout = 'list',
}: ChoiceButtonProps) => (
  <Styled.ChoiceButton
    variant={variant}
    className={className}
    data-testid={dataTestId}
    onClick={onClick}
    size={size}
    disabled={disabled}
    isSelected={isSelected}
    layout={layout}
    type='button'
    aria-label={ariaLabel}
  >
    {children}
  </Styled.ChoiceButton>
);
