import styled from 'src/theme/ThemeProvider';
import { INPUT_HEIGHT } from '../input/basic/Input.styles';

export const HeaderContainer = styled.header`
  background: ${({ theme }) => theme.colors.background.elevated};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.sizes.space.sm}px;
  // -2 for border
  height: ${({ theme }) => INPUT_HEIGHT - 2 + theme.sizes.space.sm}px;
`;
