import { routes } from 'src/router/RouteConstants';
import documentsText from 'src/texts/current/documentsText.json';
import folderPicto from '@caprikorn/dynamo-icons/folder.svg';

import { useGroupsDropdown } from 'src/hooks/useGroupsDropdown';

import { RetryError } from 'src/components/errors/RetryError';
import { CenterContentLayout } from 'src/components/layouts/position/CenterContentLayout';
import { Loader } from 'src/ui/loader/Loader';
import { Header } from '../../ui/header/Header';
import { DocumentsTable } from './DocumentsTable';
import { useDocumentPage } from './DocumentPage.hooks';

export const DocumentsPage = () => {
  const {
    groupsDropdownOptions,
    groupsOnDropdownChange,
    groupsDropdownValue,
  } = useGroupsDropdown();

  const { isError, isLoading, refetch } = useDocumentPage();

  if (isLoading || isError) {
    return (
      <>
        <Header
          dropdownOptions={groupsDropdownOptions}
          onDropdownChange={(select) => groupsOnDropdownChange(select)}
          dropdownValue={groupsDropdownValue}
          breadcrumbPaths={[{
            pathName: documentsText.breadcrumbTitle,
            path: routes.DOCUMENTS,
            pathIcon: folderPicto,
          }]}
        />
        <CenterContentLayout>
          {isError ? (
            <RetryError
              title={documentsText.errorMessages.title}
              text={documentsText.errorMessages.global}
              onRetry={refetch}
            />
          ) : <Loader />}
        </CenterContentLayout>
      </>
    );
  }
  return (
    <>
      <Header
        dropdownOptions={groupsDropdownOptions}
        onDropdownChange={(select) => groupsOnDropdownChange(select)}
        dropdownValue={groupsDropdownValue}
        breadcrumbPaths={[{
          pathName: documentsText.breadcrumbTitle,
          path: routes.DOCUMENTS,
          pathIcon: folderPicto,
        }]}
      />
      <DocumentsTable />
    </>
  );
};
