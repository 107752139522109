import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { FormInput } from 'src/ui/input/form/FormInput';
import { removeEmptyValues } from 'src/libs/miscellaneous';
import searchText from 'src/texts/current/searchText.json';
import { PrefixedSuffixedInput } from 'src/ui/input/specials/PrefixedSuffixedInput';
import closePicto from '@caprikorn/dynamo-icons/closeMd.svg';
import { Typo } from 'src/ui/typography/Typo';
import { useSearchBillConsolidationMutation } from 'src/domains/search/SearchQueries';

import { IBillConsolidationPayload } from 'src/domains/search/SearchTypes';
import { useState } from 'react';
import { replaceText } from 'src/libs/text.utils';
import { schemaBillConsolidation } from '../SearchDrawer.validation';
import { IBillConsolidationForm } from '../SearchDrawer.types';
import * as Styled from '../SearchDrawer.styles';
import { SearchForm } from './SearchForm';
import { parseBillConsolidationResponse } from '../SearchDrawer.utils';

const { contracts: contractsText } = searchText.forms;

export const BillConsolidationForm = ({ toggleDrawer }: { toggleDrawer(): void}) => {
  const {
    register,
    handleSubmit,
    resetField,
    getValues,
    formState: { errors },
  } = useForm<IBillConsolidationForm>({
    resolver: yupResolver(schemaBillConsolidation),
    reValidateMode: 'onBlur',
    defaultValues: {
      billConsolidationName: '',
      billConsolidationReference: '',
    },
  });

  const {
    data: billConsolidationData,
    mutate,
    isError,
    isPending,
    reset,
  } = useSearchBillConsolidationMutation();

  const [currentSize, setCurrentSize] = useState<number>(10);

  const onSubmit = ({ page, size }: { page?: number, size?: number }) => {
    const data = getValues();
    const formatedData = removeEmptyValues(data);
    // TODO: check this typing madness with the boss when he's back
    // One option is partial of the incoming object but not sure
    mutate({
      inputsPayload: formatedData,
      paginationPayload: {
        page: page ?? 1,
        size: size ?? currentSize,
      },
    } as unknown as IBillConsolidationPayload);
  };

  const submitPageChange = (page: number) => onSubmit({ page });
  const submitSizeChange = (size: number) => {
    setCurrentSize(size);
    onSubmit({ size });
  };

  return (
    <Styled.CustomForm onSubmit={handleSubmit(onSubmit)}>
      <SearchForm
        toggleDrawer={toggleDrawer}
        level='billConsolidation'
        data={billConsolidationData && parseBillConsolidationResponse(billConsolidationData.data)}
        isError={isError}
        isLoading={isPending}
        submitPageChange={submitPageChange}
        submitSizeChange={submitSizeChange}
      >
        <FormInput htmlFor='billConsolidationName' label={replaceText(contractsText.labelInput.label)}>
          <PrefixedSuffixedInput
            disabled={isPending}
            suffixProps={{
              onClick: () => {
                reset();
                resetField('billConsolidationName');
              },
              content: closePicto,
            }}
            type='text'
            dataTestId='input-billConsolidationName'
            placeholder={contractsText.labelInput.placeholder}
            {...register('billConsolidationName')}
          />
        </FormInput>
        <FormInput htmlFor='billConsolidationReference' label={replaceText(contractsText.refInput.label)}>
          <PrefixedSuffixedInput
            disabled={isPending}
            suffixProps={{
              onClick: () => {
                reset();
                resetField('billConsolidationReference');
              },
              content: closePicto,
            }}
            type='text'
            dataTestId='input-billConsolidationReference'
            placeholder={contractsText.refInput.placeholder}
            {...register('billConsolidationReference')}
          />
        </FormInput>
        {(errors.billConsolidationName || errors.billConsolidationReference) && (
          <Typo.Caption type='error'>
            {searchText.forms.hint}
          </Typo.Caption>
        )}
      </SearchForm>
    </Styled.CustomForm>
  );
};
