import { useEffect, useState } from 'react';

import documentsText from 'src/texts/current/documentsText.json';
import {
  usePerimeterExtractDownloadMutation,
  usePerimeterExtractMutation,
  usePerimeterExtractStatusQuery,
} from 'src/domains/perimeter/PerimeterQueries';
import { perimeterExtractStatus } from 'src/domains/perimeter/PerimeterTypes';
import { useGroupDetailsQuery } from 'src/domains/common/CommonQueries';
import { computeSupportEmail } from 'src/libs/text.utils';
import { ErrorCodes } from 'src/domains/error/ErrorConstants';
import { Alert } from 'src/ui/alert/Alert';
import { Link } from 'src/ui/typography/link/Link';
import { Column } from 'src/ui/Column/Column';
import { Table } from '@caprikorn/dynamo-ui';
import { ColumnDef } from '@tanstack/react-table';
import { RowItemType } from 'src/ui/table/item/TableItem';
import styles from './DocumentsTable.module.css';
import { useDocumentDownloadAction } from './DocumentsTable.hooks';
import { DocumentErrorNames, DocumentErrorNamesType } from './Documents.types';
import { DocumentDownloadNotifier } from './DocumentDownloadNotifier';
import { generateExtractRow } from './extract/Extract.utils';
import * as Styled from './DocumentsPage.styles';

interface IRowItems {
  rowId: string;
  rowItems: RowItemType[];
}

export const DocumentsTable = () => {
  const { data } = useGroupDetailsQuery();
  const groupName = data?.group.name ?? '';
  const email = computeSupportEmail(data?.group.email) ?? '';

  const [
    errorNotifCase,
    setErrorNotifCase,
  ] = useState<DocumentErrorNamesType>(DocumentErrorNames.NO_ERROR);

  const {
    mutationStatus,
    formattedData,
    isGetDocumentsError,
  } = useDocumentDownloadAction((errorCase) => setErrorNotifCase(errorCase));

  const { mutate: generateExtractMutation } = usePerimeterExtractMutation(
    () => setErrorNotifCase(DocumentErrorNames.EXTRACT_PERIM_GENERATE),
  );

  const { mutate: downloadExtractMutation } = usePerimeterExtractDownloadMutation(
    groupName,
    () => setErrorNotifCase(DocumentErrorNames.EXTRACT_DOWNLOAD),
  );

  const {
    data: extractData,
    isError: isExtractError,
    refetch: extractPerimRefetch,
    isStale,
  } = usePerimeterExtractStatusQuery({ isAdmin: !!data?.isAdmin });

  useEffect(() => {
    if (isStale && extractData?.status === perimeterExtractStatus.IN_PROGRESS) {
      extractPerimRefetch();
    }
  }, [extractData?.status, isStale]);

  const isDataPartial = (mutationStatus === ErrorCodes.NO_CONTENT)
  || isExtractError || isGetDocumentsError;

  const documentColumns: ColumnDef<IRowItems>[] = [
    {
      accessorFn: (row) => row.rowItems[0],
      id: 'docName',
      header: documentsText.table.head[0],
      enableSorting: false,
    },
    {
      accessorFn: (row) => row.rowItems[1],
      id: 'docButtons',
      header: documentsText.table.head[1],
      cell: (info) => info.getValue(),
      meta: { alignHeader: 'right' },
      enableSorting: false,
    },
  ];

  const tableData: IRowItems[] = isDataPartial
    ? [generateExtractRow({
      extractStatus: extractData?.status,
      groupName,
      generateExtractMutation,
      downloadExtractMutation,
    })]
    : [
      ...formattedData.map((row) => ({
        rowId: row.rowId,
        rowItems: row.rowItems,
      })),
      generateExtractRow({
        extractStatus: extractData?.status,
        groupName,
        generateExtractMutation,
        downloadExtractMutation,
      }),
    ];

  return (
    <Column margin={['md']} gap='md'>
      <DocumentDownloadNotifier
        errorCase={errorNotifCase}
        closeAction={() => setErrorNotifCase(DocumentErrorNames.NO_ERROR)}
        status={mutationStatus}
        supportEmail={email}
      />
      <Styled.CustomCard>
        {(isDataPartial
        && (
        <Alert type='info'>
          <>
            {`${documentsText.noDocumentAvailable} `}
            <Link
              type='paragraph-md'
              href={email}
              isEmail
            >
              {email}
            </Link>
          </>
        </Alert>
        )
    )}
        <Table
          columns={documentColumns} data={tableData}
        >
          <Table.Content className={styles.DocumentsTable} />
        </Table>
      </Styled.CustomCard>
    </Column>
  );
};
