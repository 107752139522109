import { css } from '@emotion/react';
import { SemanticTypes } from 'src/domains/common/CommonTypes';
import styled from 'src/theme/ThemeProvider';
import { Typo } from '../typography/Typo';
import { ReactSvgWrapper } from '../pictos/wrappers/ReactSVGWrapper';

export enum RadiusEnum {
  'top-left' = 'top-left',
  'top-right' = 'top-right',
  'bottom-left' = 'bottom-left',
  'bottom-right' = 'bottom-right',
}

export type RadiusTypes = keyof typeof RadiusEnum;

const borderVariant = {
  'top-left': () => css`
    border-top-left-radius: 0;
  `,
  'top-right': () => css`
    border-top-right-radius: 0;
  `,
  'bottom-left': () => css`
    border-bottom-left-radius: 0;
  `,
  'bottom-right': () => css`
    border-bottom-right-radius: 0;
  `,
};

export const Wrapper = styled.div<{ variant?: SemanticTypes, radius?: RadiusTypes }>`
  border-radius: ${({ theme }) => theme.sizes.radii.xl}px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  ${({ radius }) => radius && borderVariant[radius]}

  background-color: ${({ theme, variant, color }) => (variant
    ? theme.colors?.semantic[variant][50]
    : color)};
`;

export const CustomCaption = styled(Typo.Caption)<{ variant?: SemanticTypes, chipTextRight?: string}>`
  padding: 0 ${({ theme }) => theme.sizes.space.sm}px;
  color: ${({ theme }) => (theme.colors.text.reverse)};
  display: flex;
  align-items: center;
`;

export const CustomSvgWrapper = styled(ReactSvgWrapper)`
  padding-right: ${({ theme }) => theme.sizes.space.xxs}px;
`;
