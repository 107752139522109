import { Text, TextTypes } from 'src/ui/typography/text/Text';

export interface ICaptionProps {
  children: string | React.ReactNode;
  className?: string;
  type?: TextTypes;
  dataTestId?: string;
}

export const Caption: React.FC<ICaptionProps> = ({
  children,
  className,
  type,
  dataTestId,
}: ICaptionProps) => (
  <Text
    weight='semi-bold'
    tag='span'
    size='xs'
    className={className}
    type={type}
    dataTestId={dataTestId}
  >
    {children}
  </Text>
);
