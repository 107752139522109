import React, { useRef, useCallback } from 'react';

import closePicto from '@caprikorn/dynamo-icons/closeMd.svg';
import { useTheme } from 'src/theme/ThemeProvider';
import { PictoAsButton } from '../../../button/picto/PictoAsButton';

import * as Styled from './Modal.styles';
import { useOnClickOutside } from '../../useOnClickOutside/useOnClickOutside';

export interface IModalProps {
  show: boolean;
  children: React.ReactNode;
  size?: 'small' | 'normal';
  onHide(): void;
  className?: string;
  isClickOutsideEnabled?: boolean;
}

const modalSizes = {
  small: 'lg',
  normal: 'xxl',
} as const;

export const Modal = ({
  show,
  children,
  onHide,
  size = 'normal',
  className = '',
  isClickOutsideEnabled = true,
}: IModalProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const hideModal = useCallback(() => onHide(), [onHide]);
  const theme = useTheme();

  useOnClickOutside(ref, hideModal, isClickOutsideEnabled);

  if (show && !!children) {
    return (
      <Styled.Overlay>
        <Styled.CustomBox
          ref={ref}
          shadow='lg'
          spacing={modalSizes[size]}
          className={className}
        >
          {children}
          <Styled.CloseWrapper small={size === 'small'}>
            <PictoAsButton
              src={closePicto}
              onClick={hideModal}
              size='md'
              dataTestId='Modal-close'
              color={theme.colors.text.primary}
            />
          </Styled.CloseWrapper>
        </Styled.CustomBox>
      </Styled.Overlay>
    );
  }

  return null;
};
