import { ShieldCategories } from './ShieldPage.types';
import { ShieldFormData } from './form/ShieldForm.types';

interface IComputeNoCertificateCases {
  isShieldRejected?: boolean;
  isTariffShieldFormClose?: boolean;
}

export const computeNoCertificateCases = ({
  isShieldRejected,
  isTariffShieldFormClose,
}: IComputeNoCertificateCases) => {
  if (isShieldRejected) {
    return 'notElegible';
  } else if (isTariffShieldFormClose) {
    return 'notPossible';
  } else return 'possible';
};

export const formatShieldData = (formData: ShieldFormData) => ({
  mode: 'onChange',
  customerIds: formData.customerIds,
  siren: formData.siren,
  company: formData.company,
  address: {
    streetNumber: formData.streetNumber,
    street: formData.street,
    postalCode: formData.postalCode,
    city: formData.city,
  },
  mail: formData.mail,
  name: formData.name,
  firstName: formData.firstName,
  signatoryDescription: formData.signatoryDescription,
  phoneNumber: formData.phoneNumber,
  statement: formData.category,
  signatureDate: formData.signatureDate,
  signatureLocation: formData.signatureLocation,
});

export const getStatementNumber = (categoryKey: ShieldCategories) => {
  switch (categoryKey) {
    case 'tpeChoice':
      return '1';
    case 'collectivityChoice':
      return '2';
    case 'pmeChoice':
      return '3';
    case 'moralChoice':
      return '4';
    default:
      return '';
  }
};
