import { useParams } from 'react-router-dom';

import { routes } from 'src/router/RouteConstants';
import arrowLeftPicto from '@caprikorn/dynamo-icons/arrowLeftMd.svg';
import { useShieldCertificates } from 'src/domains/shield/ShieldQueries';
import { CenterContentLayout } from 'src/components/layouts/position/CenterContentLayout';
import { Loader } from 'src/ui/loader/Loader';
import { Header } from 'src/ui/header/Header';
import { ErrorTemplate } from 'src/components/errors/templates/ErrorTemplate';
import { Column } from 'src/ui/Column/Column';
import { ShieldPageContent } from './ShieldPageContent';
import { IURLShield } from './ShieldPage.types';

export const ShieldPage = () => {
  const { customerId } = useParams<IURLShield>();

  const {
    data,
    isFetching,
    isError,
    isSuccess,
  } = useShieldCertificates(customerId as string);

  return (
    <Column>
      <Header
        breadcrumbPaths={[{
          pathName: 'Retour',
          path: `${routes.PERIMETER_CONTRACTS}/${customerId}`,
          pathIcon: arrowLeftPicto,
        }]}
      />
      <Column
        padding={['xl']}
        alignItems='center'
        flex={1}
        overflow='scroll'
      >
        {(isFetching || isError) && (
          <CenterContentLayout>{isError ? <ErrorTemplate imageType='error' /> : <Loader />}</CenterContentLayout>
        )}
        {(!isFetching && isSuccess) && (<ShieldPageContent data={data} />)}
      </Column>
    </Column>
  );
};
