import styled from 'src/theme/ThemeProvider';

export const Label = styled.label<{ contained?: boolean}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  max-width: ${({ contained }) => (contained ? '20px' : '')};
`;

export const Input = styled.input<{ isError?: boolean }>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin: 0;
  &:hover ~ .CheckMark {
    border-color: ${({ theme }) => (theme.colors.actions.buttons.primary)};
  }

  :checked ~ .CheckMark {
    border-color: ${({ theme }) => (theme.colors.actions.buttons.primary)};
  }

  &:disabled ~ .CheckMark {
    background-color: ${({ theme }) => (theme.colors.disable.background)};
    border-color: ${({ theme }) => (theme.colors.disable.text)};
  }
  
  ${({ isError, theme }) => (isError && `
    ~ .CheckMark {
      border-color: ${theme.colors.actions.buttons.danger};
    }
  `)}
`;

// used to modelize the box, not to display any text.
export const BoxCheck = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  border: 2px solid ${({ theme }) => (theme.colors.actions.inputs.primary)};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.sizes.radii.xs}px;
`;
