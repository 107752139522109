import { ICustomError } from './ErrorTypes';

export enum ErrorCategories {
  CHANGE_INITIAL_PASSWORD = 'CHANGE_INITIAL_PASSWORD',
  UNAUTHORIZED = 'UNAUTHORIZED',
  INVALID_CURRENT_PASSWORD = 'INVALID_CURRENT_PASSWORD',
  INDEX_TOO_LOW = 'INDEX_TOO_LOW',
  INDEX_TOO_HIGH = 'INDEX_TOO_HIGH',
}

export enum ErrorCodes {
  NO_CONTENT = 204,
  NOT_FOUND_ERROR = 404,
  REQUEST_ENTITY_TOO_LARGE = 413,
}

// Custom Errors
enum CustomErrorCategories {
  INVALID_DATA = 'INVALID_DATA',
  UNSPECIFIED_ERROR = 'UNSPECIFIED_ERROR',
}

enum CustomErrorCodes {
  UNSPECIFIED_ERROR = 600,
  INVALID_DATA = 601,
}

export const CustomErrors: Record<keyof typeof CustomErrorCategories, ICustomError> = {
  INVALID_DATA: {
    type: CustomErrorCategories.INVALID_DATA,
    message: 'Invalid data',
    status: CustomErrorCodes.INVALID_DATA,
  },
  UNSPECIFIED_ERROR: {
    type: CustomErrorCategories.UNSPECIFIED_ERROR,
    message: 'Unspecified error',
    status: CustomErrorCodes.UNSPECIFIED_ERROR,
  },
} as const;
