import { radii } from './current/radii';

export const sizes = {
  space: {
    xxs: 4,
    xs: 8,
    sm: 12,
    md: 16,
    lg: 24,
    xl: 32,
    xxl: 40,
  },
  border: {
    xs: 1,
    sm: 2,
    md: 4,
    lg: 6,
    xl: 8,
  },
  icons: {
    xs: 16,
    sm: 18,
    md: 24,
    lg: 32,
    xl: 40,
    xxl: 48,
  },
  radii: { ...radii },
};
