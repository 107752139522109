import { useMutation, useQuery } from '@tanstack/react-query';
import { download } from 'src/libs/miscellaneous';
import { DocumentErrorNames, DocumentErrorNamesType, IDocumentDownloadInfo } from 'src/pages/documents/Documents.types';
import { AxiosError, AxiosResponse } from 'axios';
import { fetchGroupDocuments, postDownloadDocumentClient } from './DocumentsClient';
import { useGenerateQueryKey } from '../shared/useGenerateQueryKey';
import { documentQueriesEnum } from './DocumentsKeys';
import { ErrorCodes } from '../error/ErrorConstants';

export const useDocumentsQuery = () => useQuery({
  queryKey: useGenerateQueryKey({ path: documentQueriesEnum.DOC }),
  queryFn: () => fetchGroupDocuments(),
});

export const useDocumentDownloadMutation = (
  onErrorActions: (errorCase: DocumentErrorNamesType) => void,
) => useMutation<
  AxiosResponse, AxiosError, IDocumentDownloadInfo
>({
  mutationFn: ({
    fileName,
    s3VersionID,
    fileExtension,
  }: IDocumentDownloadInfo) => postDownloadDocumentClient(fileName, s3VersionID, fileExtension),
  retry: false,
  mutationKey: useGenerateQueryKey({ path: documentQueriesEnum.DOC }),
  onSuccess: (response, args) => {
    if (response.status === 200) {
      download(args.fileName, response.data);
    }
    if (response.status === ErrorCodes.NO_CONTENT) {
      onErrorActions(DocumentErrorNames.DOCUMENT_EMPTY);
    }
  },
  onError: () => {
    onErrorActions(DocumentErrorNames.DOCUMENT_DOWNLOAD);
  },
});
