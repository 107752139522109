import styled from 'src/theme/ThemeProvider';
import { Typo } from 'src/ui/typography/Typo';

export const Wrapper = styled.main`
  display: flex;
  flex: 1;
  padding: ${({ theme }) => `${theme.sizes.space.md}px`};
  display: flex;
  overflow: hidden;
`;

export const PaginationWrapper = styled.nav`
  padding: ${({ theme }) => `${theme.sizes.space.xs}px`};
  margin-top: auto;
  box-shadow: ${({ theme }) => theme.shadows.lg};
  background: ${({ theme }) => theme.colors.background.elevated};
  position: relative;
  min-height: 60px;

  @media (min-width: 1280px) {
    min-height: auto;
  }
`;

export const CustomParagraph = styled(Typo.Paragraph)`
  text-align: center;
  width: 100%;
`;
