import { MouseEvent } from 'react';
import { Sidebar } from '@caprikorn/dynamo-ui';
import { SidebarItemProps } from '@caprikorn/dynamo-ui/lib/components/navigations/Sidebar/SidebarItem/SidebarItem.types';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'src/theme/ThemeProvider';
import { ReactSvgWrapper } from 'src/ui/pictos/wrappers/ReactSVGWrapper';

type MenuItemProps = Omit<SidebarItemProps<'a'>, 'icon'> & {
  picto: string;
  testName?: string;
  path?: string;
};

export const MenuItem = ({
  picto,
  testName,
  path,
  ...props
}: MenuItemProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const iconLGSize = theme.sizes.icons.lg;
  const icon = () => <ReactSvgWrapper src={picto} size={iconLGSize} />;

  return (
    <Sidebar.Item
      icon={icon}
      isActive={path === window.location.pathname}
      onClick={(e: MouseEvent<HTMLElement>) => {
        if (path) {
          e.preventDefault();
          navigate(path);
        }
      }}
      style={{ padding: theme.sizes.space.xs }}
      data-testid={`test-menu-${testName}`}
      href={path}
      as='a'
      {...props}
    />
  );
};
