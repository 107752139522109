import { Typo } from 'src/ui/typography/Typo';
import texts from 'src/texts/current/shieldPageText.json';
import { Column } from 'src/ui/Column/Column';
import { replaceText } from 'src/libs/text.utils';
import { closingDateTariffShieldForm } from 'src/configuration/utils/feature_flag';

const shieldTexts = texts.shieldPossible;

export const ShieldPossible = () => (
  <Column gap='xxs' padding={[0, 'xxs']}>
    <Typo.Paragraph weight='bold' size='lg'>
      <Typo.Link
        target='_blank'
        rel='noreferrer'
        type='paragraph-lg'
        href={shieldTexts.government.url}
      >
        {shieldTexts.government.website}
      </Typo.Link>
    </Typo.Paragraph>
    <Typo.Paragraph weight='bold' size='lg'>
      {shieldTexts.declareElegibility}
    </Typo.Paragraph>
    <Column
      margin={['auto', 'auto', 'xxs', 'md']}
      height='inherit'
      flexWrap='wrap'
    >
      <ul style={{ whiteSpace: 'nowrap' }}>
        {shieldTexts.conditions.map((condition) => (
          <li key={condition}>
            <Typo.Paragraph key={condition} breakSpaces>
              {replaceText(
                condition,
                { replaceValues: { date: closingDateTariffShieldForm.toLocaleDateString() } },
              )}
            </Typo.Paragraph>
          </li>

        ))}
      </ul>
    </Column>
  </Column>
);
