import { Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { AbsoluteCenterContentLayout } from 'src/components/layouts/position/AbsoluteCenterContentLayout';
import { Loader } from 'src/ui/loader/Loader';
import { AuthPage } from 'src/pages/auth/AuthPage';
import { ErrorBoundary } from 'src/components/errors/ErrorBoundary';
import { isAuthenticated } from 'src/domains/auth/auth.utils';
import { CameleonLogin } from 'src/pages/auth/CameleonLogin';
import { PrivateRouter } from './PrivateRouter';
import { loginRoutes } from './RouteConstants';

export const AppRouter = () => {
  const authenticated = isAuthenticated();
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<AbsoluteCenterContentLayout><Loader /></AbsoluteCenterContentLayout>}>
      <ErrorBoundary pathname={pathname}>
        <Routes>
          <Route path={loginRoutes.AUTH_LOGIN_ROUTER} element={<AuthPage />} />
          <Route path='login-cameleon' element={<CameleonLogin />} />
          <Route path='*' element={<PrivateRouter authenticated={authenticated} />} />
        </Routes>
      </ErrorBoundary>
    </Suspense>
  );
};
