import { getRequest } from 'src/libs/requests';
import { ITicketDetailed, ITicketsWithPagination, filterValues } from './TicketsTypes';
import { concatFilterValues } from './helpers';

const URL = {
  TECH_REQUEST: '/technical-requests',
};

export const getTickets = async (
  page: number,
  size: number,
  filters: filterValues[],
): Promise<ITicketsWithPagination> => {
  const parsedStatusUrl = concatFilterValues(filters);

  const config = {
    method: 'get',
    url: `${URL.TECH_REQUEST}?page=${page}&size=${size}${parsedStatusUrl}`,
  };

  try {
    const { data } = await getRequest<ITicketsWithPagination>(config.url);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTicket = async (ticketId: number): Promise<ITicketDetailed> => {
  const config = {
    method: 'get',
    url: `${URL.TECH_REQUEST}/${ticketId}`,
  };

  try {
    const { data } = await getRequest<ITicketDetailed>(config.url);
    return data;
  } catch (error) {
    throw error;
  }
};
