import { useFormContext } from 'react-hook-form';

import { Input } from 'src/ui/input/basic/Input';
import { Row } from 'src/ui/row/Row';
import texts from 'src/texts/current/shieldPageText.json';
import commonText from 'src/texts/current/commonText.json';
import { Column } from 'src/ui/Column/Column';
import * as Styled from './ShieldForm.styles';

const formTexts = texts.form;

export const SignatoryForm = () => {
  const { register, formState: { errors } } = useFormContext();
  return (
    <Column gap='md' padding={['md', 'xl']}>
      <Row
        gap='md'
        alignItems='center'
        justifyContent='space-evenly'
        flexWrap='wrap'
      >
        <Styled.ShieldFormInput
          htmlFor='signatureDate'
          label={formTexts.inputs.signedOn.label}
          hint={errors.signatureDate && formTexts.errorPrefill}
          required
          isError={!!errors.signatureDate}

        >
          <Input
            disabled
            type='text'
            placeholder={formTexts.inputs.signedOn.placeholder}
            {...register('signatureDate')}
          />
        </Styled.ShieldFormInput>
        <Styled.ShieldFormInput
          htmlFor='signatureLocation'
          label={formTexts.inputs.signedAt}
          hint={errors.signatureLocation && commonText.inputs.commonHint}
          required
          isError={!!errors.signatureLocation}
        >
          <Input
            type='text'
            placeholder={formTexts.inputs.signedAt.replace(' :', '')}
            {...register('signatureLocation')}
          />
        </Styled.ShieldFormInput>
      </Row>
      <Row
        gap='md'
        alignItems='center'
        justifyContent='space-evenly'
        flexWrap='wrap'
      >
        <Styled.ShieldFormInput
          htmlFor='name'
          label={`${commonText.inputs.lastName.label} :`}
          hint={errors.name && commonText.inputs.commonHint}
          required
          isError={!!errors.name}
        >
          <Input
            type='text'
            placeholder={commonText.inputs.lastName.label}
            {...register('name')}
          />
        </Styled.ShieldFormInput>
        <Styled.ShieldFormInput
          htmlFor='firstName'
          label={`${commonText.inputs.firstName.label} :`}
          hint={errors.firstName && commonText.inputs.commonHint}
          required
          isError={!!errors.firstName}
        >
          <Input
            type='text'
            placeholder={commonText.inputs.firstName.label}
            {...register('firstName')}
          />
        </Styled.ShieldFormInput>
        <Styled.ShieldFormInput
          htmlFor='signatoryDescription'
          label={formTexts.inputs.signatoryDescription}
          hint={errors.signatoryDescription && commonText.inputs.commonHint}
          required
          isError={!!errors.signatoryDescription}
        >
          <Input
            type='text'
            placeholder={formTexts.inputs.signatoryDescription.replace(' :', '')}
            {...register('signatoryDescription')}
          />
        </Styled.ShieldFormInput>
      </Row>
    </Column>
  );
};
