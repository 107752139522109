import styled from 'src/theme/ThemeProvider';
import { NavLink } from 'react-router-dom';
import { Typo } from '../typography/Typo';

export const Container = styled.ul`
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.text.md}px;
  font-weight: 400;
  line-height: 24px;

  .BreadcrumbsLink-active {
    font-weight: bold;
    color: ${({ theme }) => `${theme.colors.actions.buttons.primaryHover}`};
  }

  .BreadcrumbsLink-active > span > div > div > svg > path {
    fill: ${({ theme }) => `${theme.colors.actions.buttons.primaryHover}`};
  }
`;

export const CustomNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const CustomSpan = styled(Typo.Span)`
  max-width: 240px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
