import styled from 'src/theme/ThemeProvider';
import { Chip } from 'src/ui/chip/Chip';
import { Title } from 'src/ui/typography/title/Title';

export const TicketWrapper = styled.div`
  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => `${theme.sizes.space.sm}px`};
  }
`;

export const CustomChip = styled(Chip)`
  margin: auto 0;
`;

export const CustomTitle = styled(Title)`
  margin-top: ${({ theme }) => `${theme.sizes.space.xs}px`};
  margin-bottom: ${({ theme }) => `${theme.sizes.space.xs}px`};
`;
