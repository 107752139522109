import { useState } from 'react';

import { DrawerWrapper } from 'src/ui/overlays/drawer/wrapper/DrawerWrapper';

import searchText from 'src/texts/current/searchText.json';
import * as Styled from './SearchDrawer.styles';
import { LevelTypes } from './SearchDrawer.types';
import { SearchDrawerHeader } from './components/SearchDrawerHeader';
import { BillConsolidationForm } from './forms/BillConsolidationForm';
import { PointOfDeliveryForm } from './forms/PointOfDeliveryForm';

interface ISearchDrawerProps {
  show: boolean;
  toggleDrawer(): void;
}

export const SearchDrawer = ({ show, toggleDrawer }: ISearchDrawerProps) => {
  const [level, setLevel] = useState<LevelTypes>('billConsolidation');

  return (
    <DrawerWrapper show={show}>
      <Styled.CustomDrawerContent title={searchText.title} onHide={toggleDrawer}>
        <SearchDrawerHeader level={level} setLevel={setLevel} />
        {{
          pointOfDelivery: <PointOfDeliveryForm toggleDrawer={toggleDrawer} />,
          billConsolidation: <BillConsolidationForm toggleDrawer={toggleDrawer} />,
        }[level]}
      </Styled.CustomDrawerContent>
    </DrawerWrapper>
  );
};
