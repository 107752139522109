import { css } from '@emotion/react';
import styled, { Theme } from 'src/theme/ThemeProvider';
import { commonButtonCss } from 'src/ui/button/basic/Button.styles';
import { ChoiceButtonProps } from './ChoiceButton.types';

const choiceVariant = {
  primary: ({ colors }: Theme) => css`
    background: none;
    color: ${colors.text.primary};
    border-color: ${colors.text.primary};

    &:hover {
      color: ${colors.actions.buttons.primaryHover};
      border-color: ${colors.actions.buttons.primaryHover};
    }
  `,
  danger: ({ colors }: Theme) => css`
    background: none;
    color: ${colors.semantic.error[50]};
  `,
  selected: ({ colors }: Theme) => css`
    background: ${colors.foreground[700]};
    color: ${colors.actions.buttons.primary};
  `,
  disabled: ({ colors }: Theme) => css`
    border-color: ${colors.disable.text};
    color: ${colors.disable.text};
    background: ${colors.disable.background};
  `,
};

export const ChoiceButton = styled.button<ChoiceButtonProps>`
  ${({ size, theme }) => commonButtonCss(theme, size)}
  border: 2px solid;
  cursor: ${({ disabled }) => (disabled ? ' not-allowed' : 'pointer')};
  margin: ${({ theme }) => theme.sizes.space.xxs}px;
  width: ${({ layout }) => (layout === 'inline' ? 'auto' : '100%')};
  ${({
    variant,
    theme,
    isSelected,
    disabled,
  }) => (!isSelected && !disabled) && choiceVariant[variant](theme)}
  ${({ theme, disabled }) => disabled && choiceVariant.disabled(theme)}
  ${({ theme, isSelected }) => isSelected && choiceVariant.selected(theme)}
`;
