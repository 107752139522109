import styled from 'src/theme/ThemeProvider';

export const Form = styled.form`
  text-align: center;
  height: inherit;
`;

export const FormWrapper = styled.div`
  padding-top: ${({ theme }) => theme.sizes.space.lg}px;
  width: 100%;
`;
