import { sizes } from 'src/theme/sizes';
import styled from 'src/theme/ThemeProvider';

const { space } = sizes;
export type SpaceType = keyof typeof space;

export interface ISpacerProps {
  height?: SpaceType;
  width?: SpaceType;
}

const StyledSpacer = styled.span<ISpacerProps>`
  display: block;
  min-height: ${({ theme, height }) => (height ? theme.sizes.space[height] : 0)}px;
  min-width: ${({ theme, width }) => (width ? theme.sizes.space[width] : 0)}px;
`;

export const Spacer: React.FC<ISpacerProps> = ({ height, width }: ISpacerProps) => (
  <StyledSpacer
    aria-hidden
    width={width}
    height={height}
  />
);
