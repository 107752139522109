import { getRequest, postRequest } from 'src/libs/requests';
import {
  ITechRequestData,
  IExportData,
  DocumentType,
  DocumentExtensionType,
  IGroups,
  IGroupDetail,
} from './CommonTypes';
import { convertDescriptionToHtmlString } from './utils/common.utils';

const URL = {
  TECH_REQUEST: '/technical-request',
  EXPORT_DATA: '/export-data/request',
  DOCUMENTS_DOWNLOAD: '/documents/download',
  GET_GROUP: '/groups',
};

export const postTechRequest = async (form: ITechRequestData) => {
  const description = convertDescriptionToHtmlString(form.description);
  const formData = new FormData();

  formData.append('identifier', form.identifier);
  formData.append('description', description);
  formData.append('actionType', form.actionType);
  if (form.dueDate) {
    formData.append('dueDate', form.dueDate);
  }
  if (form.siteId) {
    formData.append('siteId', form.siteId);
  }

  if (form.attachments) {
    form.attachments.forEach((attachment) => formData.append('attachments[]', attachment));
  }

  try {
    return await postRequest(URL.TECH_REQUEST, formData, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw error;
  }
};

export const postRequestExportData = ({
  fileType,
  type,
  dateRange,
  perimeter,
}: IExportData) => {
  try {
    return postRequest(URL.EXPORT_DATA, {
      fileType,
      type,
      dateRange,
      perimeter,
    });
  } catch (error) {
    throw error;
  }
};

export const postDownloadDocument = (
  customerId: string,
  contractId: string,
  documentType: DocumentType,
  extension: DocumentExtensionType,
) => {
  try {
    return postRequest(URL.DOCUMENTS_DOWNLOAD, {
      customerId,
      contractId,
      family: documentType,
      last: true,
      extension,
    }, {
      responseType: 'blob',
    });
  } catch (error) {
    throw error;
  }
};

export const getGroups = async () => {
  try {
    const { data }: {data: {groups: IGroups[]}} = await getRequest(URL.GET_GROUP);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getGroupDetail = async (reference?: string) => {
  try {
    const { data } = await getRequest<IGroupDetail>(`${URL.GET_GROUP}/${reference}`);
    return data;
  } catch (error) {
    throw error;
  }
};
