import { SemanticEnum, SemanticTypes } from 'src/domains/common/CommonTypes';
import { TICKET_STATUS_CODE } from 'src/domains/ticket/TicketsTypes';

export const setTicketStatus = (code: number) => {
  switch (code) {
    case TICKET_STATUS_CODE.OPEN:
      return 'Ouvert';

    case TICKET_STATUS_CODE.WAITING_CLIENT:
      return 'En attente d\'une réponse de votre part';

    case TICKET_STATUS_CODE.SOLVED:
      return 'Résolu';

    case TICKET_STATUS_CODE.CLOSED:
    case TICKET_STATUS_CODE.CLOSED_493:
      return 'Clôturé';

    case TICKET_STATUS_CODE.WAITING_GRD:
      return 'En attente GRD';

    default:
      return 'Status non reconnus';
  }
};

export const setTicketVariant = (code: number): SemanticTypes | undefined => {
  switch (code) {
    case TICKET_STATUS_CODE.OPEN:
    case TICKET_STATUS_CODE.WAITING_CLIENT:
    case TICKET_STATUS_CODE.WAITING_GRD:
      return SemanticEnum.info;

    case TICKET_STATUS_CODE.SOLVED:
    case TICKET_STATUS_CODE.CLOSED:
    case TICKET_STATUS_CODE.CLOSED_493:
      return SemanticEnum.success;

    default:
      return undefined;
  }
};
