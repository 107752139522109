import { Text, TextTypes } from 'src/ui/typography/text/Text';

export interface IParagraphProps {
  children: string | React.ReactNode;
  size?: 'md' | 'lg';
  weight?: 'regular' | 'semi-bold' | 'bold';
  underline?: boolean;
  type?: TextTypes;
  breakSpaces?: boolean;
  className?: string;
}

export const Paragraph: React.FC<IParagraphProps> = ({
  children,
  underline,
  type,
  size = 'md',
  weight = 'regular',
  breakSpaces,
  className,
}: IParagraphProps) => (
  <Text
    tag='p'
    className={className}
    type={type}
    size={size}
    breakSpaces={breakSpaces}
    underline={underline && weight === 'semi-bold'}
    weight={weight}
  >
    {children}
  </Text>
);
