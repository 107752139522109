export const layouts = {
  maxWidth: 1440,
  maxContentWidth: 1080,
  widthFooter: 1260,
  appHeader: 80,
  modals: {
    sm: {
      height: 376,
      width: 528,
    },
  },
};
