import { createContext } from 'react';
import { THEME_VARIANT, TThemeVariant } from './theme.types';

type SwitchThemeFunction = (variant: TThemeVariant | undefined) => void;

interface IThemeContext {
  switchTheme: SwitchThemeFunction;
  themeVariant: TThemeVariant;
}

export const ThemeContext = createContext<IThemeContext>({
  switchTheme: () => true,
  themeVariant: THEME_VARIANT.LIGHT,
});

ThemeContext.displayName = 'ThemeContext';
