import elecPicto from 'src/assets/pictos/elecPicto.svg';
import gasPicto from 'src/assets/pictos/gasPicto.svg';
import { EnergyType, EnergyTypeValues } from 'src/domains/shared/DomainSharedTypes';
import { ITableData } from './PerimeterPage.types';

export const formatEnergyType = (energy?: string): EnergyType => {
  switch (energy) {
    case 'GAS':
      return 'GAS';
    case 'GAZ':
      return 'GAS';
    default:
      return 'ELEC';
  }
};

export const formatEnergyWording = (energy?: string): string => {
  switch (energy) {
    case 'GAS':
      return 'gaz';
    case 'GAZ':
      return 'gaz';
    case 'ELEC':
      return 'électricité';
    default:
      return '';
  }
};

export const renderEnergyPicto = (energyType?: (EnergyType | 'GAZ')) => {
  switch (energyType) {
    case EnergyTypeValues.ELEC:
      return {
        src: elecPicto,
        alt: 'picto électricité',
      };
    case EnergyTypeValues.GAS:
      return {
        src: gasPicto,
        alt: 'picto gaz',
      };
    case 'GAZ':
      return {
        src: gasPicto,
        alt: 'picto gaz',
      };
    default: return undefined;
  }
};

export const setAlreadyCheckedValues = (tableData: ITableData) => tableData?.list.rows.map(
  (row) => row[tableData?.rowIdPosition],
) as string[] | undefined;
