import { AxiosResponse } from 'axios';

import { getRequest } from 'src/libs/requests';
import { IMeterReadingsRequest, IMaximalPowerAction } from './site.types';
import { ISiteDataDetailed } from '../overview/OverviewTypes';
import { SEARCH_OVERVIEW_BASE_URL } from '../PerimeterConstants';

const URL = {
  READINGS: '/exposition-point',
  MAXIMAL_POWER: '/maximal-power',
};

// See https://github.com/Microsoft/TypeScript/issues/12776#issuecomment-265885846
type ReturnType<T> = Promise<AxiosResponse<T>>;
const ReturnType = Promise;

export const fetchMeterReadings = async (
  customerId: string,
  contractId: string,
  siteId: string,
  readingPage?: number,
) => {
  const customer = `?customerId=${customerId}`;
  const contract = `&contractId=${contractId}`;
  const site = `&siteId=${siteId}`;
  const page = readingPage ? `&readingPage=${readingPage}` : '';

  const url = URL.READINGS + customer + contract + site + page;

  try {
    const { data }: { data: IMeterReadingsRequest } = await getRequest(url);

    return data;
  } catch (error) {
    throw error;
  }
};
export const fetchMaximalPower = async (
  customerId: string,
  contractId: string,
  siteId: string,
): ReturnType<IMaximalPowerAction> => {
  const customer = `/${customerId}`;
  const contract = `/${contractId}`;
  const site = `/${siteId}`;

  const url = URL.MAXIMAL_POWER + customer + contract + site;

  try {
    return await getRequest(url);
  } catch (error) {
    throw error;
  }
};

export const fetchSiteOverview = async (
  customerId: string,
  contractId: string,
  siteId: string,
): ReturnType<ISiteDataDetailed> => {
  const url = `${SEARCH_OVERVIEW_BASE_URL}/${customerId}/contracts/${contractId}/sites/${siteId}`;

  try {
    return await getRequest(url);
  } catch (error) {
    throw error;
  }
};
