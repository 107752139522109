import { Dispatch, SetStateAction } from 'react';
import { INewPagingProps } from '../shared/DomainSharedTypes';

export interface ITicketDetailed {
  channel: string;
  createdAt: string;
  description: string;
  descriptionText: string;
  id: number;
  origin: string;
  status: number;
  subject: string;
  type: string;
  updatedAt: string;
}

export interface ITicketBasicType {
  id: number;
  subject: string;
  updatedAt: Date;
  createdAt: Date;
  type: string;
  status: number;
}

export enum TICKET_STATUS_CODE {
  OPEN = 2,
  WAITING_CLIENT = 3,
  SOLVED = 4,
  CLOSED = 5,
  CLOSED_493 = 22,
  WAITING_GRD = 8,
}

export interface ITicketsWithPagination {
  tickets: ITicketBasicType[];
  pagination: INewPagingProps;
}

interface ITicketsDisplayBase {
  isLoading?: boolean;
  tickets?: ITicketBasicType[];
  onClickOnTicket(ticketId?: number): void;
}

export interface ITicketsPage {
  isLoading?: boolean;
  tickets?: ITicketBasicType[];
  setPageSize: Dispatch<SetStateAction<number>> ;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  data?: ITicketsWithPagination;
  pageSize: number;
  filterStatus: filterValues[];
  setFilterStatus: Dispatch<SetStateAction<filterValues[]>>;
}

export interface ITicketsListContainer extends ITicketsDisplayBase {
  currentTicketDisplayed?: number;
}

export interface ITicketWrapperProps {
  ticket: ITicketBasicType;
  currentId?: number | null;
  onClick: (ticket: ITicketBasicType) => void;
}

export interface ITicketsQuery {
  page: number;
  size: number;
  filters: filterValues[];
}

// More statusCode are used but only those are exploited at the moment
export type filterValues = '2' | '3' | '5';
