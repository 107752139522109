/* eslint-disable import/no-duplicates */
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

import { Button } from 'src/ui/button/basic/Button';
import { useShieldFormMutation, useShieldCustomerIds } from 'src/domains/shield/ShieldQueries';
import { InlineLoader } from 'src/ui/loader/InlineLoader';

import { Loader } from 'src/ui/loader/Loader';
import texts from 'src/texts/current/shieldPageText.json';
import commonText from 'src/texts/current/commonText.json';
import { Column } from 'src/ui/Column/Column';
import { getLocalStorageData } from 'src/libs/localStorage.utils';

import { RetryError } from 'src/components/errors/RetryError';
import { CenterContentLayout } from 'src/components/layouts/position/CenterContentLayout';
import * as Styled from './ShieldForm.styles';
import { shieldFormSchema } from './ShieldForm.validation';
import { ShieldFormStatement } from './ShieldFormStatement';
import { ShieldFormCategory } from './ShieldFormCategory';
import { ShieldFormData } from './ShieldForm.types';
import { formatShieldData } from '../shield.utils';
import { CustomerInfoForm } from './CustomerInfoForm';
import { SignatoryForm } from './SignatoryForm';
import { EligibilityForm } from './EligibilityForm';

const formTexts = texts.form;

interface IShieldFormProps {
  siren: string;
  company: string;
}
export const ShieldForm = ({ siren, company }: IShieldFormProps) => {
  const email = getLocalStorageData('email');

  const defVal = {
    siren,
    company,
    mail: email,
    signatureDate: format(new Date(), 'yyyy-LL-dd', { locale: fr }),
    isEligible: false,
  };

  const methods = useForm<ShieldFormData>({
    resolver: yupResolver(shieldFormSchema),
  });
  const {
    handleSubmit,
    reset,
    watch,
  } = methods;

  const isEligible = !!watch('isEligible');

  const {
    isLoading: isLoadingCustomerIds,
    isError: isCustomerIdsListError,
    data: customerIdsList,
    refetch: refetchCustomerIds,
  } = useShieldCustomerIds(siren);

  useEffect(() => {
    if (customerIdsList) {
      reset({
        ...defVal,
        customerIds: customerIdsList,
      });
    }
  }, [customerIdsList]);

  const {
    isPending,
    isError: isFormError,
    mutateAsync,
    reset: resetMutation,
  } = useShieldFormMutation();

  const onSubmit = (formData: ShieldFormData) => {
    const shieldFormData = isEligible ? formatShieldData(formData) : null;
    mutateAsync({ siren, shieldFormData });
  };

  if (isLoadingCustomerIds || !customerIdsList || isFormError || isCustomerIdsListError) {
    return (
      <Column flex={1}>
        <CenterContentLayout>
          {isLoadingCustomerIds
            ? <Loader />
            : (
              <RetryError
                title={commonText.errors.tryAgain}
                text=''
                onRetry={(isCustomerIdsListError || !customerIdsList)
                  ? refetchCustomerIds : resetMutation}
              />
            )}
        </CenterContentLayout>
      </Column>
    );
  }

  return (
    <Column>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Column gap='md' margin={['md', 0]}>
            <Styled.CustomTitle tag='h2'>{formTexts.titles.statement}</Styled.CustomTitle>
            <EligibilityForm />
            {(watch('isEligible')) && (
            <Column gap='xl'>
              <ShieldFormCategory />
              <ShieldFormStatement />
              <Styled.CustomTitle tag='h2'>{formTexts.titles.clientInfos}</Styled.CustomTitle>
              <CustomerInfoForm />
              <Styled.CustomTitle tag='h2'>
                {formTexts.titles.signature}
              </Styled.CustomTitle>
              <SignatoryForm />
            </Column>
            )}
            <Column alignItems='center'>
              <Button
                onClick={handleSubmit(onSubmit)}
                variant='primary'
                disabled={isPending}
                type='submit'
              >
                {isPending ? <InlineLoader /> : commonText.buttons.save}
              </Button>
            </Column>
          </Column>
        </FormProvider>
      </form>
    </Column>
  );
};
