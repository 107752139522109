import { CenterContentLayout } from 'src/components/layouts/position/CenterContentLayout';

import { ITicketBasicType, ITicketsListContainer } from 'src/domains/ticket/TicketsTypes';
import ticketPageText from 'src/texts/current//ticketPageText.json';
import { Loader } from 'src/ui/loader/Loader';

import { Column } from 'src/ui/Column/Column';
import * as Styled from '../TicketsContent.styles';
import { TicketCardWrapper } from './TicketCardWrapper';

export const TicketsListContainer = ({
  isLoading,
  tickets,
  currentTicketDisplayed,
  onClickOnTicket,
}: ITicketsListContainer) => {
  if (isLoading) {
    return (
      <CenterContentLayout>
        <Loader />
      </CenterContentLayout>
    );
  }

  if (tickets?.length === 0) {
    return (
      <Styled.CustomParagraph weight='bold'>
        {ticketPageText.empty}
      </Styled.CustomParagraph>
    );
  }

  return (
    <Column
      flex={1}
      padding={[0, 'sm', 0, 0]}
      overflow='auto'
    >
      {tickets?.map((ticket: ITicketBasicType) => (
        <TicketCardWrapper
          key={ticket.id}
          ticket={ticket}
          currentId={currentTicketDisplayed}
          onClick={() => onClickOnTicket(ticket.id)}
        />
      ))}
    </Column>
  );
};
