import styled from 'src/theme/ThemeProvider';

export const StyledDiv = styled.div`
  position: relative;
  display: flex;
  flex: 0;
  padding-left: 14px;
`;

export const StyledLoaderWrapper = styled.div<{ processing: boolean }>`
  position: absolute;
  display: ${({ processing }) => (processing ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 100%;
`;
