import { Typo } from 'src/ui/typography/Typo';

export interface IInputDescriptionProps {
  description?: string;
  className?: string;
  isError?: boolean;
  disabled?: boolean;
}

const renderSpanType = ({ isError, disabled }: {isError?: boolean, disabled?: boolean }) => {
  if (isError) { return 'error'; }
  if (disabled) { return 'disabled'; }
  return 'secondary';
};

export const InputDescription = ({
  description,
  isError,
  className,
  disabled,
}: IInputDescriptionProps) => (
  <Typo.Caption
    className={className}
    type={renderSpanType({ isError, disabled })}
  >
    {description}
  </Typo.Caption>
);
