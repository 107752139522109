import { EnergyType } from '../shared/DomainSharedTypes';

export const enum perimeterQueriesEnum {
  CONSO = 'CONSO',
  CONTACT = 'CONTACT',
  CUSTOMERS_CONTACT = 'CUSTOMERS_CONTACT',
  CUSTOMERS_BY_GROUP = 'CUSTOMERS_BY_GROUP',
  CONTRACTS_BY_CUSTOMER = 'CONTRACTS_BY_CUSTOMER',
  SITES_CONTACT = 'SITES_CONTACT',
  SITE_DETAILS_MAX_POWER = 'SITE_DETAILS_MAX_POWER',
  SITE_DETAILS_LAST_STATEMENTS = 'SITE_DETAILS_LAST_STATEMENTS',
  SITE_DETAILS_LAST_STATEMENTS_NO_PAGES = 'SITE_DETAILS_LAST_STATEMENTS_NO_PAGES',
  SITE_DETAILS_ARC = 'SITE_DETAILS_ARC',
  SITE_DETAILS_OVERVIEW = 'SITE_DETAILS_OVERVIEW',
  PERIMETER_EXTRACT_REQUEST = 'PERIMETER_EXTRACT_REQUEST',
  PERIMETER_EXTRACT_STATUS = 'PERIMETER_EXTRACT_STATUS',
  PERIMETER_EXTRACT_DOWNLOAD = 'PERIMETER_EXTRACT_DOWNLOAD',
}

export const perimeterKeysEnum = {
  [perimeterQueriesEnum.CONSO]: (
    energy: EnergyType,
    customerId?: string,
    contractId?: string,
    siteId?: string,
  ) => [
    perimeterQueriesEnum.CONSO,
    energy,
    customerId,
    contractId,
    siteId,
  ],
  [perimeterQueriesEnum.CUSTOMERS_CONTACT]: (
    customerId?: string,
  ) => [
    perimeterQueriesEnum.CONTACT,
    perimeterQueriesEnum.CUSTOMERS_CONTACT,
    customerId,
  ],
  [perimeterQueriesEnum.CUSTOMERS_BY_GROUP]: [perimeterQueriesEnum.CUSTOMERS_BY_GROUP],
  [perimeterQueriesEnum.CONTRACTS_BY_CUSTOMER]: (
    customerId: string,
  ) => [
    perimeterQueriesEnum.CONTRACTS_BY_CUSTOMER,
    customerId,
  ],
  [perimeterQueriesEnum.SITES_CONTACT]: (
    customerId?: string,
    contractId?: string,
  ) => [
    perimeterQueriesEnum.CONTACT,
    perimeterQueriesEnum.SITES_CONTACT,
    customerId,
    contractId,
  ],
  [perimeterQueriesEnum.SITE_DETAILS_OVERVIEW]: (
    customerId: string,
    contractId: string,
    siteId: string,
  ) => [
    perimeterQueriesEnum.SITE_DETAILS_OVERVIEW,
    perimeterQueriesEnum.CONTACT,
    perimeterQueriesEnum.SITES_CONTACT,
    customerId,
    contractId,
    siteId,
  ],
  [perimeterQueriesEnum.SITE_DETAILS_MAX_POWER]: (
    customerId: string,
    contractId: string,
    siteId: string,
  ) => [
    perimeterQueriesEnum.SITE_DETAILS_MAX_POWER,
    customerId,
    contractId,
    siteId,
  ],
  [perimeterQueriesEnum.SITE_DETAILS_LAST_STATEMENTS]: (
    customerId: string,
    contractId: string,
    siteId: string,
    readingPage: number,
  ) => [
    perimeterQueriesEnum.SITE_DETAILS_LAST_STATEMENTS,
    customerId,
    contractId,
    siteId,
    readingPage,
  ],
  [perimeterQueriesEnum.SITE_DETAILS_LAST_STATEMENTS_NO_PAGES]: (
    customerId: string,
    contractId: string,
    siteId: string,
  ) => [
    perimeterQueriesEnum.SITE_DETAILS_LAST_STATEMENTS,
    customerId,
    contractId,
    siteId,
  ],
  [perimeterQueriesEnum.SITE_DETAILS_ARC]: [perimeterQueriesEnum.SITE_DETAILS_ARC],
  [perimeterQueriesEnum.PERIMETER_EXTRACT_REQUEST]: [
    perimeterQueriesEnum.PERIMETER_EXTRACT_REQUEST,
  ],
  [perimeterQueriesEnum.PERIMETER_EXTRACT_STATUS]: [
    perimeterQueriesEnum.PERIMETER_EXTRACT_STATUS,
  ],
  [perimeterQueriesEnum.PERIMETER_EXTRACT_DOWNLOAD]: (
    filename: string,
  ) => [
    perimeterQueriesEnum.PERIMETER_EXTRACT_DOWNLOAD,
    filename,
  ],
} as const;
