import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import { CenterContentLayout } from 'src/components/layouts/position/CenterContentLayout';

import { ErrorNotFound } from 'src/components/errors/ErrorNotFound';

const PerimeterPage = lazy(() => import('src/pages/perimeter/PerimeterPage'));
const PerimeterPageCustomersProvider = lazy(() => import('src/pages/perimeter/customers/PerimeterPageCustomersProvider'));
const PerimeterPageContractsProvider = lazy(() => import('src/pages/perimeter/contracts/PerimeterPageContractsProvider'));
const PerimeterPageSitesProvider = lazy(() => import('src/pages/perimeter/sites/PerimeterPageSitesProvider'));
const PerimeterPageSiteDetail = lazy(() => import('src/pages/perimeter/site_details/PerimeterPageSiteDetail'));

export const PerimeterRouter = () => (
  <Suspense fallback={null}>
    <Routes>
      <Route path='/' element={<PerimeterPage />}>
        <Route path='/' element={<PerimeterPageCustomersProvider />} />

        <Route path='/regroupement/:customerId?' element={<PerimeterPageContractsProvider />} />

        <Route path='/sites/:customerId/:contractId' element={<PerimeterPageSitesProvider />} />
        <Route path='/sites' element={<PerimeterPageSitesProvider />} />

        <Route path='/site/:customerId/:contractId/:siteId/:energyType' element={<PerimeterPageSiteDetail />} />
      </Route>

      <Route
        path='*'
        element={(
          <CenterContentLayout>
            <ErrorNotFound />
          </CenterContentLayout>
          )}
      />
    </Routes>
  </Suspense>
);
