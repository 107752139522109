import { AxiosResponse } from 'axios';
import { getRequest, postRequest } from 'src/libs/requests';
import { IDocumentInformation } from 'src/pages/documents/Documents.types';
import { DocumentExtensionType, DocumentExtensionTypeXls } from '../common/CommonTypes';

const URL = {
  GROUP_DOCUMENTS: '/documents/group/search',
  CLIENT_DOCUMENT_DOWNLOAD: '/documents/group/download',
} as const;

export const fetchGroupDocuments = async (): Promise<AxiosResponse<
  { groupDocuments: IDocumentInformation[] }
>> => {
  try {
    return await getRequest(URL.GROUP_DOCUMENTS);
  } catch (error) {
    throw error;
  }
};

export const postDownloadDocumentClient = (
  fileName: string,
  s3VersionId: string,
  fileExtension: DocumentExtensionType | DocumentExtensionTypeXls,
) => {
  try {
    return postRequest(URL.CLIENT_DOCUMENT_DOWNLOAD, {
      refDocument: fileName,
      s3VersionId,
      extension: fileExtension,
    }, {
      responseType: 'blob',
    });
  } catch (error) {
    throw error;
  }
};
