import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from 'src/domains/auth/auth.utils';

import { routes } from 'src/router/RouteConstants';

export const useLoginRedirect = () => {
  const navigate = useNavigate();
  const authenticated = isAuthenticated();

  useEffect(() => {
    if (authenticated) {
      navigate(routes.DASHBOARD);
    }
  }, [authenticated]);
};
