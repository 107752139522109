import { colors } from './colors';
import { fontWeights } from './fontWeights';
import { fontFamily } from './fontFamily';

// ts-prune-ignore-next
export default {
  name: 'ekwateur',
  colors,
  fontWeights,
  fontFamily,
};
