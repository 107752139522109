export enum EnergyTypeValues {
  ELEC = 'ELEC',
  GAS = 'GAS',
}

export type EnergiesWordingTypes = 'Électricité' | 'Gaz';

export type EnergyType = keyof typeof EnergyTypeValues;

// TODO: Deprecate this type when back is not using it
export interface IPagingProps {
  currentPage: number;
  totalElements: number;
  totalPages: number;
}

export interface INewPagingProps {
  recordsCount: number;
  currentPage: number;
  size: number;
}
