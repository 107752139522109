import {
  QueryObserverOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { download } from 'src/libs/miscellaneous';
import { useContext } from 'react';
import { GroupReferenceContext } from 'src/contexts/GroupReferences.context';
import {
  getGroupDetail,
  postDownloadDocument,
  postRequestExportData,
  postTechRequest,
} from './CommonClient';
import {
  IBillsMutationArgs,
  IExportData,
  IGroupDetail,
  ITechRequestData,
} from './CommonTypes';
import { commonQueriesEnum } from './CommonKeys';
import {
  useGenerateQueryKey,
  useGenerateQueryKeyNotStrict,
} from '../shared/useGenerateQueryKey';
import { ticketsQueriesEnum } from '../ticket/TicketKeys';
import { GROUP_ID_LENGTH } from '../shared/constants';

export const useBillsDownloadMutation = () => useMutation<
  AxiosResponse,
  AxiosError,
  IBillsMutationArgs
>({
  mutationFn: ({ customerId, contractId, extension }: IBillsMutationArgs) => postDownloadDocument(customerId, contractId, 'invoice', extension),
  mutationKey: useGenerateQueryKey({
    path: commonQueriesEnum.BILLS_DOWNLOAD,
  }),
  onSuccess: (response, args) => {
    if (response.status === 200) {
      download(`invoice_${args.contractId}.${args.extension}`, response.data);
    }
  },
});

export const useTechRequestMutation = (onSettledFunction: () => void) => {
  const queryClient = useQueryClient();
  const queryKeyToInvalidate = useGenerateQueryKeyNotStrict(
    [ticketsQueriesEnum.GET_TICKETS],
    false,
  );

  return useMutation({
    mutationFn: (form: ITechRequestData) => postTechRequest(form),
    retry: false,
    mutationKey: useGenerateQueryKey({ path: commonQueriesEnum.TECH_REQUEST }),
    onSuccess: () => queryClient.invalidateQueries({
      queryKey: queryKeyToInvalidate,
      refetchType: 'active',
    }),
    onSettled: () => onSettledFunction(),
  });
};

export const useRequestExportDataMutation = () => useMutation<
AxiosResponse,
AxiosError,
IExportData
>({
  mutationFn: (dataToExport: IExportData) => postRequestExportData(dataToExport),
  mutationKey: useGenerateQueryKey({
    path: commonQueriesEnum.EXPORT_DATA_REQUEST,
  }),
});

export const useGroupDetailsQuery = <SelectType = IGroupDetail>(props?: {
  options?: QueryObserverOptions<IGroupDetail, AxiosError, SelectType>;
}) => {
  const { currentGroup: storedGroupRef } = useContext(GroupReferenceContext);

  return useQuery({
    queryKey: useGenerateQueryKey({ path: commonQueriesEnum.GROUP_DETAIL }),
    queryFn: () => getGroupDetail(storedGroupRef),
    ...props?.options,
    enabled: storedGroupRef.length === GROUP_ID_LENGTH,
  });
};
