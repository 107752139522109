import React, { ErrorInfo } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import errorsText from 'src/texts/current/errorsText.json';

import { getLocalStorageData } from 'src/libs/localStorage.utils';
import { RetryError } from './RetryError';

type ErrorState = {
  hasError: boolean;
  chunkError: boolean;
};

type ErrorProps = {
  children?: React.ReactNode;
  pathname: string;
};

export class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps | Readonly<ErrorProps>) {
    super(props);
    this.state = { hasError: false, chunkError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, chunkError: error.message.includes('fetch dynamically imported module') };
  }

  componentDidUpdate(prevProps: ErrorProps) {
    const { pathname } = this.props;
    if (prevProps.pathname !== pathname) {
      this.setState(() => ({ hasError: false }));
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { chunkError } = this.state;
    if (!chunkError) {
      const boundary = new Error('error boundary ecms');

      const user = getLocalStorageData('email');
      // TODO: this is temporary until we can do better
      datadogRum.setUser({
        id: user,
        email: user,
        name: user,
      });
      datadogRum.addError(boundary, { error, errorInfo });
    }
  }

  render() {
    const { hasError, chunkError } = this.state;
    const { children } = this.props;
    const title = chunkError ? errorsText.chunkError.title : errorsText.default.title.large;
    const btnText = chunkError ? errorsText.chunkError.buttonText : errorsText.boundary;

    if (hasError) {
      return (
        <div
          style={{
            display: 'flex', flex: 1, minHeight: '100vh', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <RetryError
            title={title}
            text=''
            onRetryButtonText={btnText}
            onRetry={() => window.location.reload()}
            titleTag='h1'
            titleStyle='h3'
          />
        </div>
      );
    }
    return children;
  }
}
