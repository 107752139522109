import { Box } from 'src/ui/box/Box';
import { Text } from 'src/ui/typography/text/Text';
import { useTheme } from 'src/theme/ThemeProvider';
import informationPicto from '@caprikorn/dynamo-icons/circleInfo.svg';
import checkPicto from '@caprikorn/dynamo-icons/check.svg';
import { Spacer } from 'src/ui/spacer/Spacer';
import { ReactSvgWrapper } from '../pictos/wrappers/ReactSVGWrapper';
import { Row } from '../row/Row';

export interface IAlertProps {
  children: string | React.ReactNode;
  type: 'warning' | 'success' | 'info';
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

export const Alert: React.FC<IAlertProps> = ({
  children,
  type,
  size = 'md',
  className,
}: IAlertProps) => {
  const { colors, sizes } = useTheme();
  const pictoSizes = {
    sm: sizes.icons.xs,
    md: sizes.icons.sm,
    lg: sizes.icons.md,
  };

  return (
    <Box
      backgroundColor='background'
      borderColor={type}
      className={className}
      spacing={size}
      radius='xs'
    >
      <Row justifyContent='center' alignItems='center'>
        <ReactSvgWrapper
          src={type === 'warning' ? informationPicto : checkPicto}
          color={colors.semantic[type][50]}
          size={pictoSizes[size].toString()}
        />
        <Spacer width='xs' />
        <Text
          size={size}
          weight='regular'
          tag='span'
        >
          {children}
        </Text>
      </Row>
    </Box>
  );
};
