import { replaceText } from 'src/libs/text.utils';
import documentsText from 'src/texts/current/documentsText.json';
import { perimeterExtractStatus } from 'src/domains/perimeter/PerimeterTypes';
import redo from '@caprikorn/dynamo-icons/redo.svg';
import loading from '@caprikorn/dynamo-icons/loadingRound.svg';
import download from '@caprikorn/dynamo-icons/download.svg';
import close from '@caprikorn/dynamo-icons/closeMd.svg';
import checkBig from '@caprikorn/dynamo-icons/checkBig.svg';
import folderClose from '@caprikorn/dynamo-icons/folderClose.svg';
import { SemanticEnum } from 'src/domains/common/CommonTypes';
import { IExtractStatusDataHandling, IGenerateExtractRow } from './Extract.types';
import { ExtractPerimeterDownloadColumn } from './ExtractPerimeterDownloadColumn';

const text = documentsText.table.extractPerimeter;

const extractStatusDataHandling = ({
  extractStatus,
  generateExtractMutation,
  downloadExtractMutation,
}: IExtractStatusDataHandling) => {
  switch (extractStatus) {
    case perimeterExtractStatus.EMPTY:
      return {
        label: text.chipStatus.empty,
        picto: folderClose,
        status: SemanticEnum.info,
        buttonText: text.buttonText.empty,
        buttonPicto: redo,
        onClickFn: () => generateExtractMutation(),
      };
    case perimeterExtractStatus.DONE:
      return {
        label: text.chipStatus.done,
        picto: checkBig,
        status: SemanticEnum.success,
        buttonText: text.buttonText.done,
        buttonPicto: download,
        onClickFn: () => downloadExtractMutation(),
      };
    case perimeterExtractStatus.IN_PROGRESS:
      return {
        label: text.chipStatus.inProgress,
        picto: loading,
        status: SemanticEnum.warning,
      };
    default:
      return {
        label: text.chipStatus.error,
        picto: close,
        status: SemanticEnum.error,
        buttonText: text.buttonText.error,
        buttonPicto: redo,
        onClickFn: () => generateExtractMutation(),
      };
  }
};

export const generateExtractRow = ({
  generateExtractMutation,
  downloadExtractMutation,
  extractStatus,
  groupName,
}: IGenerateExtractRow) => {
  if (!extractStatus) {
    return ({
      rowId: 'extractPerimeterRow',
      rowItems: [],
    }
    );
  }
  const data = extractStatusDataHandling({
    extractStatus,
    generateExtractMutation,
    downloadExtractMutation,
  });
  return ({
    rowId: 'extractPerimeterRow',
    rowItems: [
      replaceText(
        documentsText.table.extractPerimeter.name,
        { replaceValues: { groupName: groupName ?? '' } },
      ),
      <ExtractPerimeterDownloadColumn key={data.label} {...data} />,
    ],
  });
};
