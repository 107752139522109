import { ITextProps } from 'src/ui/typography/text/Text';

type TextPropsOmitted = Omit<ITextProps, | 'tag' | 'size' | 'underline' | 'breakSpaces'>;

export interface ILabelProps extends TextPropsOmitted {
  htmlFor: string;
}

export const Label: React.FC<ILabelProps> = ({
  htmlFor,
  className,
  children,
  weight,
  type,
  dataTestId,
}: ILabelProps) => {
  const cx = [
    'TypoCommon',
    'TextsCommon-sm',
    `TextsCommon-${weight}`,
    `TypoCommon-${type}`,
    className,
  ];

  return (
    <label
      className={cx.join(' ')}
      htmlFor={htmlFor}
      data-testid={dataTestId}
    >
      {children}
    </label>
  );
};
