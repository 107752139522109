export enum TimeDuration {
  ONE_SECOND = 1000,
  ONE_MINUTE = ONE_SECOND * 60,
  ONE_HOUR = ONE_MINUTE * 60,
  ONE_DAY = ONE_HOUR * 24,
}

export const defaultQueryOptions = {
  staleTime: TimeDuration.ONE_MINUTE,
  retry: false,
  refetchOnWindowFocus: false,
};
