import { Spacer } from 'src/ui/spacer/Spacer';
import { IMAGES_URL } from 'src/assets/images/current/images_config';
import { ImgWrapper } from 'src/ui/pictos/wrappers/ImgWrapper';
import * as Styled from './VerticalBackgroundLayout.styles';

interface IVerticalBackgroundLayoutProps {
  children: React.ReactNode;
}

export const VerticalBackgroundLayout = ({ children }: IVerticalBackgroundLayoutProps) => (
  <Styled.Main>
    <Spacer height='xl' />
    <Spacer height='xxl' />
    <ImgWrapper
      src={IMAGES_URL.AUTH_LOGO}
      alt='logo'
      size={54}
    />
    <Spacer height='xl' />
    {children}
  </Styled.Main>
);
