import { AxiosResponse } from 'axios';
import { getRequest, postRequest } from 'src/libs/requests';
import { ICompanyStatements, IPostShieldStatement } from './ShieldTypes';

const URL = {
  SHIELD_COMPANIES: '/companies',
  SHIELD_SIREN_LIST: '/customers',
};

const FORM_TYPES = {
  TYPE: 'PRICE_SHIELD_2024_PRO',
  VERSION_TYPES: 1,
};

export const fetchShieldCertificates = async (
  customerId: string,
): Promise<AxiosResponse<ICompanyStatements>> => {
  try {
    return await getRequest(`${URL.SHIELD_COMPANIES}/${customerId}/statement`);
  } catch (error) {
    throw error;
  }
};

export const fetchShieldCustomerIds = async (
  siren: string,
): Promise<AxiosResponse<{customersBySiren: string[]}>> => {
  try {
    return await getRequest(`${URL.SHIELD_SIREN_LIST}/${siren}`);
  } catch (error) {
    throw error;
  }
};

export const postShieldForm = async ({ siren, shieldFormData }: IPostShieldStatement) => {
  const payload = {
    type: FORM_TYPES.TYPE,
    statementTypeVersion: FORM_TYPES.VERSION_TYPES,
    customerRefusal: !shieldFormData,
    data: shieldFormData,
  };

  try {
    return await postRequest(`${URL.SHIELD_COMPANIES}/${siren}/statement`, payload);
  } catch (error) {
    throw error;
  }
};
