import React from 'react';

import theme from 'src/theme';

import { IconContainer } from '../icon/IconContainer';

import * as Styled from './PrefixedSuffixedInput.styles';
import { ISuffixedPrefixedInput } from './PrefixedSuffixedInput.types';

export const PrefixedSuffixedInput = React.forwardRef<HTMLInputElement, ISuffixedPrefixedInput>(({
  type,
  placeholder,
  name,
  dataTestId,
  isError,
  disabled,
  className,
  onChange,
  onBlur,
  ddPrivacyAllow,
  suffixProps,
  prefixProps,
  pictoSize = theme.sizes.icons.md,
}: ISuffixedPrefixedInput, ref) => (
  <Styled.Container>
    {prefixProps && (
    <IconContainer
      content={prefixProps.content}
      disabled={disabled}
      isPrefixed
      onClick={prefixProps?.onClick}
      dataTestId={prefixProps?.dataTestId}
      pictoSize={pictoSize}
      cursor={prefixProps.cursor}
    />
    )}
    <Styled.CustomInput
      className={className}
      onChange={onChange}
      onBlur={onBlur}
      type={type}
      placeholder={placeholder}
      name={name}
      isError={isError}
      ref={ref}
      data-testid={dataTestId}
      data-dd-privacy={ddPrivacyAllow}
      disabled={disabled}
      min={0}
      isPrefixed={!!prefixProps}
      isSuffixed={!!suffixProps}
      pictoSize={pictoSize}
    />
    {suffixProps && (
      <IconContainer
        content={suffixProps.content}
        disabled={disabled}
        onClick={suffixProps?.onClick}
        dataTestId={suffixProps?.dataTestId}
        onMouseDown={suffixProps?.onMouseDown}
        onMouseUp={suffixProps?.onMouseUp}
        onMouseLeave={suffixProps?.onMouseLeave}
        pictoSize={pictoSize}
        cursor={suffixProps.cursor}

      />
    )}
  </Styled.Container>
));

// Prevent eslint isError: Component definition is missing display name  react/display-name
PrefixedSuffixedInput.displayName = 'PrefixedSuffixedInput';
