import { Title } from 'src/ui/typography/title/Title';

import { Column } from 'src/ui/Column/Column';
import * as Styled from './AuthContentTemplate.styles';

interface IAuthContentTemplateProps {
  children: React.ReactNode;
  title: string;
}

export const AuthContentTemplate = ({ children, title }: IAuthContentTemplateProps) => (
  <Column
    gap='xl'
    alignItems='center'
  >
    <Title tag='h1'>
      {title}
    </Title>
    <Styled.CustomCard>
      <Column
        padding={['md']}
        alignItems='center'
        data-testid='test-box-content'
      >
        {children}
      </Column>
    </Styled.CustomCard>
  </Column>
);
