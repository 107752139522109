import { css } from '@emotion/react';

import styled, { Theme } from 'src/theme/ThemeProvider';
import { ISharedButtonProps, TButtonSize } from './Button.types';

export const BUTTON_HEIGHT = {
  sm: 32,
  md: 48,
  lg: 56,
} as const;

export const ICON_SIZE = {
  sm: 16,
  md: 18,
  lg: 24,
} as const;

const PADDING_SIZE = {
  sm: 12,
  md: 16,
  lg: 24,
} as const;

const buttonVariants = {
  primary: ({ colors }: Theme) => css`
    background: ${colors.actions.buttons.primary};
    color: ${colors.text.reverse};

    &:hover {
      background: ${colors.actions.buttons.primaryHover};
      border-color: ${colors.actions.buttons.primaryHover};
    }
`,
  danger: ({ colors }: Theme) => css`
   background: ${colors.actions.buttons.danger};
   color: ${colors.text.reverse};

    &:hover {
      background: ${colors.actions.buttons.dangerHover};
      border-color: ${colors.actions.buttons.dangerHover};
    }
  `,
  outline: {
    primary: ({ colors }: Theme) => css`
      background: none;
      color: ${colors.actions.buttons.primary};

      &:hover {
        color: ${colors.actions.buttons.primaryHover};
        border-color: ${colors.actions.buttons.primaryHover};
      }
    `,
    danger: ({ colors }: Theme) => css`
      background: none;
      color: ${colors.actions.buttons.danger};

      &:hover {
        color: ${colors.actions.buttons.dangerHover};
      }
    `,
  },
  disabled: {
    outline: ({ colors }: Theme) => css`
      border-color: ${colors.disable.text};
      color: ${colors.disable.text};
      cursor: not-allowed;

      &:hover {
        border-color: ${colors.disable.text};
        color: ${colors.disable.text};
      }
    `,
    default: ({ colors }: Theme) => css`
      background: ${colors.disable.background};
      border-color: ${colors.disable.background};
      color: ${colors.disable.text};
      cursor: not-allowed;

      &:hover {
        background: ${colors.disable.background};
        border-color: ${colors.disable.background};
        color: ${colors.disable.text};
      }
    `,
  },
};

export const commonButtonCss = (theme: Theme, size: TButtonSize = 'md') => css`
  font-family: ${theme.fontFamily.title};
  font-weight: ${theme.fontWeights.semiBold};
  cursor: pointer;
  border-radius: ${theme.sizes.radii.xl}px;
  white-space: nowrap;
  outline: none;
  height: ${BUTTON_HEIGHT[size]}px;
  padding: 0px ${(PADDING_SIZE[size] - 2)}px; // Minus border to fit DS padding size
  font-size: ${theme.fontSizes.cta[size]}px;
  line-height:  ${theme.fontSizes.cta[size]}px;
  transition: background .2s, color .2s, border-color .2s;
`;

export const Button = styled.button<ISharedButtonProps>`
  ${({ size, theme }) => commonButtonCss(theme, size)}
  
  border: 2px solid ${({ variant, theme }) => theme.colors.actions.buttons[variant]};

  ${({ variant, outline, theme }) => !outline && buttonVariants[variant](theme)}
  ${({ variant, outline, theme }) => outline && buttonVariants.outline[variant](theme)}

  ${({ outline, disabled, theme }) => (disabled && !outline) && buttonVariants.disabled.default(theme)}
  ${({ outline, disabled, theme }) => (disabled && outline) && buttonVariants.disabled.outline(theme)}
`;
