import { css } from '@emotion/react';

const GlobalCSSTheme = css`
  :root {
    --fontFamilyTitle: Carnero, sans-serif;
    --fontFamilyText: GothicA1, sans-serif;

    --colorTextPrimary: #0F1A1C;
    --colorTextSecondary: #2A4B50;
    --colorTextReverse: #F8FBFB;
    
    --colorDisableText: #404040;
    --colorDisableBackground: #E6E6E6;
    --colorDisableLoading: #D9D9D9;

    --colorButtonPrimary: #053832;
    --colorButtonPrimaryHover: #0A665C;

    --colorSemanticInfo: #1D57AF;
    --colorSemanticSucess: #2F6A2F;
    --colorSemanticWarning: #934801;
    --colorSemanticError: #AD2000;

    --appBackgroundPrimary: #EEF5F6;
    --appBackgroundElevated: #F8FBFB;
    --appForeground800: #EEF5F6;
    --appForeground600: #CDE1E4;

    --specificTooltipBg: #EEF5F6;
    --specificTooltipText: #0F1A1C;
    

    --transparancy300: rgba(17, 18, 19, 0.5);

    --sizeBorderRadiusXs: 4px;
    --sizeBorderRadiusMd: 16px;
    --sizeBorderRadiusXl: 32px;
  }


  [data-theme="dark"] {
    --colorTextPrimary: #F8FBFB;
    --colorTextSecondary: #BCD7DC;
    --colorTextReverse: #0F1A1C;
    
    --colorDisableText: #E6E6E6;
    --colorDisableBackground: #404040;
    --colorDisableLoading: #808080;

    --colorButtonPrimary: #12B5A3;
    --colorButtonPrimaryHover: #74F1E3;

    --colorSemanticInfo: #91B5ED;
    --colorSemanticSucess: #80C684;
    --colorSemanticWarning: #FDB935;
    --colorSemanticError: #EBA594;

    --appBackgroundPrimary: #0F1A1C;
    --appBackgroundElevated: #132527;
    --appForeground800: #132527;
    --appForeground600: #1E4247;

    --specificTooltipBg: #BCD7DC;
    --specificTooltipText: #0F1A1C;
  }

  html {
    font-family: GothicA1, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: Carnero, sans-serif;
  }
`;

// ts-prune-ignore-next
export default GlobalCSSTheme;
