/* eslint-disable react/no-array-index-key */

import * as Styled from './Skeleton.styles';

export interface ISkeletonProps {
  numberOfLines?: number;
  className?: string;
}

export const Skeleton = ({
  numberOfLines = 1,
  className,
}: ISkeletonProps) => (
  <Styled.SkeletonContainer data-testid='Skeleton' className={className}>
    {new Array(numberOfLines)
      .fill('').map((_, index) => <Styled.SkeletonItem key={index} />)}
  </Styled.SkeletonContainer>
);
