import { Title } from 'src/ui/typography/title/Title';
import { Typo } from 'src/ui/typography/Typo';

import texts from 'src/texts/current/shieldPageText.json';

import { Column } from 'src/ui/Column/Column';
import { InfoItem } from 'src/ui/infos/InfoItem';
import { Row } from 'src/ui/row/Row';
import styled from 'src/theme/ThemeProvider';
import { replaceText } from 'src/libs/text.utils';
import { closingDateTariffShieldForm } from 'src/configuration/utils/feature_flag';

const sources = texts.legalIntro.legalSources;
const { definitions } = texts.legalIntro;

const LiWithMargin = styled.li`
  margin-bottom: ${({ theme }) => theme.sizes.space.md}px;
`;

export const ShieldLegalIntro = () => (
  <Column
    gap='xs'
    flex={1}
    padding={['xl']}
  >
    <Title tag='h1' titleStyle='h2'>
      {texts.title}
    </Title>
    <Column gap='xs'>
      <InfoItem name={sources.generalText.label} value={sources.generalText.name} />
      <Typo.Paragraph weight='bold'>
        {sources.decree}
      </Typo.Paragraph>
      <InfoItem name={sources.nor.label} value={sources.nor.name} />
    </Column>
    <Column>
      <ol>
        <LiWithMargin>
          {definitions.usefullLinks.map((item) => (
            <Row key={item.link} flexWrap='wrap'>
              <Typo.Paragraph breakSpaces>
                {item.sentence}
              </Typo.Paragraph>
              <Typo.Link
                type='paragraph-md'
                href={item.link}
                target='_blank'
                rel='noreferrer'
              >
                {item.link}
              </Typo.Link>
            </Row>
          ))}
        </LiWithMargin>
        <LiWithMargin>
          {definitions.explanation.map((paragraph, index) => (
            <Typo.Span key={paragraph} weight={index === 0 ? 'bold' : 'regular'}>
              {paragraph}
              {[1, 2].includes(index) && <br />}
            </Typo.Span>
          ))}
          <Typo.Paragraph weight='bold' type='error'>
            {replaceText(
              definitions.warnings[0],
              { replaceValues: { date: closingDateTariffShieldForm.toLocaleDateString() } },
            )}
          </Typo.Paragraph>
        </LiWithMargin>
        <LiWithMargin>
          <Typo.Paragraph weight='bold' type='error'>{definitions.warnings[1]}</Typo.Paragraph>
        </LiWithMargin>
      </ol>
    </Column>
  </Column>
);
