import { IInlineLoaderProps } from './InlineLoader.types';
import * as Styled from './InlineLoader.styles';

export const InlineLoader = ({ color }: IInlineLoaderProps) => (
  <Styled.Loader>
    <Styled.Ball color={color} />
    <Styled.SecondBall color={color} />
    <Styled.ThirdBall color={color} />
  </Styled.Loader>
);
