import { Breadcrumbs, BreadcrumbPath } from 'src/ui/breadcrumbs/Breadcrumbs';

import { useTheme } from '@emotion/react';
import * as Styled from './Header.styles';
import { Dropdown, DropdownOption } from '../dropdown/Dropdown';

export type HeaderProps = {
  breadcrumbPaths: Array<BreadcrumbPath>;
}& ({
  dropdownOptions?: DropdownOption[];
  onDropdownChange: (val: DropdownOption | null) => void;
  dropdownValue?: DropdownOption;
} | {
  dropdownOptions?: never;
  onDropdownChange?: never;
  dropdownValue?: never;
  });

export const Header = ({
  breadcrumbPaths,
  dropdownOptions,
  onDropdownChange,
  dropdownValue,
}: HeaderProps) => {
  const theme = useTheme();

  return (
    <Styled.HeaderContainer>
      {dropdownOptions && dropdownOptions?.length > 1 && (
      <Dropdown
        options={dropdownOptions}
        value={dropdownValue}
        onChange={onDropdownChange}
        isSearchable
        customStyle={{ container: { width: '200px', marginRight: `${theme.sizes.space.md}px` } }}
        dataTestId='headerGroups'
      />
      )}
      {/* TODO: Add a separator here when we move on Dynamo  */}
      <Breadcrumbs paths={breadcrumbPaths} />
    </Styled.HeaderContainer>
  );
};
