import styled from 'src/theme/ThemeProvider';
import { FormInput } from 'src/ui/input/form/FormInput';

export const HeaderOptionsContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  border-top: ${({ theme }) => `${theme.sizes.border.sm}px solid ${theme.colors.foreground[600]}`};
  background: ${({ theme }) => theme.colors.background.elevated};
  padding: ${({ theme }) => `${theme.sizes.space.md}px`};
`;

export const CustomFormInput = styled(FormInput)`
  margin: ${({ theme }) => `${theme.sizes.space.xs}px 0`};
  min-width: fit-content;
`;
