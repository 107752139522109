import { useFormContext } from 'react-hook-form';
import { Input } from 'src/ui/input/basic/Input';
import { Row } from 'src/ui/row/Row';
import commonText from 'src/texts/current/commonText.json';
import { Column } from 'src/ui/Column/Column';
import texts from 'src/texts/current/shieldPageText.json';
import { Card } from 'src/ui/card/Card';
import { Typo } from 'src/ui/typography/Typo';
import * as Styled from './ShieldForm.styles';

const formTexts = texts.form;

export const CustomerInfoForm = () => {
  const { register, formState: { errors }, getValues } = useFormContext();

  const customerIdsList = getValues('customerIds');

  return (
    <Column gap='md' margin={['md', 'xl']}>
      <Card>
        <Column gap='xs'>
          <Typo.Span weight='semi-bold'>{formTexts.inputs.customerReference.label}</Typo.Span>
          <Typo.Span weight='semi-bold'>{customerIdsList ? customerIdsList?.join(' - ') : ''}</Typo.Span>
        </Column>
      </Card>
      <Row
        gap='md'
        alignItems='center'
        flexWrap='wrap'
        justifyContent='space-evenly'
      >
        <Styled.ShieldFormInput
          htmlFor='siren'
          label={formTexts.inputs.sirenNumber.label}
          required
          hint={errors.siren && formTexts.inputs.sirenNumber.hint}
        >
          <Input
            disabled
            type='number'
            placeholder={formTexts.inputs.sirenNumber.label.replace(' :', '')}
            {...register('siren', {
              setValueAs: (v) => v.toString(),
            })}
          />
        </Styled.ShieldFormInput>
        <Styled.ShieldFormInput
          htmlFor='company'
          label={formTexts.inputs.companyName.label}
          hint={errors.company && formTexts.errorPrefill}
          required
        >
          <Input
            disabled
            type='text'
            placeholder={formTexts.inputs.companyName.placeholder}
            {...register('company')}
          />
        </Styled.ShieldFormInput>
        <Styled.ShieldFormInput
          htmlFor='email'
          label={`${commonText.inputs.email.label} :`}
          required
          hint={errors.mail && commonText.inputs.email.hint}
          isError={!!errors.mail}
        >
          <Input
            type='email'
            placeholder={commonText.inputs.email.placeholder}
            {...register('mail')}
          />
        </Styled.ShieldFormInput>
        <Styled.ShieldFormInput
          htmlFor='phoneNumber'
          label={`${commonText.inputs.phone.label} :`}
          required
          hint={errors.phoneNumber && commonText.inputs.phone.hint}
          isError={!!errors.phoneNumber}
        >
          <Input
            type='text'
            placeholder={commonText.inputs.phone.placeholder}
            {...register('phoneNumber')}
          />
        </Styled.ShieldFormInput>
      </Row>
      <Row
        gap='md'
        alignItems='center'
        justifyContent='space-evenly'
        flexWrap='wrap'
      >
        <Styled.ShieldFormInput htmlFor='streetNumber' label={formTexts.inputs.streetNumber}>
          <Input
            type='text'
            placeholder={formTexts.inputs.streetNumber.replace(' :', '')}
            {...register('streetNumber')}
          />
        </Styled.ShieldFormInput>
        <Styled.ShieldFormInput
          htmlFor='street'
          label={formTexts.inputs.streetName}
          hint={errors.street && commonText.inputs.commonHint}
          required
          isError={!!errors.street}
        >
          <Input
            type='text'
            placeholder={formTexts.inputs.streetName.replace(' :', '')}
            {...register('street')}
          />
        </Styled.ShieldFormInput>
        <Styled.ShieldFormInput
          htmlFor='postalCode'
          label={formTexts.inputs.postalCode.label}
          required
          hint={errors?.postalCode && formTexts.inputs.postalCode.hint}
          isError={!!errors.postalCode}
        >
          <Input
            type='text'
            placeholder={formTexts.inputs.postalCode.label.replace(' :', '')}
            {...register('postalCode')}
          />
        </Styled.ShieldFormInput>
        <Styled.ShieldFormInput
          htmlFor='city'
          label={formTexts.inputs.city}
          hint={errors.city && commonText.inputs.commonHint}
          required
          isError={!!errors.city}
        >
          <Input
            type='text'
            placeholder={formTexts.inputs.city.replace(' :', '')}
            {...register('city')}
          />
        </Styled.ShieldFormInput>
      </Row>
    </Column>
  );
};
