import React from 'react';

import checkPicto from '@caprikorn/dynamo-icons/check.svg';
import { useTheme } from 'src/theme/ThemeProvider';
import { ReactSvgWrapper } from 'src/ui/pictos/wrappers/ReactSVGWrapper';
import * as Styled from './Checkbox.styles';

export interface CheckboxProps {
  name?: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  value: string;
  contained?: boolean;
  checked?: boolean;
  isError?: boolean;
  dataTestId?: string;
}

// Customized from https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(({
  // TODO update Table when this use the new-DS
  disabled,
  className,
  name,
  value,
  onChange,
  checked,
  isError,
  contained,
  dataTestId,
}: CheckboxProps, ref) => {
  const theme = useTheme();
  return (
    <Styled.Label
      contained={contained}
      className={className}
      data-testid={dataTestId}
    >
      <Styled.Input
        type='checkbox'
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
        isError={isError}
        ref={ref}
        data-dd-privacy='allow'
        aria-label={`select-${value}`}
        data-testid='input-checkbox'
      />
      <Styled.BoxCheck data-testid='checkmark' className='CheckMark' />
      <ReactSvgWrapper
        src={checkPicto}
        size={theme.sizes.icons.sm}
        color={checked ? theme.colors.actions.buttons.primary : 'transparent'}
      />
    </Styled.Label>
  );
});

Checkbox.displayName = 'Checkbox';
