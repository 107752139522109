import styled from 'src/theme/ThemeProvider';

export const TicketDrawerWrapper = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
  strong {
    // Counter intuitive but no other solution at the moment was found
    font-weight: normal;
    color: ${({ theme }) => theme.colors.text.secondary};
  }

  li {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.title.h4}px;
    color: ${({ theme }) => theme.colors.text.secondary};
    padding-bottom: ${({ theme }) => theme.sizes.space.xxs}px;
  }

  ul {
    margin: ${({ theme }) => theme.sizes.space.xxs}px 0 ${({ theme }) => theme.sizes.space.md}px 0;
  }
`;
