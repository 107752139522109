import { RefObject, useEffect } from 'react';

export function useOnClickOutside(
  ref: RefObject<HTMLDivElement | null>,
  handler: () => void,
  isClickOutsideEnabled?: boolean,
): void {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }

        handler();
      };

      if (isClickOutsideEnabled) {
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
      }

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler],
  );
}
