// Keep the comment because ./current may be unefined if there is no theme
// eslint-disable-next-line import/no-unresolved
import currentTheme from './current';
import { breakpoints } from './breakpoints';
import { shadows } from './shadows';
import { layouts } from './layouts';
import { fontSizes } from './fontSizes';
import { lineHeights } from './lineHeights';
import { sizes } from './sizes';

export default {
  ...currentTheme,
  breakpoints,
  layouts,
  fontSizes,
  shadows,
  lineHeights,
  sizes,
};
