import styled from 'src/theme/ThemeProvider';

interface ICenterContentLayoutProps {
  children: React.ReactNode;
}

const StyledDiv = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const AbsoluteCenterContentLayout = ({ children }: ICenterContentLayoutProps) => (
  <StyledDiv>
    {children}
  </StyledDiv>
);
