import { Notification } from 'src/ui/overlays/notification/Notification';
import { DocumentErrorNames, DocumentErrorNamesType } from 'src/pages/documents/Documents.types';
import { getFailMessage } from './documents.utils';

interface IDocumentDownloadNotifierProps {
  errorCase: DocumentErrorNamesType;
  closeAction: () => void;
  status?: number;
  supportEmail?: string;
}

export const DocumentDownloadNotifier = ({
  errorCase,
  closeAction,
  status,
  supportEmail,
}: IDocumentDownloadNotifierProps) => (
  <Notification
    type='warning'
    showNotif={errorCase !== DocumentErrorNames.NO_ERROR}
    closeAction={closeAction}
  >
    {getFailMessage(errorCase, status, supportEmail)}
  </Notification>
);
