import { InlineLoader } from 'src/ui/loader/InlineLoader';
import { useTheme } from 'src/theme/ThemeProvider';
import { ISharedButtonProps } from './Button.types';

import * as Styled from './Button.styles';
import { setLoaderColor } from '../button.utils';

export const Button = ({
  children,
  variant,
  size = 'md',
  outline,
  className,
  ariaLabel,
  dataTestId,
  isLoading,
  type = 'button',
  disabled,
  ...props
}: ISharedButtonProps) => {
  const theme = useTheme();
  return (
    <Styled.Button
      {...props}
      aria-label={ariaLabel}
      className={className}
      variant={variant}
      outline={outline}
      disabled={disabled}
      size={size}
      role='button'
      type={type}
      data-testid={dataTestId}
    >
      {isLoading
        ? <InlineLoader color={setLoaderColor(theme, variant, outline, disabled)} />
        : (children)}
    </Styled.Button>
  );
};
