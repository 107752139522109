import { Radio } from '@caprikorn/dynamo-ui';
import { RadioProps } from '@caprikorn/dynamo-ui/lib/components/dataEntry/Radio/Radio.types';
import { Option } from '@caprikorn/dynamo-ui/lib/components/dataEntry/Select/Select.types';
import {
  Control, Controller, FieldValues, Path,
} from 'react-hook-form';
import { capitalizeFirstLetter } from 'src/libs/text.utils';

type ControlledRadioGroupProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  options: readonly Option[];
  dataTestId?: string;
} & Pick<RadioProps, 'size'>;

export const ControlledRadioGroup = <T extends FieldValues>({
  control,
  name,
  options,
  size = 'sm',
  dataTestId,
}: ControlledRadioGroupProps<T>) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { value: fieldValue, ...field } }) => (
      <>
        {options.map(({ value, label }) => (
          <Radio
            key={value}
            label={label}
            value={value}
            size={size}
            checked={fieldValue === value}
            data-testid={`${capitalizeFirstLetter(name)}${dataTestId}-${value}`}
            {...field}
          />
        ))}
      </>
    )}
  />
  );
