import styled from 'src/theme/ThemeProvider';
import { PrefixedSuffixedInput } from '../specials/PrefixedSuffixedInput';

export const CustomPrefixedSuffixedInput = styled(PrefixedSuffixedInput)`
  cursor: text;

  svg {
    // To understand why this is necessary: https://stackoverflow.com/questions/57664614/why-is-svg-not-identified-inside-a-node-using-contain-method-using-javascript/66709626#66709626
    pointer-events: none;
  }
`;
