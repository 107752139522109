import React from 'react';
import { createRoot } from 'react-dom/client';

import './theme/global.css';
import './theme/current/fonts.css';
import './ui/typography/TypoCommon.css';

import App from './App';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
