export const fontSizes = {
  title: {
    h5: 20,
    h4: 24,
    h3: 28,
    h2: 32,
    h1: 40,
  },
  text: {
    xs: 13,
    sm: 14,
    md: 16,
    lg: 18,
  },
  cta: {
    xs: 10,
    sm: 13,
    md: 14,
    lg: 16,
  },
};
