import errorsText from 'src/texts/current/errorsText.json';
import { ErrorTemplate } from './templates/ErrorTemplate';

interface INotFoundErrorProps {
  title?: string;
  text?: string;
}

export const ErrorNotFound = ({
  title = errorsText.notFoundError,
  text,
}: INotFoundErrorProps) => (
  <ErrorTemplate
    imageType='notFound'
    title={title}
    text={text ?? ''}
    dataTestId='ErrorNotFound-container'
  />
);
