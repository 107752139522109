import { IconButton } from 'src/ui/button/icon/IconButton';

import { Row } from 'src/ui/row/Row';
import { Chip } from 'src/ui/chip/Chip';
import { IExtractPerimeterDownloadColumn } from './Extract.types';

export const ExtractPerimeterDownloadColumn = ({
  label,
  picto,
  status,
  buttonText,
  buttonPicto,
  onClickFn,
}: IExtractPerimeterDownloadColumn) => (
  <Row
    justifyContent='space-between'
    alignItems='center'
  >
    <Chip
      label={label}
      variant={status}
      picto={picto}
    />
    {(buttonPicto && buttonText && !!onClickFn) ? (
      <IconButton
        variant='primary'
        size='sm'
        leftIcon={buttonPicto}
        onClick={() => onClickFn()}
        dataTestId='document-ExtractPerimeter-Button'
      >
        {buttonText}
      </IconButton>
    ) : <div />}
  </Row>
);
