import styles from './Title.module.css';

export type ITitleTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

export interface ITitleProps {
  tag: ITitleTags;
  titleStyle?: ITitleTags;
  variant?: 'primary' | 'secondary';
  children: string | React.ReactNode;
  className?: string;
}

export const Title: React.FC<ITitleProps> = ({
  tag,
  titleStyle,
  children,
  variant = 'primary',
  className = '',
}: ITitleProps) => {
  const CustomTitleTag = `${tag}` as keyof JSX.IntrinsicElements;

  const cx = [
    'TypoCommon',
    'TitlesFontFamily',
    'TitlesCommon-bold',
    `TypoCommon-${variant}`,
    styles[`Title-${titleStyle || tag}`],
    className,
  ];

  return (
    <CustomTitleTag className={cx.join(' ')}>
      {children}
    </CustomTitleTag>
  );
};
