import styled from 'src/theme/ThemeProvider';
import { Box } from 'src/ui/box/Box';

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.transparency[300]};
  backdrop-filter: blur(4px);
`;

export const CustomBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.sizes.radii.md}px;
  min-height: 150px;
  min-width: 300px;
  z-index: 999;
  overflow: hidden;
`;

export const CloseWrapper = styled.div<{ small?: boolean }>`
  position: absolute;
  right: ${({ theme, small }) => (small ? `${theme.sizes.space.lg}px` : `${theme.sizes.space.xxl}px`)};
  top: ${({ theme, small }) => (small ? `${theme.sizes.space.lg}px` : `${theme.sizes.space.xxl}px`)};
`;
