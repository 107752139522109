import { ReactSVG } from 'react-svg';

import styled from 'src/theme/ThemeProvider';
import placeholder from 'src/assets/pictos/placeholder.svg';
import styles from './ReactSVGWrapper.module.css';

export interface IReactSvgProps {
  src: string;
  className?: string;
  dataTestId?: string;
  size?: string | number;
}

const ReactWrapper = ({
  src,
  className,
  dataTestId,
  size,
}: IReactSvgProps) => {
  const errorImg = () => (
    <img
      src={placeholder}
      alt='error'
      height={size}
      width={size}
    />
  );

  return (
    <ReactSVG
      src={src}
      role='img'
      data-testid={dataTestId}
      className={styles.wrapper}
      beforeInjection={(svg) => {
        if (className) {
        // className going to the SVG DOM element
          svg.classList.add(className);
        }

        if (size) {
          svg.setAttribute('width', `${size}px`);
          svg.setAttribute('height', `${size}px`);
        }

      // Keep this as an example about how to modify the first `path` element within the SVG.
      // const paths = svg.querySelectorAll('path');
      // paths[0].setAttribute('fill', 'blue');
      }}
      fallback={errorImg}
    />
  );
};

interface IReactSvgWrapper {
  color?: string;
}

export const ReactSvgWrapper = styled(ReactWrapper) <IReactSvgWrapper>`
    ${({ color }) => (color && { color })}
`;
