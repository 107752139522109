import { accessKeysEnum, accessQueriesEnum } from '../access/AccessKeys';
import { authKeysEnum, authQueriesEnum } from '../auth/AuthKeys';
import { commonKeysEnum, commonQueriesEnum } from '../common/CommonKeys';
import { dashboardKeysEnum, dashboardQueriesEnum } from '../dashboard/DashboardKeys';
import { documentKeysEnum, documentQueriesEnum } from '../documents/DocumentsKeys';
import { perimeterKeysEnum, perimeterQueriesEnum } from '../perimeter/PerimeterKeys';
import { perimeterDataKeysEnum, perimeterDataQueriesEnum } from '../perimeter/data/DataKeys';
import { searchKeysEnum, searchQueriesEnum } from '../search/SearchKeys';
import { shieldKeysEnum, shieldQueriesEnum } from '../shield/ShieldKeys';
import { ticketKeysEnum, ticketsQueriesEnum } from '../ticket/TicketKeys';

export type GlobalEnumKeysPattern = {
  [key: string]: (...args: unknown[]) => unknown[] | readonly string[];
};

// Must be updated with every new domains
export type globalEnumKeysKey =
commonQueriesEnum | dashboardQueriesEnum | documentQueriesEnum
| shieldQueriesEnum | ticketsQueriesEnum | authQueriesEnum
  | accessQueriesEnum | perimeterQueriesEnum | perimeterDataQueriesEnum
  | searchQueriesEnum;
// Must be updated with every new domains
export const globalEnumKeys = {
  ...commonKeysEnum,
  ...dashboardKeysEnum,
  ...documentKeysEnum,
  ...shieldKeysEnum,
  ...ticketKeysEnum,
  ...authKeysEnum,
  ...accessKeysEnum,
  ...perimeterKeysEnum,
  ...perimeterDataKeysEnum,
  ...searchKeysEnum,
};

type EnumKeysPathRecursive<T, K extends keyof T = keyof T> =
  K extends string ?
    T[K] extends (...args: infer J) => unknown[] ?
      J
    : never
  : never;

export type EnumKeysPossiblePath = keyof typeof globalEnumKeys;

type IdsObjet<InferedKey extends EnumKeysPossiblePath> =
  EnumKeysPathRecursive<typeof globalEnumKeys, InferedKey> extends never ? {
  params?: never;
} : {
  // Use EnumKeysPathRecursive as type due to args type definition in it.
  params: EnumKeysPathRecursive<typeof globalEnumKeys, InferedKey>;
};

export type QueryEnymParams<InferedKey extends EnumKeysPossiblePath = EnumKeysPossiblePath> = {
  path: InferedKey;
} & IdsObjet<InferedKey>;
