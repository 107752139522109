import { useContext } from 'react';

import styled from 'src/theme/ThemeProvider';
import { Typo } from 'src/ui/typography/Typo';
import contactImage from 'src/assets/images/current/contact.svg';
import contactImageDark from 'src/assets/images/current/contact_dark.svg';
import { Spacer } from 'src/ui/spacer/Spacer';
import modalText from 'src/texts/current/modalText.json';
import commonText from 'src/texts/current/commonText.json';
import { computeSupportEmail, phoneNumberSeparator } from 'src/libs/text.utils';
import { ThemeContext } from 'src/theme/ThemeContext';
import { ModalWithActions } from 'src/ui/overlays/modals/actionnable/ModalWithActions';
import { useGroupDetailsQuery } from 'src/domains/common/CommonQueries';

interface IHelpModalProps {
  show: boolean;
  onHide(): void;
  enableQuery?: boolean;
}

const EmailLink = styled.a`
  color: ${({ theme }) => theme.colors.text.primary};
`;

const PhoneLink = styled.a`
  font-family: ${({ theme }) => theme.fontFamily.title};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.fontSizes.title.h3}px;
  line-height: ${({ theme }) => theme.lineHeights.title.h3}px;
  text-decoration: none;
`;

const helpText = modalText.help;

export const HelpModal = ({ show, onHide, enableQuery = true }: IHelpModalProps) => {
  const { data } = useGroupDetailsQuery({
    options: {
      select: (res) => res.group,
      enabled: enableQuery,
    },
  });

  const supportEmail = computeSupportEmail(data?.email);
  const phone = phoneNumberSeparator(
    data?.customerServicePhoneNumber || commonText.contact.sc,
  );

  const { themeVariant } = useContext(ThemeContext);

  return (
    <ModalWithActions
      onHide={onHide}
      show={show}
      isClickOutsideEnabled
      img={{
        size: 128,
        src: themeVariant === 'light' ? contactImage : contactImageDark,
        alt: 'hotline',
      }}
      title={helpText.title}
    >
      <Typo.Paragraph weight='bold'>
        {helpText.subtitle}
      </Typo.Paragraph>
      <Spacer height='xs' />
      <Typo.Paragraph>
        <>
          {helpText.paragraph1}
          {' '}
          <EmailLink href={`mailto:${supportEmail}`}>{supportEmail}</EmailLink>
          <>
            {' '}
            {helpText.paragraph2}
            {' '}
          </>

        </>
      </Typo.Paragraph>
      <Spacer height='lg' />
      <PhoneLink href={`tel:${phone}`}>
        {phone}
        {' '}
        *
      </PhoneLink>
      <Typo.Paragraph size='md'>{helpText.phoneInfo}</Typo.Paragraph>
    </ModalWithActions>
  );
};
