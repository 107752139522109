import Select from 'react-select';
import { useTheme } from 'src/theme/ThemeProvider';

import * as styled from './Dropdown.styles';

export type DropdownOption = { value: string, label: string };

export interface IDropdownProps {
  options: DropdownOption[];
  placeholder?: string;
  isSearchable?: boolean;
  onChange: (val: DropdownOption | null) => void;
  dataTestId?: string;
  disabled?: boolean;
  value?: DropdownOption;
  customStyle?: Record<string, Record<string, string>>;
  highlight?: boolean;
  ariaLabel?: string;
}

export const Dropdown = ({
  options,
  placeholder = '',
  isSearchable,
  onChange,
  dataTestId,
  disabled,
  value,
  highlight,
  ariaLabel = '',
  customStyle,
}: IDropdownProps) => {
  const valueOrReset = value && value.label ? value : null;
  const theme = useTheme();
  /* id is kept instead of data-testid since it's an uper component from react-select. */

  const getStyle = (
    key: string,
  ): Record<string, string> => (customStyle && customStyle[key] ? customStyle[key] : {});

  return (
    <Select
      isDisabled={disabled}
      placeholder={placeholder}
      onChange={(val) => onChange(val)}
      value={valueOrReset}
      options={options}
      isSearchable={isSearchable}
      id={`${dataTestId}-Dropdown`}
      aria-label={ariaLabel}
      styles={{
        container: (baseStyle) => {
          const extraStyle = getStyle('container');
          return ({
            ...baseStyle,
            ...styled.container,
            ...extraStyle,
          });
        },
        control: (baseStyle) => ({
          ...baseStyle,
          ...styled.renderControlStyles({ highlight, disabled, theme }),
        }),
        valueContainer: (baseStyle) => ({
          ...baseStyle,
          ...styled.valueContainer(theme),
        }),
        singleValue: (baseStyle) => ({
          ...baseStyle,
          ...styled.renderSingleValueStyles({ highlight, disabled, theme }),
        }),
        placeholder: (baseStyle) => ({
          ...baseStyle,
          ...styled.placeholder(theme),
        }),
        indicatorSeparator: () => ({
          ...styled.indicatorSeparator,
        }),

        dropdownIndicator: (baseStyle, state) => ({
          ...styled.dropdownIndicator({
            ...baseStyle,
            menuIsOpen: state.selectProps.menuIsOpen,
            disabled,
            theme,
          }),
        }),
        menu: (baseStyle) => ({
          ...baseStyle,
          ...styled.menuStyles(theme),
        }),
        menuList: (baseStyle) => ({
          ...baseStyle,
          ...styled.menuList,
        }),
        option: (baseStyle, state) => ({
          ...baseStyle,
          ...styled.renderOptionStyles({
            isSelected: state.isSelected,
            isFocused: state.isFocused,
            baseStyle,
            theme,
          }),
        }),
      }}
    />
  );
};
