import styled, { useTheme } from 'src/theme/ThemeProvider';

const loaderSize = {
  sm: 16,
  md: 24,
  lg: 32,
  xl: 56,
} as const;

export type TLoaderSizes = keyof typeof loaderSize;
export interface ILoader {
  size?: TLoaderSizes;
  secondaryColor?: boolean;
  dataTestId?: string;
}

const ArcCircle = styled.circle`
  transform-origin: 50% 50%;
  animation: rotate 2s linear infinite;

 @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Loader = ({
  size = 'lg',
  secondaryColor,
  dataTestId = 'Loader',
}: ILoader) => {
  const theme = useTheme();
  const diameter = loaderSize[size];
  const radius = diameter / 2;
  const strokeWidth = theme.sizes.border[size];
  const viewBox = diameter + strokeWidth;
  const circleCircumference = 2 * Math.PI * (diameter / 2);
  const strokeColor = secondaryColor
    ? theme.colors.foreground[600] : theme.colors.actions.buttons.primary;
  return (
    <svg
      width={diameter}
      height={diameter}
      viewBox={`0 0 ${viewBox} ${viewBox}`}
      data-testid={dataTestId}
    >
      <ArcCircle
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circleCircumference}
        strokeDashoffset={diameter - strokeWidth}
        stroke={strokeColor}
        cy='50%'
        cx='50%'
        fill='transparent'
        strokeLinecap='round'
      />
    </svg>
  );
};
