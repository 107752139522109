import { sizes } from 'src/theme/sizes';
import { getLocalStorageData, localStorageKey, setLocalStorageData } from 'src/libs/localStorage.utils';
import { THEME_VARIANT, TThemeVariant } from './theme.types';

export function switchThemeCSS(themeVariant: TThemeVariant) {
  document.documentElement.setAttribute('data-theme', themeVariant);
  document.documentElement.classList.remove('theme-dark', 'theme-light');
  document.documentElement.classList.add(`theme-${themeVariant}`);
  setLocalStorageData(localStorageKey.theme, themeVariant);
}

const getLocalStorageThemeValue = () => {
  const { LIGHT, DARK } = THEME_VARIANT;
  const localStorageValue = getLocalStorageData(localStorageKey.theme);
  const isThemeValue = localStorageValue === DARK || localStorageValue === LIGHT;
  return isThemeValue ? localStorageValue : undefined;
};

export const getDefaultThemeValue = () => {
  const isDarkPrefered = window.matchMedia('(prefers-color-scheme: dark)').matches;
  return isDarkPrefered ? THEME_VARIANT.DARK : THEME_VARIANT.LIGHT;
};

export const getInitialThemeState = (): TThemeVariant => {
  const localStorageValue = getLocalStorageThemeValue();
  const initialState = localStorageValue ?? getDefaultThemeValue();
  document.documentElement.setAttribute('data-theme', initialState);
  document.documentElement.classList.add(`theme-${initialState}`);
  return initialState;
};

export const getSpaceSize = (spaceSize: keyof typeof sizes.space | 0 | 'auto') => {
  if (spaceSize === 'auto') return 'auto';
  else if (spaceSize === 0) return '0px';
  else return `${sizes.space[spaceSize]}px`;
};
