export const enum ticketsQueriesEnum {
  GET_TICKETS = 'GET_TICKETS',
  GET_TICKET_DETAILS = 'GET_TICKET_DETAILS',
}

export const ticketKeysEnum = {
  [ticketsQueriesEnum.GET_TICKETS]: (
    page: number,
    size: number,
    filters: string,
  ) => [
    ticketsQueriesEnum.GET_TICKETS,
    page,
    size,
    filters,
  ],
  [ticketsQueriesEnum.GET_TICKET_DETAILS]: (
    ticketId?: number,
  ) => [ticketsQueriesEnum.GET_TICKET_DETAILS, ticketId],
} as const;
