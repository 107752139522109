import { datadogRum } from '@datadog/browser-rum';

if (import.meta.env.VITE_APP_ENV !== 'local') {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DD_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'ecms-front',
    env: import.meta.env.VITE_DD_ENV,
    version: __APP_VERSION__,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();
}
