import { SemanticTypes } from 'src/domains/common/CommonTypes';
import { useTheme } from 'src/theme/ThemeProvider';
import {
  Wrapper,
  CustomCaption,
  RadiusTypes,
  CustomSvgWrapper,
} from './Chip.styles';

export interface ChipProps {
  label?: string;
  className?: string;
  variant?: SemanticTypes;
  onClick?: () => void;
  radius?: RadiusTypes;
  picto?: string;
  color?: string;
}

export const Chip = ({
  label,
  variant,
  onClick,
  className,
  radius,
  picto,
  color,
}: ChipProps) => {
  const theme = useTheme();

  return (
    <Wrapper
      variant={variant}
      onClick={onClick}
      className={className}
      data-testid='chip'
      radius={radius}
      color={color ?? theme.colors.disable.background}
    >
      <CustomCaption variant={variant}>
        {picto && (
          <CustomSvgWrapper
            src={picto}
            color={theme.colors.text.reverse}
            size={theme.sizes.icons.sm}
          />
        )}
        {label}
      </CustomCaption>
    </Wrapper>
  );
};
