import { useState } from 'react';

import folder from '@caprikorn/dynamo-icons/folder.svg';
import ticketSimple from '@caprikorn/dynamo-icons/ticketSimple.svg';
import logout from '@caprikorn/dynamo-icons/logOut.svg';
import house from '@caprikorn/dynamo-icons/house01.svg';
import circleQuestion from '@caprikorn/dynamo-icons/circleQuestion.svg';
import settings from '@caprikorn/dynamo-icons/settings.svg';
import usersGroup from '@caprikorn/dynamo-icons/usersGroup.svg';
import layers from '@caprikorn/dynamo-icons/layers.svg';
import search from '@caprikorn/dynamo-icons/search.svg';
import cameleon from 'src/assets/images/current/cameleon.gif';
import { IMAGES_URL } from 'src/assets/images/current/images_config';

import menuText from 'src/texts/current/menuText.json';
import commonText from 'src/texts/current/commonText.json';
import { routes } from 'src/router/RouteConstants';

import { getLocalStorageData, localStorageKey, setLocalStorageData } from 'src/libs/localStorage.utils';
import { onLogout } from 'src/domains/auth/auth.utils';
import { useLocation } from 'react-router-dom';
import { ReactSvgWrapper } from 'src/ui/pictos/wrappers/ReactSVGWrapper';

import { MenuItem } from 'src/ui/menu/item/MenuItem';
import { Sidebar } from '@caprikorn/dynamo-ui';
import { Column } from 'src/ui/Column/Column';
import { Chip } from 'src/ui/chip/Chip';
import { HelpModal } from '../modals/HelpModal';
import { SearchDrawer } from '../drawers/search/SearchDrawer';
import {
  CameleonContainer, CameleonIcon, IconContainer, MenuBar, MenuBarHeader, Text,
} from './Menu.styles';

export const Menu = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const toggleHelp = () => setShowModal(!showModal);
  const toggleDrawer = () => setShowDrawer(!showDrawer);

  const location = useLocation();

  const [menuCollapsed, setMenuCollapsed] = useState(() => localStorage.getItem(localStorageKey.menuCollapsed) === 'true');
  const toggleMenu = () => {
    setMenuCollapsed(!menuCollapsed);
    setLocalStorageData(localStorageKey.menuCollapsed, JSON.stringify(!menuCollapsed));
  };
  const isCameleon = getLocalStorageData('cameleonEmail') !== '';

  return (
    <>
      <HelpModal
        show={showModal}
        onHide={toggleHelp}
      />
      <SearchDrawer
        show={showDrawer}
        toggleDrawer={toggleDrawer}
      />
      <MenuBar
        onExtensionChange={toggleMenu}
        isExtended={!menuCollapsed}
      >
        <MenuBarHeader
          label={commonText.brandName}
        >
          <ReactSvgWrapper
            size={40}
            src={IMAGES_URL.MENU_LOGO}
            aria-label={`logo-${commonText.brandName}`}
          />
        </MenuBarHeader>
        {isCameleon && (
          <CameleonContainer>
            <IconContainer>
              <CameleonIcon
                src={cameleon}
                alt='cameleon-logo'
                width='32'
                height='32'
              />
            </IconContainer>
            <Text>
              <Column>
                <Chip variant='error' label={getLocalStorageData('cameleonFullName')} />
              </Column>
            </Text>
          </CameleonContainer>
        )}
        <Sidebar.Separator />
        <MenuItem
          picto={search}
          onClick={toggleDrawer}
          testName='search'
          label={menuText.quickSearch}
        />
        <MenuItem
          picto={house}
          path={routes.DASHBOARD}
          testName='dashboard'
          label={menuText.dashboard}
        />
        <MenuItem
          picto={usersGroup}
          path={routes.ACCESS_MANAGER}
          isActive={location.pathname.startsWith(routes.ACCESS_MANAGER)}
          testName='access-manager'
          label={menuText.coworkers}
        />
        <MenuItem
          picto={layers}
          path={routes.PERIMETER}
          isActive={location.pathname.startsWith(routes.PERIMETER)}
          testName='perimeter'
          label={menuText.perimeter}
        />
        <MenuItem
          picto={folder}
          path={routes.DOCUMENTS}
          testName='documents'
          label={menuText.document}
        />
        <MenuItem
          picto={ticketSimple}
          path={routes.FRESHDESK_TICKETS}
          testName='tickets'
          label={menuText.techRequest}
        />
        <MenuItem
          picto={settings}
          path={routes.SETTINGS}
          testName='settings'
          label={menuText.settings}
        />
        <MenuItem
          picto={circleQuestion}
          onClick={toggleHelp}
          testName='help'
          label={menuText.help}
        />
        <MenuItem
          picto={logout}
          onClick={onLogout}
          testName='logout'
          label={menuText.logout}
          pushToBottom
        />
      </MenuBar>
    </>
  );
};
