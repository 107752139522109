import styled from 'src/theme/ThemeProvider';
import { DocumentsButtonsCell } from 'src/components/documents/DocumentsButtonsCell';
import { Card } from 'src/ui/card/Card';

export const CustomCard = styled(Card)`
  flex-direction: column;
  height: auto;
  overflow: auto;
`;

export const CustomButton = styled(DocumentsButtonsCell)`
  display: flex;
  justify-content: flex-end;
`;
