import { actionTypesType } from 'src/components/modals/request/forms/type/TypeForm.types';

export interface ITechRequestDataDico {
  [key: string]: string ;
}

export interface ITechRequestDescriptionField {
  [name: string]: ITechRequestDataDico ;
}

export interface ITechRequestData {
  identifier: string;
  actionType: actionTypesType;
  attachments?: File[];
  siteId?: string;
  description: ITechRequestDescriptionField;
  dueDate?: string;
}

export type ITechRequestDataForm = Partial<ITechRequestData>;

export interface IExportData {
  fileType: 'CSV' | 'PDF' | undefined;
  type: 'CONSUMPTION' | 'INVOICE' | undefined;
  dateRange: {
    from: string;
    to: string;
  };
  perimeter: {
    customers: IPerimeterExported[] | null;
  };
}

export interface IPerimeterExported {
  customerId: string;
  contracts?: { contractId: string }[];
}

export type DocumentType = 'invoice' | 'contractual' | 'payment' | 'commercial' | 'litigation' | 'contract' | 'schedule' | 'mandate';
export type DocumentExtensionType = 'pdf' | 'csv';
export type DocumentExtensionTypeXls = 'xls' | 'ods' | 'xlsx';

export interface IBillsMutationArgs {
  customerId: string;
  contractId: string;
  extension: DocumentExtensionType;
}

export enum SemanticEnum {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

export type SemanticTypes = keyof typeof SemanticEnum;

export interface IGroups {
  name: string;
  reference: string;
}

export interface IGroupDetail {
  group: {
    reference: string;
    name: string;
    email: string;
    created: string | null;
    signatureDate: string;
    customerServicePhoneNumber: string;
  };
  isAdmin: boolean;
}
