import { THEME_VARIANT, TThemeVariant } from '../theme.types';
import { darkBaseColors, lightBaseColors } from './baseColors';

export const colors = (variant: TThemeVariant) => {
  const baseColors = variant === THEME_VARIANT.LIGHT ? lightBaseColors : darkBaseColors;
  const appBgColor = variant === THEME_VARIANT.LIGHT
    ? (baseColors as typeof lightBaseColors).neutral[800]
    : (baseColors as typeof darkBaseColors).neutral[900];

  const appElevatedBgColor = variant === THEME_VARIANT.LIGHT
    ? (baseColors as typeof lightBaseColors).neutral[900]
    : (baseColors as typeof darkBaseColors).neutral[700];

  const appTooltipBgColor = variant === THEME_VARIANT.LIGHT
    ? (baseColors as typeof lightBaseColors).neutral[800]
    : (baseColors as typeof darkBaseColors).neutral[200];

  const appTooltipTextColor = variant === THEME_VARIANT.LIGHT
    ? (baseColors as typeof lightBaseColors).neutral[100]
    : (baseColors as typeof darkBaseColors).neutral[900];

  return {
    text: {
      primary: baseColors.neutral[100],
      secondary: baseColors.neutral[200],
      reverse: baseColors.neutral[900],
    },
    disable: {
      text: baseColors.black[10],
      background: baseColors.black[75],
      loading: baseColors.black[50],
      textOnly: baseColors.black[25],
    },
    actions: {
      links: {
        secondary: baseColors.primary[600],
        primary: baseColors.black[10],
      },
      buttons: {
        primary: baseColors.primary[300],
        primaryHover: baseColors.primary[600],
        danger: baseColors.error[50],
        dangerHover: baseColors.error[50],
      },
      inputs: {
        primary: baseColors.neutral[100],
        disabled: baseColors.black[75],
        border: baseColors.primary[600],
        placeholder: baseColors.neutral[200],
      },
    },
    borders: {
      primary: baseColors.primary[600],
      secondary: baseColors.black[10],
      alternate: baseColors.black[15],
    },
    background: {
      primary: appBgColor,
      elevated: appElevatedBgColor,
    },
    foreground: {
      600: baseColors.neutral[600],
      700: baseColors.neutral[700],
      800: baseColors.neutral[800],
    },
    transparency: {
      100: 'rgba(73, 73, 73, 0.4)', // Confirm with UI/UX
      200: 'rgba(73, 73, 73, 0.1)', // Confirm with UI/UX
      300: 'rgba(17, 18, 19, 0.5)',
    },
    specific: {
      tooltip: {
        background: appTooltipBgColor,
        text: appTooltipTextColor,
      },
    },
    semantic: {
      error: { ...baseColors.error },
      success: { ...baseColors.success },
      warning: { ...baseColors.warning },
      info: { ...baseColors.info },
    },
    secondary: { ...baseColors.secondary },
  };
};
