import { Typo } from 'src/ui/typography/Typo';
import { FormInput } from 'src/ui/input/form/FormInput';
import texts from 'src/texts/current/shieldPageText.json';
import { Row } from 'src/ui/row/Row';
import { Column } from 'src/ui/Column/Column';
import { useFormContext } from 'react-hook-form';
import { ControlledRadioGroup } from 'src/ui/input/radio/ControlledRadio';
import { InfoText } from './ShieldForm.styles';
import { getStatementNumber } from '../shield.utils';
import { ShieldCategories } from '../ShieldPage.types';

const formTexts = texts.form.choices;
const { values } = formTexts;

export const ShieldFormCategory = () => {
  const { control, formState: { errors } } = useFormContext();
  return (
    <Column gap='md'>
      <Typo.Paragraph>
        {formTexts.legals}
      </Typo.Paragraph>
      <Column>
        <FormInput
          htmlFor='category'
          label={formTexts.label}
          hint={errors.category && formTexts.hint}
          required
          isError={!!errors.category}
        >
          {values.map((value) => (
            <Row key={value.key} margin={['md', 0]}>
              <ControlledRadioGroup
                control={control}
                key={value.key}
                name='category'
                options={[
                  {
                    label: '',
                    value: getStatementNumber(value.key as ShieldCategories),
                  },
                ]}
                dataTestId='RadioButtonShieldForm'
              />
              <Column margin={[0, 0, 0, 'xs']}>
                <Typo.Paragraph>
                  {value.regularText}
                </Typo.Paragraph>
                {value?.list && (
                <ul>
                  {value.list.map((li) => (
                    <li key={li}>{li}</li>
                  ))}
                </ul>
                )}
                <InfoText>{value.italicText}</InfoText>
              </Column>

            </Row>
          ))}
        </FormInput>
      </Column>

    </Column>
  );
};
