import { useSearchParams } from 'react-router-dom';
import { CameleonToken } from 'src/domains/auth/AuthTypes';
import { parseJwt } from 'src/domains/auth/auth.utils';
import { useLoginRedirect } from 'src/hooks/useLoginRedirect';
import { localStorageKey, setLocalStorageData } from 'src/libs/localStorage.utils';
import { Loader } from 'src/ui/loader/Loader';
import { Row } from 'src/ui/row/Row';

export const CameleonLogin = () => {
  const [params] = useSearchParams();
  const token = params.get('token');
  if (token) {
    const data: CameleonToken = parseJwt(token);
    setLocalStorageData(localStorageKey.currentGroupRef, '');
    setLocalStorageData(localStorageKey.accessToken, token);
    setLocalStorageData(localStorageKey.email, data.emailCustomer);
    setLocalStorageData(localStorageKey.cameleonEmail, data.emailCameleon);
    setLocalStorageData(localStorageKey.cameleonFullName, data.fullnameCameleon);
  }
  useLoginRedirect();

  return (
    <Row
      justifyContent='center'
      alignItems='center'
      height='100vh'
    >
      <Loader />
    </Row>
  );
};
